import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import moment, { months } from "moment";
import React, { useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTimes, FaTrashAlt } from "react-icons/fa";
import { MdCancel, MdRestartAlt } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { v4 } from "uuid";
import { formatDate, getErrorMessage, getSuccessMessage } from "../../functions";
import {
  addLedgerDetails,
  deleteLedgerDetails,
  getAllLedgerDetails,
} from "../../services/cncLedgerApi";
import {
  addParty,
  deletePartyById,
  getAllParties,
} from "../../services/userWithoutValidationApi";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import Loader from "../../UI/Loader";
import SelectField from "../../UI/SelectField";
import DatePicker from "react-datepicker";
//party name,job name, hours taken, total amount, amount left, amount recieved
const CncLedger = () => {
  const [loading, setLoading] = useState(false);
  const [ledgerHistory, setLedgerHistory] = useState([]);
  const [jobName, setJobName] = useState("");
  const [hoursTaken, setHoursTaken] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [dateOfEntry, setDateOfEntry] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState("");
  const [searchStatus, setSearchStatus] = useState("All");
  const [allParties, setAllParties] = useState([]);
  const [selectedParty, setSelectedParty] = useState("");
  const [partyName, setPartyName] = useState("");
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);
  const dealers = useSelector((state) => state.dealer.dealers);
  const customers = useSelector((state) => state.customer.customers);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [filteredBatteryDetailsHistory, setFilteredLedgerDetails] = useState(
    []
  );
  const [month, setMonth] = useState("All");
  const [year, setYear] = useState(0);
  const [date, setDate] = useState(0);
  const [paymentHistory, setPaymentHistory] = useState([
    {
      dateOfPayment: new Date(),
      amountPaid: 0,
      _id: v4(),
    },
  ]);

  const handleAddSecondaryBatteryDetails = async () => {
    setPaymentHistory((prevSecondaryLedgerDetails) => [
      ...prevSecondaryLedgerDetails,
      {
        dateOfPayment: new Date(),
        amountPaid: 0,
        _id: v4(),
      },
    ]);
  };

  const handleDeleteSecondaryBatteryDetails = (_id) => {
    if (!_id) return;
    if (paymentHistory.length === 1) return;
    setPaymentHistory((prevSecondaryLedgerDetails) =>
      prevSecondaryLedgerDetails.filter((item) => item._id !== _id)
    );
  };

  const handleChangeSecondaryBatteryDetails = (_id, field, value) => {
    if (!_id) return;
    setPaymentHistory((prevSecondaryLedgerDetails) =>
      prevSecondaryLedgerDetails.map((item) =>
        item._id === _id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleAddParty = async () => {
    try {
      if (!partyName) {
        return toast.error("Please enter party name");
      }
      setLoading(true);
      const res = await addParty({
        name: partyName,
      });
      setAllParties((prevParties) => [res.data, ...prevParties]);
      setPartyName("");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchAllParties = async () => {
    try {
      setLoading(true);
      const res = await getAllParties();
      setAllParties(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllParties();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleLedgerDetailsCreate();
  };

  useEffect(() => {
    setLoading(false);
  }, []);
  const fetchLedgerDetails = async () => {
    try {
      setLoading(true);
      const res = await getAllLedgerDetails();
      setLedgerHistory(res.data);
      setFilteredLedgerDetails(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLedgerDetails();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLedgerDetailsCreate = async () => {
    try {
      if (!dateOfEntry) {
        return toast.error("Please enter date of entry");
      }
      if (paymentHistory.length === 0) {
        return toast.error("Please add date of payment and amount paid");
      }

      setLoading(true);
      const res = await addLedgerDetails({
        dateOfEntry,
        partyName: selectedParty,
        jobName,
        hoursTaken,
        totalAmount,
        ledgerDetailsId: selectedId,
        paymentHistory: paymentHistory.map((item) => ({
          dateOfPayment: item.dateOfPayment,
          amountPaid: item.amountPaid,
        })),
      });

      setPaymentHistory([
        {
          dateOfPayment: new Date(),
          amountPaid: 0,
          _id: v4(),
        },
      ]);
      setIsEdit(false);

      //   if (isEdit) {
      //     setLedgerHistory((prevHistory) =>
      //       prevHistory.map((item) => (item._id === selectedId ? res.data : item))
      //     );
      //   } else {
      //     setLedgerHistory((prevHistory) => [res.data, ...prevHistory]);
      //   }
      fetchLedgerDetails();
      setSelectedId("");
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const formattedDate = dateOfEntry.toISOString().split("T")[0];
  const handleEdit = (history) => {
    setDateOfEntry(new Date(history.dateOfEntry));
    setSelectedParty(history.partyName);
    setJobName(history.jobName);
    setHoursTaken(history.hoursTaken);
    setTotalAmount(history.totalAmount);
    setPaymentHistory(history.paymentHistory);
    setSelectedId(history._id);
    setIsEdit(true);
  };

  const handleDeleteLedgerDetails = async (batteryDetails) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this log?"
      );
      if (!shouldDelete) {
        return;
      }

      setLoading(true);
      const res = await deleteLedgerDetails(batteryDetails._id);
      toast.success(getSuccessMessage(res));

      setLedgerHistory((prevHistory) =>
        prevHistory.filter((history) => history._id !== batteryDetails._id)
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filteredBatteryDetailsHistory = ledgerHistory.filter((history) => {
      const searchLower = searchQuery.toLowerCase();

      // Check if the search query is empty or if any field matches the search query
      const matchesSearchQuery =
        !searchLower || // This allows all items if searchQuery is empty
        history.dateOfEntry.toLowerCase().includes(searchLower) ||
        history.partyName.toLowerCase().includes(searchLower);

      // Match the status, or allow all if searchStatus is "All"
      const matchesStatus =
        searchStatus === "All" || history.status === searchStatus;

      // Convert month name to 0-indexed month number
      const monthIndex = month === "All" ? -1 : moment().month(month).month();
      const matchesDay =
        date == 0 || moment(history.dateOfEntry).date() == +date;
      const matchesMonth =
        month === "All" || moment(history.dateOfEntry).month() === monthIndex;
      const matchesYear =
        year == 0 || moment(history.dateOfEntry).year() === Number(year);

      return (
        matchesSearchQuery &&
        matchesStatus &&
        matchesMonth &&
        matchesYear &&
        matchesDay
      );
    });

    setFilteredLedgerDetails(filteredBatteryDetailsHistory);
  }, [searchQuery, searchStatus, ledgerHistory, month, year, date]);

  const handleDeleteParty = async (partyId) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this party?"
      );
      if (!shouldDelete) {
        return;
      }

      setLoading(true);
      const res = await deletePartyById(partyId);
      toast.success(getSuccessMessage(res));
      setAllParties((prevParties) =>
        prevParties.filter((party) => party._id !== partyId)
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const getMonths = () => {
    const newMonths = [];
    for (let i = 0; i < 12; i++) {
      newMonths.push({
        name: months(i),
        value: i,
      });
    }
    return newMonths;
  };

  const handleResetFilters = () => {
    setSearchQuery("");
    setSearchStatus("All");
    setMonth("All");
    setYear(new Date().getFullYear());
    setDate(0);
  };


  if (loading) return <Loader />;

  return (
    <>
      <div>
        <Gap>Add Details</Gap>
        <form
          className="flex lg:grid lg:grid-row-3 gap-4 flex-col"
          onSubmit={handleSubmit}
        >
          <div className="flex lg:flex-row flex-col gap-4">
            <div>
              <InputField
                type={"date"}
                labelName={"Date"}
                value={formattedDate}
                onChange={(e) => setDateOfEntry(new Date(e.target.value))}
              />
            </div>
            <div className="flex  lg:w-96">
              <SelectField
                type={"text"}
                labelName={"Party Name"}
                uni={"partyName"}
                options={[...allParties, ...customers, ...dealers]
                  .map((party) => ({
                    name: party.name,
                    value: party.name,
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name))}
                value={{
                  label: selectedParty,
                  value: selectedParty,
                }}
                name={"partyName"}
                onChange={({ value }) => {
                  setSelectedParty(value.value);
                }}
              />
            </div>
            <div className="flex lg:flex-row flex-col gap-4">
              <InputField
                type={"text"}
                labelName={"Job Name"}
                uni={"jobName"}
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
              />
            </div>
            <div className="flex lg:flex-row flex-col gap-4 ">
              <InputField
                type={"number"}
                labelName={"Hours Taken"}
                uni={"hoursTaken"}
                value={hoursTaken}
                onChange={(e) => setHoursTaken(e.target.value)}
              />
            </div>
            <div className="flex lg:flex-row flex-col gap-4 ">
              <InputField
                type={"number"}
                labelName={"Total Amount"}
                uni={"totalAmount"}
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
              />
            </div>
          </div>
          {paymentHistory.map((item) => (
            <div className="flex gap-4" key={item._id}>
              <div className="flex lg:flex-row flex-col gap-4">
                <div className="flex lg:flex-row flex-col gap-4">
                  <InputField
                    type={"date"}
                    labelName={"Date of Payment"}
                    uni={"dateOfPayment"}
                    value={formatDate(item.dateOfPayment)}
                    onChange={(e) =>
                      handleChangeSecondaryBatteryDetails(
                        item._id,
                        "dateOfPayment",
                        new Date(e.target.value)
                      )
                    }
                  />
                </div>
                <div>
                  <InputField
                    type={"number"}
                    labelName={"Amount Paid"}
                    uni={"amountPaid"}
                    value={item.amountPaid}
                    //always positive
                    min={0}
                    onChange={(e) =>
                      handleChangeSecondaryBatteryDetails(
                        item._id,
                        "amountPaid",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <Button
                rounded={"none"}
                colorScheme="blue"
                className="lg:mt-6 "
                onClick={() => handleAddSecondaryBatteryDetails()}
              >
                <FaPlus size={20} />
              </Button>
              <Button
                rounded={"none"}
                colorScheme="red"
                className="lg:mt-6 "
                onClick={() => handleDeleteSecondaryBatteryDetails(item._id)}
              >
                <FaTrashAlt size={20} />
              </Button>
            </div>
          ))}
          <div className="flex lg:flex-row flex-col gap-4 w-full">
            <Button
              rounded={"none"}
              colorScheme="blue"
              className="lg:mt-6 w-full "
              type="submit"
            >
              {isEdit ? "Update" : "Add"}
            </Button>
          </div>
        </form>
      </div>
      <div>
        <Gap>Parties</Gap>
        <div className="flex flex-wrap gap-4">
          {allParties?.map((party, index) => (
            <div className="flex gap-2 items-center" key={index}>
              <div className="flex items-center gap-2 bg-gray-200 px-3 py-1 ">
                <span>{party.name}</span>
                <button
                  onClick={() => handleDeleteParty(party._id)}
                  className="flex items-center justify-center w-5 h-5 bg-red-500 text-white  hover:bg-red-700"
                >
                  <FaTimes size={10} />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="flex gap-2 items-center mt-2">
          <InputField
            type="text"
            placeholder={"Party Name"}
            value={partyName}
            onChange={(e) => setPartyName(e.target.value)}
          />
          <Button
            rounded={"none"}
            onClick={handleAddParty}
            size={"md"}
            colorScheme="blue"
          >
            Add
          </Button>
        </div>
      </div>
      <div className="p-0">
        <Gap>Ledger Details</Gap>
        <div className="flex gap-2 flex-1 w-full lg:flex-row flex-col">
          <div className="flex flex-col gap-2 flex-1">
            <InputField
              labelName={"Date"}
              type="number"
              max={31}
              min={1}
              placeholder="23"
              //disable stepper

              value={date}
              onChange={(e) => {
                if (e.target.value === "") setDate(0);
                setDate(e.target.value);
              }}
              className="border border-gray-300 p-2 "
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <SelectField
              labelName={"Month"}
              type="text"
              value={{
                label: month,
                value: moment(month).format("M"),
              }}
              onChange={({ value }) => {
                setMonth(value.value);
              }}
              options={[{ name: "All", value: "All" }, ...getMonths()]}
              className="border border-gray-300 p-2 "
            />
          </div>
          <div className="flex flex-col  flex-1">
            <label
              className=" text-secondary font-bold col-span-1 flex items-center"
              htmlFor="DatePicker"
            >
              Year
            </label>
            <DatePicker
              id="DatePicker"
              type="string"
              className="text-black text-center border w-full border-gray-300  p-1.5  focus:outline-none focus:border-blue-500"
              selected={new Date(year, 0)}
              onChange={(date) => {
                //make sure date not become null
                if (!date) return;
                setYear(date?.getFullYear());
              }}
              showYearPicker
              dateFormat="yyyy"
              yearItemNumber={10}
              required
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <InputField
              labelName={"Search"}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="border border-gray-300 p-2 "
            />
          </div>
      
          <div
            className="flex flex-col gap-2 flex-1 mt-6"
            title="Reset Filters"
          >
            <Button rounded={"none"} onClick={handleResetFilters} size={"md"}>
              <MdRestartAlt size={20} color="green" />
            </Button>
          </div>
        </div>
        <div className=" text-secondary font-bold col-span-1 flex items-center mt-2 border-2 border-secondary p-2 w-fit ">
          <span className="text-lg">
            {" "}
            Total Entries: {filteredBatteryDetailsHistory.length}
          </span>
        </div>
        <div className="overflow-x-auto">
          {isMobileView ? (
            <div className="grid grid-cols-1 gap-4 mt-4">
              {filteredBatteryDetailsHistory?.map((history, index) => (
                <div
                  key={history._id}
                  className="bg-white shadow-md  p-4 border border-gray-200"
                >
                  <div className="flex justify-between items-center mb-3">
                    <span className="font-bold text-lg">S.No: {index + 1}</span>
                    <div className="flex gap-2">
                      {isEdit ? (
                        <button
                          onClick={() => setIsEdit(false)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <MdCancel size={24} />
                        </button>
                      ) : (
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleEdit(history)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            <FaEdit size={20} />
                          </button>
                          <button
                            onClick={() => handleDeleteLedgerDetails(history)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <FaTrashAlt size={20} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Date:</span>{" "}
                    {moment(history.dateOfEntry).format("DD MMM YYYY")}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Job Name:</span>{" "}
                    {history.jobName}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Party Name:</span>{" "}
                    {history.partyName}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Hours Taken:</span>{" "}
                    {history.hoursTaken}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Total Amount:</span>{" "}
                    {history.totalAmount}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Amount Paid:</span>{" "}
                    {history.amountPaid}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Amount Left:</span>{" "}
                    {history.amountLeft}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Payment History</span>
                    <Table variant="striped" className="mt-4">
                      <Thead className="bg-gray-50">
                        <Tr>
                          <Th>Date of Payment</Th>
                          <Th>Amount Paid</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {history.paymentHistory.map((payment) => (
                          <Tr key={payment._id}>
                            <Td>
                              {moment(payment.dateOfPayment).format(
                                "DD MMM YYYY"
                              )}
                            </Td>
                            <Td>{payment.amountPaid}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="overflow-x-auto">
              <Table variant="striped" className="mt-4">
                <Thead className="bg-gray-50">
                  <Tr>
                    <Th>S.No</Th>
                    <Th>Date</Th>
                    <Th>Job Name</Th>
                    <Th>Party Name</Th>
                    <Th>Hours Taken</Th>
                    <Th>Total Amount</Th>
                    <Th>Amount Paid</Th>
                    <Th>Amount Left</Th>
                    <Th>Payment History</Th>

                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredBatteryDetailsHistory?.map((history, index) => (
                    <Tr key={history._id}>
                      <Td>{index + 1}</Td>
                      <Td>
                        {moment(history.dateOfEntry).format("DD MMM YYYY")}
                      </Td>
                      <Td>{history.jobName}</Td>
                      <Td>{history.partyName}</Td>
                      <Td>{history.hoursTaken}</Td>
                      <Td>{history.totalAmount}</Td>
                      <Td>{history.amountPaid}</Td>
                      <Td>{history.amountLeft}</Td>
                      <Td>
                        <Table variant="striped" className="mt-4">
                          <Thead className="bg-gray-50">
                            <Tr>
                              <Th>Date of Payment</Th>
                              <Th>Amount Paid</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {history.paymentHistory.map((payment) => (
                              <Tr key={payment._id}>
                                <Td>
                                  {moment(payment.dateOfPayment).format(
                                    "DD MMM YYYY"
                                  )}
                                </Td>
                                <Td>{payment.amountPaid}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Td>

                      <Td>
                        {isEdit ? (
                          <button
                            onClick={() => setIsEdit(false)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            <MdCancel size={24} />
                          </button>
                        ) : (
                          <div className="flex gap-2">
                            <button
                              onClick={() => handleEdit(history)}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              <FaEdit size={20} />
                            </button>
                            <button
                              onClick={() => handleDeleteLedgerDetails(history)}
                              className="text-red-500 hover:text-red-700"
                            >
                              <FaTrashAlt size={20} />
                            </button>
                          </div>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CncLedger;
