import { Box, Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import moment, { months } from "moment";
import React, { useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { v4 } from "uuid";
import {
  formatDate,
  getErrorMessage,
  getSuccessMessage,
} from "../../../functions";
import {
  deleteKhataOfAEmployee,
  khataOfAEmployee,
  startNextMonthKhataOfAEmployee,
  updateKhataOfAEmployee,
} from "../../../services/khatabookApi";
import BasicModal from "../../../UI/BasicModal";
import Gap from "../../../UI/Gap";
import InputField from "../../../UI/InputField";
import Loader from "../../../UI/Loader";

function EditKhataModal({
  isEditKhataModalOpen,
  setIsEditKhataModalOpen,
  selectedKhata,
  setSelectedKhata,
  setLoading,
  fetchKhataOfEmployee,
}) {
  const [advanceDeductions, setAdvanceDeductions] = useState([]);
  const [overTimeHours, setOverTimeHours] = useState([]);
  const [basePay, setBasePay] = useState(selectedKhata.basePay);
  useEffect(() => {
    if (selectedKhata?.advanceDeductions?.length === 0) {
      setAdvanceDeductions([
        {
          _id: v4(),
          amountPaid: 0,
          dateOfPayment: new Date(),
        },
      ]);
    } else {
      setAdvanceDeductions(selectedKhata.advanceDeductions);
    }
    if (selectedKhata?.overTimeHours?.length === 0) {
      setOverTimeHours([
        {
          _id: v4(),
          hours: 0,
          dateOfPayment: new Date(),
          amountPaid: 0,
        },
      ]);
    } else {
      setOverTimeHours(selectedKhata.overTimeHours);
    }
    setBasePay(selectedKhata.basePay);
  }, [selectedKhata]);
  const handleAddAdvanceDeduction = () => {
    setAdvanceDeductions([
      ...advanceDeductions,
      {
        _id: v4(),
        amountPaid: 0,
        dateOfPayment: new Date(),
      },
    ]);
  };
  const handleAddOverTimeHours = () => {
    setOverTimeHours([
      ...overTimeHours,
      {
        _id: v4(),
        hours: 0,
        dateOfPayment: new Date(),
        amountPaid: 0,
      },
    ]);
  };
  const handleRemoveAdvanceDeduction = (_id) => {
    if (advanceDeductions.length === 1) {
      toast.error("Cannot remove last advance deduction");
      return;
    }
    setAdvanceDeductions((prevState) =>
      prevState.filter((advanceDeduction) => advanceDeduction?._id !== _id)
    );
  };
  const handleRemoveOverTimeHours = (_id) => {
    if (overTimeHours.length === 1) {
      toast.error("Cannot remove last over time hours");
      return;
    }
    setOverTimeHours((prevState) =>
      prevState.filter((overTimeHours) => overTimeHours?._id !== _id)
    );
  };

  const handleChangeOverTimeHours = (_id, field, value) => {
    setOverTimeHours((prevState) =>
      prevState.map((overTimeHours) =>
        overTimeHours?._id === _id
          ? { ...overTimeHours, [field]: value }
          : overTimeHours
      )
    );
  };

  const handleChangeAdvanceDeduction = (_id, field, value) => {
    setAdvanceDeductions((prevState) =>
      prevState.map((advanceDeduction) =>
        advanceDeduction?._id === _id
          ? { ...advanceDeduction, [field]: value }
          : advanceDeduction
      )
    );
  };

  const handleUpdateKhata = async () => {
    try {
      setLoading(true);
      setSelectedKhata({
        ...selectedKhata,
        basePay,
        advanceDeductions,
        overTimeHours,
      });
      const res = await updateKhataOfAEmployee(selectedKhata._id, {
        ...selectedKhata,
        basePay,
        advanceDeductions: advanceDeductions
          .map((advanceDeduction) => ({
            ...advanceDeduction,
            _id: undefined,
          }))
          .filter((advanceDeduction) => advanceDeduction.amountPaid > 0),
        overTimeHours: overTimeHours
          .map((overTimeHour) => ({
            ...overTimeHour,
            _id: undefined,
          }))
          .filter((khata) => khata.hours > 0),
      });
      await fetchKhataOfEmployee();
      setIsEditKhataModalOpen(false);
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicModal
      isOpen={isEditKhataModalOpen}
      onClose={() => setIsEditKhataModalOpen(false)}
      title="Update Khata"
    >
      <div className="flex gap-2 flex-col ">
        <div className="flex gap-4 p-4 border font-semibold text-gray-900 bg-white shadow-md">
          {months(selectedKhata?.month - 1) + " " + selectedKhata.year}
        </div>
        <div className="flex gap-4 p-4 border  bg-white shadow-md ">
          <InputField
            labelName={"Base Pay"}
            type={"number"}
            value={basePay}
            onChange={(e) => setBasePay(e.target.value)}
          />
        </div>
        <div className="flex gap-4 p-4 border flex-col  bg-white shadow-md ">
          <span className="text-lg font-semibold">Advance Deductions</span>
          {advanceDeductions?.map((advanceDeduction) => (
            <div
              className="flex lg:flex-row flex-col gap-2 items-center lg:mt-0 mt-2"
              key={advanceDeduction?._id}
            >
              <div className="flex lg:flex-row flex-col gap-4">
                <div className="flex lg:flex-row flex-col gap-4">
                  <InputField
                    type={"date"}
                    labelName={"Date of Payment"}
                    uni={"dateOfPayment"}
                    value={formatDate(advanceDeduction.dateOfPayment)}
                    onChange={(e) =>
                      handleChangeAdvanceDeduction(
                        advanceDeduction?._id,
                        "dateOfPayment",
                        new Date(e.target.value)
                      )
                    }
                  />
                </div>
                <div>
                  <InputField
                    type={"number"}
                    labelName={"Amount Paid"}
                    uni={"amountPaid"}
                    value={advanceDeduction?.amountPaid}
                    onChange={(e) => {
                      handleChangeAdvanceDeduction(
                        advanceDeduction?._id,
                        "amountPaid",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex  gap-2 items-center lg:mt-6 mt-2">
                <Button
                  rounded={"none"}
                  colorScheme="blue"
                  className="w-fit flex self-end"
                  onClick={handleAddAdvanceDeduction}
                >
                  <FaPlus size={20} />
                </Button>
                <Button
                  rounded={"none"}
                  colorScheme="red"
                  className="w-fit flex self-end"
                  onClick={() =>
                    handleRemoveAdvanceDeduction(advanceDeduction?._id)
                  }
                >
                  <FaTrashAlt size={20} />
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div className="flex gap-4 p-4 border flex-col  bg-white shadow-md ">
          <span className="text-lg font-semibold">Over Time Hours</span>
          {overTimeHours?.map((overTimeHour) => (
            <div
              className="flex lg:flex-row flex-col gap-2 items-center lg:mt-0 mt-2"
              key={overTimeHour?._id}
            >
              <div className="flex lg:flex-row flex-col gap-4">
                <div className="flex lg:flex-row flex-col gap-4">
                  <InputField
                    type={"number"}
                    labelName={"Hours"}
                    uni={"hours"}
                    value={overTimeHour.hours}
                    onChange={(e) =>
                      handleChangeOverTimeHours(
                        overTimeHour?._id,
                        "hours",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="flex lg:flex-row flex-col gap-4">
                  <InputField
                    type={"date"}
                    labelName={"Date of Payment"}
                    uni={"dateOfPayment"}
                    value={formatDate(overTimeHour.dateOfPayment)}
                    onChange={(e) =>
                      handleChangeOverTimeHours(
                        overTimeHour?._id,
                        "dateOfPayment",
                        new Date(e.target.value)
                      )
                    }
                  />
                </div>
                <div>
                  <InputField
                    type={"number"}
                    labelName={"Amount Paid"}
                    uni={"amountPaid"}
                    value={overTimeHour?.amountPaid}
                    onChange={(e) => {
                      handleChangeOverTimeHours(
                        overTimeHour?._id,
                        "amountPaid",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex  gap-2 items-center lg:mt-6 mt-2">
                <Button
                  rounded={"none"}
                  colorScheme="blue"
                  className="w-fit flex self-end"
                  onClick={handleAddOverTimeHours}
                >
                  <FaPlus size={20} />
                </Button>
                <Button
                  rounded={"none"}
                  colorScheme="red"
                  className="w-fit flex self-end"
                  onClick={() => handleRemoveOverTimeHours(overTimeHour?._id)}
                >
                  <FaTrashAlt size={20} />
                </Button>
              </div>
            </div>
          ))}
        </div>
        <Button
          rounded={"none"}
          colorScheme="blue"
          className="w-full flex "
          onClick={handleUpdateKhata}
        >
          Update
        </Button>
      </div>
    </BasicModal>
  );
}

const KhataDetails = ({ employee, setShowKhataDetails }) => {
  const [loading, setLoading] = useState(false);
  const [employeeKhata, setEmployeeKhata] = useState([]);
  const [isEditKhataModalOpen, setIsEditKhataModalOpen] = useState(false);
  const [selectedKhata, setSelectedKhata] = useState({});
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);

  const handleEditKhata = (khata) => {
    setSelectedKhata(khata);
    setIsEditKhataModalOpen(true);
  };

  const fetchKhataOfEmployee = async () => {
    try {
      setLoading(true);
      const query = `?partyName=${employee.partyName}`;
      const res = await khataOfAEmployee(query);
      setEmployeeKhata(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchKhataOfEmployee();
  }, []);

  const deleteKhata = async (id) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this khata?"
      );
      if (!shouldDelete) {
        return;
      }

      setLoading(true);
      const res = await deleteKhataOfAEmployee(id);
      toast.success(getSuccessMessage(res));

      setEmployeeKhata((prevKhata) =>
        prevKhata.filter((khata) => khata._id !== id)
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleStartNextMonthKhata = async () => {
    try {
      setLoading(true);
      const query = `?partyName=${employee.partyName}`;
      const res = await startNextMonthKhataOfAEmployee(query);
      toast.success(getSuccessMessage(res));
      await fetchKhataOfEmployee();
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  return (
    <div>
      <EditKhataModal
        setLoading={setLoading}
        loading={loading}
        isEditKhataModalOpen={isEditKhataModalOpen}
        setIsEditKhataModalOpen={setIsEditKhataModalOpen}
        selectedKhata={selectedKhata}
        setSelectedKhata={setSelectedKhata}
        fetchKhataOfEmployee={fetchKhataOfEmployee}
      />
      <Gap>Employee Details</Gap>
      <div className="flex gap-4 p-4 border  bg-white shadow-md">
        <div className="flex flex-col gap-2 w-full">
          <div className="flex justify-between gap-5 lg:items-center w-full lg:flex-row flex-col">
            <div className="text-lg font-semibold text-gray-900">
              <span className="font-bold mr-2"> Name:</span>
              {employee.partyName}
            </div>
            <div className="flex gap-2 text-gray-700 ">
              <Button
                rounded={"none"}
                colorScheme="blue"
                className="w-fit flex self-end"
                onClick={() => handleStartNextMonthKhata(employee)}
              >
                Start Next Month
              </Button>
              <Button
                rounded={"none"}
                colorScheme="blue"
                className="w-fit flex self-end"
                onClick={() => {
                  setShowKhataDetails(false);
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Gap>Employee Khata</Gap>
      <Box>
        {isMobileView ? (
          employeeKhata.map((employeeData, index) => (
            <Box
              key={index}
              p={4}
              border="1px solid"
              borderColor="gray.200"
              borderRadius="md"
              bg="white"
              mb={4}
            >
              <Box mb={2}>
                <span className="font-bold mr-2">Month:</span>{" "}
                {months(employeeData?.month - 1).toUpperCase()}
              </Box>
              <Box mb={2}>
                <span className="font-bold mr-2">Year:</span>{" "}
                {employeeData.year}
              </Box>
              <Box mb={2}>
                <span className="font-bold mr-2">Base Pay:</span>{" "}
                {employeeData.basePay}
              </Box>
              <div className="flex flex-col gap-2">
                <Box mb={2}>
                  <span className="font-bold mr-2">Advance Deductions:</span>
                  <Table variant="striped" colorScheme="blue">
                    <Thead className="bg-blue-50">
                      <Tr>
                        <Th>Date of Payment</Th>
                        <Th>Amount Paid</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {employeeData?.advanceDeductions?.map((payment) => (
                        <Tr key={payment._id}>
                          <Td>
                            {moment(payment.dateOfPayment).format(
                              "DD MMM YYYY"
                            )}
                          </Td>
                          <Td>{payment.amountPaid}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box mb={2} className="overflow-x-auto">
                  <span className="font-bold mr-2">Over Time Hours:</span>
                  <Table variant="striped" colorScheme="green">
                    <Thead className="bg-green-50">
                      <Tr>
                        <Th>Hours</Th>
                        <Th>Date of Payment</Th>
                        <Th>Amount Paid</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {employeeData?.overTimeHours?.map((payment) => (
                        <Tr key={payment._id}>
                          <Td>{payment.hours}</Td>
                          <Td>
                            {moment(payment.dateOfPayment).format(
                              "DD MMM YYYY"
                            )}
                          </Td>
                          <Td>{payment.amountPaid}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </div>
              <div className="flex gap-2 items-center mt-2">
                <Button
                  className="flex gap-2 text-gray-700 w-full hover:cursor-pointer"
                  colorScheme="blue"
                  onClick={() => handleEditKhata(employeeData)}
                >
                  <span className="font-bold ml-2">Edit</span>
                </Button>
                <Button
                  className="flex gap-2 text-gray-700 w-full hover:cursor-pointer"
                  colorScheme="red"
                  onClick={() => deleteKhata(employeeData._id)}
                >
                  <span className="font-bold ml-2">Delete</span>
                </Button>
              </div>
            </Box>
          ))
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Month</Th>
                <Th>Year</Th>
                <Th>Base Pay</Th>
                <Th>Advance Deductions</Th>
                <Th>Over Time Hours</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {employeeKhata.map((employeeData, index) => (
                <Tr key={index}>
                  <Td>{months(employeeData?.month - 1).toUpperCase()}</Td>
                  <Td>{employeeData.year}</Td>
                  <Td>{employeeData.basePay}</Td>
                  <Td>
                    <Table variant="striped">
                      <Thead className="bg-gray-50">
                        <Tr>
                          <Th>Date of Payment</Th>
                          <Th>Amount Paid</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {employeeData?.advanceDeductions?.map((payment) => (
                          <Tr key={payment._id}>
                            <Td>
                              {moment(payment.dateOfPayment).format(
                                "DD MMM YYYY"
                              )}
                            </Td>
                            <Td>{payment.amountPaid}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Td>
                  <Td>
                    <Table variant="striped">
                      <Thead className="bg-gray-50">
                        <Tr>
                          <Th>Hours</Th>
                          <Th>Date of Payment</Th>
                          <Th>Amount Paid</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {employeeData?.overTimeHours?.map((payment) => (
                          <Tr key={payment._id}>
                            <Td>{payment.hours}</Td>
                            <Td>
                              {moment(payment.dateOfPayment).format(
                                "DD MMM YYYY"
                              )}
                            </Td>
                            <Td>{payment.amountPaid}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Td>
                  <Td className="flex gap-2">
                    <FaEdit
                      size={20}
                      onClick={() => handleEditKhata(employeeData)}
                      className="hover:cursor-pointer text-blue-500"
                    />
                    <FaTrashAlt
                      size={20}
                      onClick={() => deleteKhata(employeeData._id)}
                      className="hover:cursor-pointer text-red-500"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </div>
  );
};

export default KhataDetails;
