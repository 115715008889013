import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { itemsPerPage } from "../../constant";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import { removeCustomer, updateCustomer } from "../../redux/customerSlice";
import { addCustomer, deleteCustomer } from "../../services/customerApi";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import Loader from "../../UI/Loader";

const Customers = () => {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    address: "",
    phoneNumber: "",
    gst: "",
    email: "",
    whatsappNumber: "",
  });
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customer.customers);
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredProducts = customers.filter(
    (product) =>
      (product.name &&
        product.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (product.address &&
        product.address.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (product.phoneNumber &&
        product.phoneNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (product.email &&
        product.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (product.gst &&
        product.gst.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (product.whatsappNumber &&
        product.whatsappNumber.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await addCustomer({ ...customerDetails, isEdit });
      dispatch(updateCustomer(res.data));
      setCustomerDetails({
        name: "",
        address: "",
        phoneNumber: "",
        gst: "",
        email: "",
        whatsappNumber: "",
      });
      setIsEdit(false);
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (customer) => {
    setCustomerDetails(customer);
    setIsEdit(true);
  };

  const handleDeleteCustomer = async (customer) => {
    try {
      setLoading(true);
      const confirmed = window.confirm(
        "Are you sure you want to delete this customer?"
      );
      if (confirmed) {
        await deleteCustomer(customer._id);
        dispatch(removeCustomer(customer._id));
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };
  const offset = currentPage * itemsPerPage;
  const endOffset = offset + itemsPerPage;

  const currentProducts = filteredProducts.slice(
    offset,
    Math.min(endOffset, filteredProducts.length)
  );

  if (loading) return <Loader />;

  return (
    <div>
      <Gap>Enter Customer Details</Gap>
      <form
        className="flex lg:flex-wrap lg:flex-row gap-4 flex-col"
        onSubmit={handleSubmit}
      >
        <InputField
          labelName="Name"
          type="text"
          required
          uni="name"
          value={customerDetails.name}
          onChange={handleChange}
        />
        <InputField
          labelName="Address"
          type="text"
          required
          uni="address"
          value={customerDetails.address}
          onChange={handleChange}
        />
        <InputField
          labelName="Phone No"
          type="tel"
          required
          uni="phoneNumber"
          value={customerDetails.phoneNumber}
          onChange={handleChange}
        />
        <InputField
          labelName="GST"
          type="text"
          uni="gst"
          value={customerDetails.gst}
          onChange={handleChange}
        />
        <InputField
          labelName="Email"
          type="email"
          uni="email"
          value={customerDetails.email}
          onChange={handleChange}
        />
        <InputField
          labelName="Whatsapp No"
          type="tel"
          uni="whatsappNumber"
          value={customerDetails.whatsappNumber}
          onChange={handleChange}
        />
        <Button
rounded={"none"}
          type="submit"
          variant="solid"
          colorScheme="blue"
          size="md"
          className="mt-6"
        >
          Add
        </Button>
      </form>
      <Gap>Customers</Gap>
      <InputField
        type="text"
        placeholder="Search customers..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16  text-sm focus:outline-none"
      />
      <div className="overflow-y-auto">
        {isMobileView ? (
          <div className="flex flex-col gap-4 p-4">
            {currentProducts.map((customer, index) => (
              <div key={index} className="bg-gray-100 p-2  shadow-md">
                <div className="font-bold">{customer.name}</div>
                <div className="flex flex-col gap-1">
                  <div>
                    <span className="font-semibold">Address:</span>{" "}
                    {customer.address}
                  </div>
                  <div>
                    <span className="font-semibold">Phone Number:</span>{" "}
                    {customer.phoneNumber}
                  </div>
                  <div>
                    <span className="font-semibold">GST:</span> {customer.gst}
                  </div>
                  <div>
                    <span className="font-semibold">Email:</span>{" "}
                    {customer.email}
                  </div>
                  <div>
                    <span className="font-semibold">Whatsapp No:</span>{" "}
                    {customer.whatsappNumber}
                  </div>
                  <div className="flex gap-2 mt-2">
                    {isEdit ? (
                      <Button
rounded={"none"}
                        onClick={() => {
                          setIsEdit(false);
                          setCustomerDetails({
                            name: "",
                            address: "",
                            phoneNumber: "",
                            gst: "",
                            email: "",
                            whatsappNumber: "",
                          });
                        }}
                      >
                        <MdCancel size={20} color="blue" />
                      </Button>
                    ) : (
                      <>
                        <Button
rounded={"none"} onClick={() => handleEdit(customer)} size="xs">
                          <FaEdit size={20} color="blue" />
                        </Button>
                        <Button
rounded={"none"}
                          onClick={() => handleDeleteCustomer(customer)}
                          size="xs"
                        >
                          <FaTrashAlt size={20} color="red" />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Table variant="striped" className="mt-4">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Address</Th>
                <Th>Phone Number</Th>
                <Th>GST</Th>
                <Th>Email</Th>
                <Th>Whatsapp No</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentProducts.map((customer, index) => (
                <Tr key={index}>
                  <Td>{customer.name}</Td>
                  <Td>{customer.address}</Td>
                  <Td>{customer.phoneNumber}</Td>
                  <Td>{customer.gst}</Td>
                  <Td>{customer.email}</Td>
                  <Td>{customer.whatsappNumber}</Td>
                  <Td className="flex">
                    {isEdit ? (
                      <Button
rounded={"none"}
                        onClick={() => {
                          setIsEdit(false);
                          setCustomerDetails({
                            name: "",
                            address: "",
                            phoneNumber: "",
                            gst: "",
                            email: "",
                            whatsappNumber: "",
                          });
                        }}
                      >
                        <MdCancel size={24} color="blue" />
                      </Button>
                    ) : (
                      <>
                        <Button
rounded={"none"} onClick={() => handleEdit(customer)}>
                          <FaEdit size={24} color="blue" />
                        </Button>
                        <Button
rounded={"none"} onClick={() => handleDeleteCustomer(customer)}>
                          <FaTrashAlt size={24} color="red" />
                        </Button>
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </div>

      <div className="flex lg:p-5 lg:w-full p-1 w-4/5">
        <ReactPaginate
          nextLabel={"Next"}
          previousLabel={"Back"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={isMobileView ? 1 : 2}
          pageRangeDisplayed={isMobileView ? 1 : 5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          className="flex gap-3 pagination page-link page-item"
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};
export default Customers;
