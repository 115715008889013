import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import Loader from "../../UI/Loader";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import {
  getAllAttendance,
  toggleAttendanceOfAEmployee,
} from "../../services/attendanceApi";
import AttendanceDetails from "./AttendanceDetails";

const Attendance = () => {
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [showFullAttendanceDetails, setShowFullAttendanceDetails] =
    useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);

  const fetchAttendance = async () => {
    try {
      setLoading(true);
      const res = await getAllAttendance();
      setAttendance(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAttendance();
  }, []);

  const handleAddAttendance = async (employeeId, date) => {
    try {
      if (!employeeId) {
        return toast.error("Please select an employee");
      }
      setLoading(true);
      const res = await toggleAttendanceOfAEmployee({
        employeeId,
        date: date ? date : moment(),
      });
      fetchAttendance();
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleShowFullAttendanceDetails = (employee) => {
    setSelectedEmployee(employee);
    setShowFullAttendanceDetails(true);
  };

  const isEmployeePresentToday = (data) => {
    const today = moment().format("YYYY-MM-DD");
    return data.date === today && data.isPresent;
  };

  if (loading) return <Loader />;

  return showFullAttendanceDetails ? (
    <AttendanceDetails
      employee={selectedEmployee}
      setShowFullAttendanceDetails={setShowFullAttendanceDetails}
      fetchAttendance={fetchAttendance}
      handleAddAttendance={handleAddAttendance}
      setLoading={setLoading}
      loading={loading}
    />
  ) : (
    <div className="p-0">
      <Gap>Attendance</Gap>
      <div className="overflow-y-auto">
        {isMobileView ? (
          <div className="flex flex-col gap-4 p-4">
            {attendance.map((employee, index) => (
              <div key={index} className="bg-gray-100 p-2  shadow-md">
                <div className="flex items-center justify-between mb-2">
                  <div className="text-lg font-bold">{employee.name}</div>
                </div>
                <div className="flex flex-wrap gap-2">
                  <div className="flex items-center gap-2 border border-gray-300 p-2">
                    <InputField
                      type="checkbox"
                      checked={isEmployeePresentToday(employee)}
                      onChange={() => handleAddAttendance(employee.employeeId)}
                    />
                    <span>{moment().format("DD MMM YYYY")}</span>
                  </div>

                  <div className="flex items-center justify-between">
                    <Button
                      rounded={"none"}
                      colorScheme="blue"
                      onClick={() => handleShowFullAttendanceDetails(employee)}
                    >
                      <FaEye size={20} />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Table variant="striped" className="mt-4">
            <Thead className="bg-gray-50">
              <Tr>
                <Th>S.No</Th>
                <Th>Name</Th>

                <Th>{moment().format("DD MMM YYYY")}</Th>

                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {attendance.map((employee, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{employee.name}</Td>

                  <Td>
                    <div className="flex items-center gap-2">
                      <InputField
                        type={"checkbox"}
                        checked={isEmployeePresentToday(employee)}
                        onChange={() =>
                          handleAddAttendance(employee.employeeId)
                        }
                      />
                    </div>
                  </Td>

                  <Td>
                    <Button
                      rounded={"none"}
                      colorScheme="blue"
                      onClick={() => handleShowFullAttendanceDetails(employee)}
                    >
                      <FaEye size={20} />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default Attendance;
