import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import Loader from "../../../UI/Loader";
import { State } from "country-state-city";
import { useSelector } from "react-redux";
import InputField from "../../../UI/InputField";
import { FaEye } from "react-icons/fa";
import { toast } from "sonner";
import Gap from "../../../UI/Gap";
import SelectField from "../../../UI/SelectField";
import { getErrorMessage, getSuccessMessage } from "../../../functions";
import { addInvoice } from "../../../services/invoiceApi";
import ItemList from "./ItemList";
import LivePreview from "./LivePreview";

const CreateInvoice = ({ setIsCreate, setInvoices, defaultInvoiceNumber }) => {
  const companyDetails = useSelector(
    (state) => state.companyDetails.companyDetails
  );
  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(defaultInvoiceNumber + 1);
  const [eWayBillNo, setEWayBillNo] = useState("");
  const [vehicle, setVehicle] = useState(companyDetails?.vehicle);
  const [transport, setTransport] = useState(companyDetails?.transport);
  const [station, setStation] = useState(companyDetails?.station);
  const [billType, setBillType] = useState("");
  const [billTo, setBillTo] = useState("");
  const [items, setItems] = useState([]);
  const [stateOfSupply, setStateOfSupply] = useState("");
  const [billToId, setBillToId] = useState("");
  const [customerDetails, setCustomerDetails] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const products = useSelector((state) => state.product.products);
  const dealers = useSelector((state) => state.dealer.dealers);
  const customers = useSelector((state) => state.customer.customers);
  const States = State.getStatesOfCountry("IN");

  const handleProductSelect = ({ value }) => {
    const isProductInItems = items.some((item) => item._id === value._id);
    if (!isProductInItems) {
      setItems([
        ...items,
        {
          ...value,
          quantity: 1,
          isTaxIncluded: true,
        },
      ]);
    }
  };
  const handleDeleteItem = (productId) => {
    const updatedItems = items.filter((item) => item._id !== productId);
    setItems(updatedItems);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (items.length < 1) {
        return toast.error("Please add atleast 1 item");
      }
      if (!invoiceNumber) {
        return toast.error("Please enter invoice number");
      }
      if (!billType) {
        return toast.error("Please select invoice type");
      }
      if (!billTo) {
        return toast.error("Please select whom to bill");
      }
      if (!billToId) {
        return toast.error("Please select a customer or dealer");
      }
      if (!stateOfSupply) {
        return toast.error("Please select a state");
      }
      const res = await addInvoice({
        items,
        invoiceNumber,
        billType,
        billTo,
        billToId,
        stateOfSupply,
        eWayBillNo,
        vehicle,
        station,
        transport,
      });
      setInvoices((prevInvoices) => [res.data, ...prevInvoices]);
      setIsCreate(false);
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const togglePreview = () => {
    setShowPreview((prev) => !prev);
  };
  if (loading) return <Loader />;

  return (
    <div>
      <div>
        <Gap>Create Invoice</Gap>
        <div className="flex lg:flex-wrap lg:flex-row gap-4 flex-col">
          <InputField
            labelName="Invoice Number"
            type="text"
            required
            uni="invoiceNumber"
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
          />
          <InputField
            labelName="E Way Bill Number"
            type="text"
            uni="eWayBillNo"
            value={eWayBillNo}
            onChange={(e) => setEWayBillNo(e.target.value)}
          />
          <InputField
            labelName="Vehicle"
            type="text"
            uni="vehicle"
            value={vehicle}
            onChange={(e) => setVehicle(e.target.value)}
          />
          <InputField
            labelName="Transport"
            type="text"
            uni="transport"
            value={transport}
            onChange={(e) => setTransport(e.target.value)}
          />
          <InputField
            labelName="Station"
            type="text"
            uni="station"
            value={station}
            onChange={(e) => setStation(e.target.value)}
          />
          <div className="lg:w-1/6">
            <SelectField
              labelName={"State Of Supply"}
              inputClass={"w-fit"}
              options={States}
              onChange={({ value }) => {
                setStateOfSupply(value.name);
              }}
            />
          </div>
          <div className="lg:w-1/6">
            <SelectField
              labelName={"Invoice Type"}
              inputClass={"w-fit"}
              options={[
                { name: "Tax Invoice", value: "Tax Invoice" },
                {
                  name: "Sell Invoice",
                  value: "Sell Invoice",
                },
              ]}
              onChange={({ value }) => {
                setBillType(value.value);
              }}
            />
          </div>
          <div className="lg:w-1/6">
            <SelectField
              labelName={"Bill To"}
              inputClass={"w-fit"}
              options={[
                { name: "Dealer", value: "Dealer" },
                {
                  name: "Customer",
                  value: "Customer",
                },
              ]}
              onChange={({ value }) => {
                setBillTo(value.value);
              }}
            />
          </div>
          <div className="lg:w-1/6">
            {billTo === "Customer" ? (
              <SelectField
                labelName={"Customers"}
                options={customers}
                onChange={({ value }) => {
                  setBillToId(value._id);
                  setCustomerDetails(value);
                }}
              />
            ) : (
              <SelectField
                labelName={"Dealers"}
                options={dealers}
                onChange={({ value }) => {
                  setBillToId(value._id);
                  setCustomerDetails(value);
                }}
              />
            )}
          </div>
          <div className="lg:w-1/6">
            <SelectField
              labelName={"Products"}
              options={products.filter(
                (product) => !items.some((item) => item._id === product._id)
              )}
              onChange={(value) => handleProductSelect(value)}
            />
          </div>
        </div>

        <div>
          <ItemList
            products={products}
            items={items}
            handleProductSelect={handleProductSelect}
            handleDeleteItem={handleDeleteItem}
            setItems={setItems}
          />
        </div>
        <div className="flex gap-2">
          <Button
rounded={"none"}
            type="submit"
            variant="solid"
            colorScheme="blue"
            size="md"
            className="mt-6"
            onClick={handleSubmit}
          >
            Submit
          </Button>

          <Button
rounded={"none"}
            variant="solid"
            colorScheme="blue"
            size="md"
            className="mt-6"
            onClick={togglePreview}
          >
            <FaEye size={20} />
          </Button>
        </div>
      </div>
      {showPreview && (
        <LivePreview
          invoice={{
            billType,
            vehicle,
            companyDetails,
            transport,
            station,
            customerDetails,
            invoiceNumber,
            stateOfSupply,
            eWayBillNo,
            billToId,
            items,
            isTaxIncluded: true,
          }}
        />
      )}
    </div>
  );
};
export default CreateInvoice;
