import {
  Button,
  FormControl,
  HStack,
  PinInput,
  PinInputField,
  Stack
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Logo } from "../components/Login/Logo";
import { getErrorMessage, getSuccessMessage } from "../functions";
import { resetPassword, sendOtp, verifyOtp } from "../services/userApi";
import InputField from "../UI/InputField";

function SendEmail({ email, setEmail, handleSendOtp }) {
  return (
    <div className="flex flex-col justify-center items-center gap-4 w-full">
      <FormControl>
        <InputField
          id="email"
          labelName={"Email"}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>

      <Button
rounded={"none"}
        variant="primary"
        className="bg-primary text-white w-full"
        onClick={handleSendOtp}
      >
        Submit
      </Button>
    </div>
  );
}

const VerifyOtp = ({ otp, setOtp, handleVerifyOtp }) => {
  return (
    <Stack spacing="6">
      <Stack spacing="5">
        <HStack>
          <PinInput
            onChange={(value) => setOtp(value)}
            value={otp}
            otp
            autocomplete="one-time-code"
          >
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>

        <Button
rounded={"none"}
          variant="primary"
          className="bg-primary text-white w-full"
          onClick={handleVerifyOtp}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

function ResetPassword({ email, navigate, setActiveStep }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleResetPassword = async () => {
    try {
      if (!email || !password || !confirmPassword)
        return toast.info("Please fill all fields");
      if (password !== confirmPassword)
        return toast.info("Passwords do not match");
      if (password.length < 8)
        return toast.info("Password must be at least 8 characters");
      const res = await resetPassword({ email, password, confirmPassword });
      toast.success(getSuccessMessage(res));
      navigate("/login");
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-4 w-full">
      <FormControl>
        <InputField
          id="email"
          type="email"
          value={email}
          disabled={true}
          labelName={"Email"}
        />
        <InputField
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          labelName={"Password"}
        />
        <InputField
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          labelName={"Confirm Password"}
        />
      </FormControl>

      <Button
rounded={"none"}
        variant="primary"
        className="bg-primary text-white w-full"
        onClick={handleResetPassword}
      >
        Submit
      </Button>
      <Button
rounded={"none"}
        variant="link"
        colorScheme="blue"
        size="sm"
        onClick={() => setActiveStep(1)}
      >
        Not your email? Resend OTP
      </Button>
    </div>
  );
}

export const ForgotPassword = () => {
  const { auth } = useSelector((state) => state);
  const { isAuth } = auth;
  if (isAuth) window.location.href = "/dashboard";

  const [email, setEmail] = useState("");

  const [activeStep, setActiveStep] = useState(1);
  const [otp, setOtp] = useState("");

  const handleSendOtp = async () => {
    try {
      if (!email) return toast.info("Please enter your email");

      const res = await sendOtp({ email });
      toast.success(getSuccessMessage(res));
      setActiveStep(2);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };
  const navigate = useNavigate();
  const handleVerifyOtp = async () => {
    try {
      if (!email || !otp) return toast.info("Please fill all fields");

      const res = await verifyOtp({ email, otp });
      toast.success(getSuccessMessage(res));
      setActiveStep(3);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const renderStep = () => {
    switch (activeStep) {
      case 1:
        return (
          <SendEmail
            email={email}
            setEmail={setEmail}
            handleSendOtp={handleSendOtp}
          />
        );
      case 2:
        return (
          <VerifyOtp
            otp={otp}
            setOtp={setOtp}
            handleVerifyOtp={handleVerifyOtp}
          />
        );
      case 3:
        return (
          <ResetPassword
            email={email}
            navigate={navigate}
            setActiveStep={setActiveStep}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Stack spacing="2">
      <div className="flex justify-center mt-20">
        <Logo />
      </div>
      <Stack
        spacing={{
          base: "2",
          md: "3",
        }}
        textAlign="center"
      ></Stack>
      <div className="shadow-lg rounded-xl p-4 bg-white flex flex-col justify-center items-center gap-4 lg:w-1/3 mx-auto w-11/12">
        {renderStep()}
      </div>
      <Button
rounded={"none"}
        variant="link"
        colorScheme="blue"
        size="sm"
        onClick={() => navigate("/login")}
      >
        Already have an account? Sign in
      </Button>
    </Stack>
  );
};

export default ForgotPassword;
