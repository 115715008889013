import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputField from "../../../UI/InputField";
import { getInputType } from "../../../functions";

const EditQrDataModal = ({ isOpen, onClose, qrData, updateQrData }) => {
  const [localData, setLocalData] = useState([]);
  useEffect(() => {
    if (qrData) {
      setLocalData(qrData?.data?.map((item) => ({ ...item })));
    }
  }, [qrData]);

  const handleUpdate = () => {
    updateQrData(qrData._id, { data: localData });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit QR Data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {localData?.map((item, index) => (
            <InputField
              key={index}
              labelName={item.labelName}
              value={item.value}
              type={
                item.labelType === "select"
                  ? "text"
                  : getInputType(item.labelType)
              }
              onChange={(e) => {
                const updatedData = [...localData];
                updatedData[index].value = e.target.value;
                setLocalData(updatedData);
              }}
            />
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
rounded={"none"} colorScheme="blue" mr={3} onClick={handleUpdate}>
            Update
          </Button>
          <Button
rounded={"none"} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditQrDataModal;
