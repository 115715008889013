import React, { useEffect, useState } from "react";
import InputField from "../../UI/InputField";

import Gap from "../../UI/Gap";
import { toast } from "sonner";

import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../services/userApi";
import { updateUser as updateUserReducer } from "../../redux/authSlice";
import Loader from "../../UI/Loader";
import { useNavigate } from "react-router-dom";
import { getSuccessMessage } from "../../functions";
import { Button } from "@chakra-ui/react";
const Profile = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const { auth } = useSelector((state) => state);

  const navigate = useNavigate();

  const updateUserInfo = async () => {
    try {
      if (!user?.firstName) return toast.info("First Name can't be empty");
      setLoading(true);

      const res = await updateUser(user?._id, user);

      toast.success(getSuccessMessage(res));
      dispatch(
        updateUserReducer({
          user: res.user,
        })
      );
      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      else toast.error(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.user) setUser(auth.user);
  }, []);



  if (loading) return <Loader />;

  return (
    <>
      <div >
        <form className="flex flex-col">
          <Gap>Basic Info</Gap>
          <div className="flex lg:flex-wrap lg:flex-row flex-col gap-4 w-full ">
            <InputField
              labelName="First Name"
              type="text"
              inputClass={"w-full"}
              placeholder="Enter First Name"
              value={user?.firstName}
              onChange={(e) => {
                setUser({ ...user, firstName: e.target.value });
              }}
            />
            <InputField
              labelName="Last Name"
              type="text"
              placeholder="Enter Last Name"
              value={user?.lastName}
              onChange={(e) => {
                setUser({ ...user, lastName: e.target.value });
              }}
            />

            <InputField
              labelName="Email"
              type="text"
              disabled={true}
              placeholder="Enter Email"
              value={user?.email}
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
              }}
            />
            <InputField
              labelName="Phone Number"
              disabled={true}
              type="number"
              value={user?.phoneNumber}
              onChange={(e) => {
                setUser({
                  ...user,
                  phoneNumber: e.target.value,
                });
              }}
              placeholder="Enter Phone Number"
            />
          </div>

          <Button
rounded={"none"}
            className="bg-primary text-white rounded-xl p-2 mt-4"
            type="button"
            colorScheme="blue"
            onClick={updateUserInfo}
          >
            {"Update"}
          </Button>
        </form>
      </div>
    </>
  );
};

export default Profile;
