import React from "react";
import InputField from "../../../UI/InputField";
import SelectField from "../../../UI/SelectField";
import { Button } from "@chakra-ui/react";
import { FaTrashAlt } from "react-icons/fa";

export default function InventoryItemList({
  items,
  handleDeleteItem,
  setItems,
}) {
  const handleChangeItemValue = (e, itemToUpdate, index) => {
    const { name, value } = e.target;
    const updatedItems = items?.map((item, idx) =>
      idx === index && item._id === itemToUpdate._id
        ? { ...item, [name]: value }
        : item
    );

    setItems(updatedItems);
  };
  const handleSelectItemValue = (name, value, itemToUpdate, index) => {
    const updatedItems = items?.map((item, idx) =>
      idx === index && item._id === itemToUpdate._id
        ? { ...item, [name]: value }
        : item
    );

    setItems(updatedItems);
  };

  return (
    <div className="flex flex-wrap gap-4 flex-col overflow-y-auto mt-4 ">
      {items.map((item, index) => (
        <div key={index} className="flex flex-col gap-4 ">
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-10 lg:items-center">
            <InputField
              disabled
              value={item.name}
              labelName={"Product Name"}
              className="w-full"
            />
            <InputField
              labelName={"Quantity"}
              name="itemQuantity"
              type={"number"}
              value={item.itemQuantity}
              uni="itemQuantity"
              onChange={(e) => handleChangeItemValue(e, item, index)}
              className="w-full"
            />
            <InputField
              labelName={"Note"}
              name="note"
              type={"textarea"}
              value={item.note}
              uni="note"
              onChange={(e) => handleChangeItemValue(e, item, index)}
              className="w-full"
            />

            <SelectField
              labelName={"Status"}
              name="status"
              value={{
                label: item.status,
                value: item.status,
              }}
              selectStyles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%", // Adjust width as needed
                }),
              }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              options={[
                {
                  label: "Manufactured",
                  name: "Manufactured",
                },
                {
                  label: "Sold",
                  name: "Sold",
                },
              ]}
              onChange={({ value }) => {
                handleSelectItemValue("status", value.name, item, index);
              }}
              className="w-full"
            />
            <Button
rounded={"none"}
              colorScheme="red"
              onClick={() => handleDeleteItem(item._id, item.status)}
              className="mt-4 lg:mt-0"
            >
              <FaTrashAlt size={20} />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
