import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import dealerReducer from "./dealerSlice";
import customerReducer from "./customerSlice";
import productReducer from "./productSlice";
import companyDetailsReducer from "./companyDetailsSlice";
import qrSettingsReducer from "./qrSettingsSlice";
import mobileViewReducer from "./mobileViewSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    dealer: dealerReducer,
    customer: customerReducer,
    product: productReducer,
    companyDetails: companyDetailsReducer,
    qrSettings: qrSettingsReducer,
    mobileView: mobileViewReducer,
  },
});
export default store;
