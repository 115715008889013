import React from "react";
import CommonModal from "../../common/CommonModal";
import InputField from "../../../UI/InputField";
import { Button } from "@chakra-ui/react";

const QRAddInBulkModal = ({
  noOfQr,
  setNoOfQr,
  showBulkModal,
  setShowBulkModal,
  qrSettings,
  setFieldToIncrement,
  setBulkStartRange,
  handleBulkCreate,
  bulkStartRange,
}) => {
  return (
    <CommonModal isOpen={showBulkModal} onClose={() => setShowBulkModal(false)}>
      <div className="flex flex-col gap-3">
        <InputField
          type={"Number"}
          labelName={"No. Of  QRs"}
          value={noOfQr}
          onChange={(e) => setNoOfQr(e.target.value)}
        />

        <InputField
          type={"select"}
          labelName={"Field To Increment"}
          options={qrSettings
            ?.filter((item) => item.labelType === "Number")
            ?.map((item) => {
              return {
                label: item.labelName,
                name: item.labelName,
              };
            })}
          onChange={({ value }) => setFieldToIncrement(value.name)}
        />

        <InputField
          type={"number"}
          labelName={"Start"}
          uni={"Start"}
          value={bulkStartRange}
          onChange={(e) => setBulkStartRange(e.target.value)}
        />

        <Button
rounded={"none"} colorScheme="blue" onClick={handleBulkCreate}>
          Submit
        </Button>
      </div>
    </CommonModal>
  );
};

export default QRAddInBulkModal;
