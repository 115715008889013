import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import { toast } from "sonner";
import { Logo } from "./Logo";

import { PasswordField } from "./PasswordField";
import { login } from "../../services/userApi";
import Loader from "../../UI/Loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login as loginReducer } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import imageConstant from "../../imageConstant";
import { getErrorMessage, getSuccessMessage } from "../../functions";
export const Login = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const { isAuth } = auth;
  if (isAuth) window.location.href = "/dashboard";
  const [loading, setLoading] = useState(false); // [1
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const handleLogin = async () => {
    try {
      if (!email || !password) return toast.info("Please fill all fields");
      setLoading(true);
      const res = await login({ email, password });
      toast.success(getSuccessMessage(res));
      dispatch(
        loginReducer({
          isAuth: true,
          isAdmin: res.data.isAdmin,
          token: res.data.token,
          user: res.data.user,
          classes: res.data.classes,
        })
      );
      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <div className="flex">
      <div className="w-1/2 h-screen hidden lg:flex flex-col justify-center items-center text-center">
        <img
          src={imageConstant.signup}
          className="w-1/2 p-10 object-cover fixed"
          alt="login"
        />
      </div>

      <Container className="mt-20">
        <Stack spacing="2">
          <Stack spacing="2">
            <div className="flex justify-center">
              <Logo />
            </div>
            <Stack
              spacing={{
                base: "2",
                md: "3",
              }}
              textAlign="center"
            >
              <Heading
                size={{
                  base: "xs",
                  md: "sm",
                }}
              >
                Log in to your account
              </Heading>
              {/* <HStack spacing="1" justify="center">
              <Text color="muted">Don't have an account?</Text>
              <Button
rounded={"none"} variant="link" colorScheme="blue">
                Sign up
              </Button>
            </HStack> */}
            </Stack>
          </Stack>
          <Box
            py={{
              base: "0",
              sm: "8",
            }}
            px={{
              base: "4",
              sm: "10",
            }}
            bg={{
              base: "transparent",
              sm: "bg-surface",
            }}
            boxShadow={{
              base: "none",
              sm: "md",
            }}
            borderRadius={{
              base: "none",
              sm: "xl",
            }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <PasswordField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Stack>
              <HStack justify="space-between" className="flex flex-wrap">
                {/* <Checkbox defaultChecked>Remember me</Checkbox> */}
                <Button
rounded={"none"}
                  variant="link"
                  colorScheme="blue"
                  size="sm"
                  onClick={() => navigate("/signup")}
                >
                  Don&apos;t have an account? Sign up
                </Button>
                <Button
rounded={"none"}
                  variant="link"
                  colorScheme="blue"
                  size="sm"
                  onClick={() => navigate('/forgot-password')}
                >
                  Forgot Password?
                </Button>
              </HStack>
              <Stack spacing="6">
                <Button
rounded={"none"}
                  variant="primary"
                  className="bg-primary text-white"
                  onClick={handleLogin}
                >
                  Sign in
                </Button>
                {/* <HStack>
                <Divider />
                <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                  or continue with
                </Text>
                <Divider />
              </HStack> */}
                {/* <OAuthButtonGroup /> */}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </div>
  );
};

export default Login;
