import http from "./http";

const attendanceApi = "/api/attendance";

export const addAttendance = (data) => {
  return http.post(`${attendanceApi}/add`, data);
};

export const getAllAttendance = () => {
  return http.get(`${attendanceApi}/get`);
};

export const getAttendanceOfAEmployee = (employeeId) => {
  return http.get(`${attendanceApi}/get/${employeeId}`);
};

export const toggleAttendanceOfAEmployee = (data) => {
  return http.put(`${attendanceApi}/toggle`, data);
};

export const updateAttendanceOfAEmployee = (employeeId, data) => {
  if (!employeeId) {
    return alert("employeeId is missing");
  }
  return http.put(`${attendanceApi}/update/${employeeId}`, data);
};

export const getTodaysAttendance = () => {
  return http.get(`${attendanceApi}/today`);
};

export const getYearlyAttendance = (query) => {
  return http.get(`${attendanceApi}/year${query}`);
};
