import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsAndroid } from "react-icons/bs";
import { FiLogOut, FiMenu } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../components/Login/Logo";
import { Pages, ROLES } from "../constant";
import { logout } from "../redux/authSlice";
import { emptyCustomerDetails } from "../redux/companyDetailsSlice";
import { emptyCustomers } from "../redux/customerSlice";
import { emptyDealers } from "../redux/dealerSlice";
import { emptyProducts } from "../redux/productSlice";
import { emptyItems } from "../redux/qrSettingsSlice";
import Layout from "./Layout";

export default function Sidebar() {
  const handleSetPage = (page) => {
    setPage(page);
  };
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const { user } = auth;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [page, setPage] = useState(user?.pagesPermissions?.[0] || null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const component = searchParams.get("component");
  useEffect(() => {
    if (component) setPage(component);
  }, [location.search]);

  const navigate = useNavigate();
  const SidebarContent = ({ ...props }) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      className="scrollbar-custom"
      overflowX="hidden"
      overflowY="auto"
      bg={useColorModeValue("white", "gray.800")}
      borderColor={useColorModeValue("inherit", "gray.700")}
      borderRightWidth="1px"
      w={isCollapsed ? "20" : "64"}
      onMouseEnter={() => setIsCollapsed(false)}
      onMouseLeave={() => setIsCollapsed(true)}
      transition="all 0.3s ease"
      {...props}
    >
      <Flex
        padding="4"
        align="center"
        justifyContent="center"
        h="14"
        transition="all 0.3s ease"
      >
        <Text
          fontSize="4xl"
          ml="2"
          mt={"6"}
          color={useColorModeValue("brand.500", "white")}
          fontWeight="semibold"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxW={isCollapsed ? "20" : "64"}
        >
          <Logo />
        </Text>
      </Flex>
      <Flex
        direction="column"
        as="nav"
        fontSize="md"
        color="gray.600"
        aria-label="Main Navigation"
        mt={"4"}
      >
        {Pages.map((item) => {
          if (!item.permission.includes(user?.role)) return null;
          return (
            <NavItem
              key={item.name}
              icon={item.icon}
              name={item.name}
              isDisabled={item.isDisabled}
              role={user?.role}
              hasPermission={user?.pagesPermissions.includes(item.name)}
              isCollapsed={isCollapsed}
            >
              {item.label}
            </NavItem>
          );
        })}
      </Flex>
    </Box>
  );

  const NavItem = (props) => {
    // const color = useColorModeValue("gray.600", "gray.300");

    const {
      icon,
      children,
      name,
      isDisabled,
      hasPermission,
      role,
      isCollapsed,
    } = props;

    const handleClick = () => {
      handleSetPage(name);
      onClose();
    };

    const flexColor = useColorModeValue("gray.600", "gray.300");
    // const bgColor = useColorModeValue("gray.100", "gray.900");

    const isActive = searchParams.get("component") === name;

    if (!hasPermission && role !== ROLES.SUPER_ADMIN) return null;

    return (
      <Flex
        onClick={() => {
          handleClick();
          navigate({
            search: `?component=${name}`,
          });
        }}
        align="center"
        px="4"
        py="3"
        cursor={isDisabled ? "not-allowed" : "pointer"}
        role="group"
        fontWeight="semibold"
        transition="all 0.3s ease"
        color={isActive ? "white" : flexColor}
        backgroundColor={isActive ? "blue.500" : "white"}
        _hover={{
          color: isActive ? "white" : "blue.500",
        }}
      >
        {icon && (
          <Icon
            mx="2"
            boxSize={isCollapsed ? "5" : "4"}
            _groupHover={{
              color: isActive ? "white" : "blue.500",
            }}
            as={icon}
          />
        )}
        {!isCollapsed && children}
      </Flex>
    );
  };

  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(emptyDealers());
    dispatch(emptyCustomers());
    dispatch(emptyProducts());
    dispatch(emptyCustomerDetails());
    dispatch(emptyItems());
    navigate("/");
  };
  return (
    <Box
      as="section"
      // bg={useColorModeValue("gray.50", "gray.700")}
      minH="100vh"
    >
      <SidebarContent display={{ base: "none", md: "unset" }} />
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <Box
        ml={{ base: 0, md: isCollapsed ? 20 : 64 }}
        transition="all 0.3s ease"
      >
        <Flex
          as="header"
          align="center"
          justify={{ base: "space-between", md: "flex-end" }}
          w="full"
          px="4"
          borderBottomWidth="1px"
          borderColor={useColorModeValue("inherit", "gray.700")}
          bg={useColorModeValue("white", "gray.800")}
          boxShadow="sm"
          h="14"
        >
          <IconButton
            aria-label="Menu"
            display={{ base: "inline-flex", md: "none" }}
            onClick={onOpen}
            icon={<FiMenu />}
            size="md"
          />

          <h1
            className={`text-3xl ml-10 absolute ${
              isCollapsed ? "left-20" : "left-64"
            } lg:flex hidden`}
          >
            Welcome{"  "}
            <span className="text-blue-500 ml-2 capitalize">
              {" "}
              {auth?.user?.firstName}
            </span>
          </h1>

          <Flex align="center">
            <a
              className="flex items-center border p-1 w-fit  border-gray-300 text-white cursor-pointer bg-green-100"
              href="https://drive.usercontent.google.com/download?id=1R548Lr87OKMvrqe-pFObq0edEbbOPyc_&export=download"
              target="_blank"
              rel="noreferrer"
            >
              <h1 className="lg:text-xl lg:flex font-bold text-lg text-green-700 lg:text-green-500 lg:capitalize">
                Download App
              </h1>
              <Icon
                color="green.500"
                as={BsAndroid}
                ml="4"
                cursor="pointer"
                className="text-3xl"
              />
            </a>
            <Icon
              color="gray.500"
              as={FiLogOut}
              ml="4"
              cursor="pointer"
              className="text-3xl"
              onClick={handleLogout}
            />
          </Flex>
        </Flex>
        <div className="p-2">
          {Pages.map((item) => {
            if (!item.permission.includes(user?.role)) return null;
            if (item.name === page) {
              return (
                <Layout key={item.name}>
                  {React.cloneElement(item.component, { key: item.name })}
                </Layout>
              );
            }
          })}
        </div>
      </Box>
    </Box>
  );
}
