import { Box, Button, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emptyCustomerDetails } from "../../redux/companyDetailsSlice";
import { emptyCustomers } from "../../redux/customerSlice";
import { emptyDealers } from "../../redux/dealerSlice";
import { emptyProducts } from "../../redux/productSlice";
import { emptyItems } from "../../redux/qrSettingsSlice";

const AccountNotVerified = () => {
  const { auth } = useSelector((state) => state);
  const { isAuth } = auth;
  const dispatch = useDispatch();
  const { user } = auth;
  const [isMailSent, setIsMailSent] = useState(false);
  const handleLogout = () => {
    dispatch(emptyDealers());
    dispatch(emptyCustomers());
    dispatch(emptyProducts());
    dispatch(emptyCustomerDetails());
    dispatch(emptyItems());
  };
  if (!isAuth) window.location.href = "/login";
  if (user?.isAccountVerified) window.location.href = "/dashboard";

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      minH="100vh"
      px={{ base: "4", md: "12", lg: "48" }}
      pt="20"
      bg="gray.50"
    >
      <VStack spacing="6" align="center" textAlign="center">
        <Heading as="h2" size="xl">
          Account Status
        </Heading>
        <Box bg="white" p="6" rounded="md" shadow="md" w="full" maxW="lg">
          <Text fontSize="lg">
            {isMailSent
              ? `A mail has been sent to admin once your account is verified you will be redirected to the login page .`
              : `Your account is not verified. Please contact your admin to verify
            your account. You will be redirected to the login page once your
            account is verified.`}
          </Text>
        </Box>
        <div className="flex justify-center gap-2">
          <Button
            rounded={"none"}
            colorScheme="blue"
            mt="6"
            onClick={() => setIsMailSent(true)}
            isDisabled={isMailSent}
          >
            {isMailSent ? "Sent" : "Send Mail"}
          </Button>
          <Button
            rounded={"none"}
            colorScheme="blue"
            mt="6"
            onClick={handleLogout}
          >
            Log Out
          </Button>
        </div>
      </VStack>
    </Flex>
  );
};

export default AccountNotVerified;
