import { Button, Card, CardBody, Flex, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import { Pages } from "../../constant";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import {
  getAllUsers,
  markUserAsNotVerified,
  markUserAsVerified,
} from "../../services/userApi";
import Gap from "../../UI/Gap";
import Loader from "../../UI/Loader";
import { updateEmployee } from "../../services/employeeApi";

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        setLoading(true);
        const res = await getAllUsers();
        setUsers(res.data);
        setLoading(false);
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setLoading(false);
      }
    };
    fetchAllUsers();
  }, []);

  const markAsVerified = async (userId) => {
    try {
      setLoading(true);
      const res = await markUserAsVerified(userId);
      toast.success(getSuccessMessage(res));
      setUsers(
        users.map((user) =>
          user?._id === userId ? { ...user, isAccountVerified: true } : user
        )
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const markAsNotVerified = async (userId) => {
    try {
      setLoading(true);
      const res = await markUserAsNotVerified(userId);
      toast.success(getSuccessMessage(res));
      setUsers(
        users.map((user) =>
          user?._id === userId ? { ...user, isAccountVerified: false } : user
        )
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (pageName, userId, user) => {
    if (!user?.pagesPermissions.includes(pageName)) {
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user?._id === userId
            ? {
                ...user,
                pagesPermissions: [...user.pagesPermissions, pageName],
              }
            : user
        )
      );
    } else {
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user?._id === userId
            ? {
                ...user,
                pagesPermissions: user?.pagesPermissions.filter(
                  (page) => page !== pageName
                ),
              }
            : user
        )
      );
    }
  };

  //   res = await updateEmployee(employee._id, formData);

  const updatePagePermissions = async (userId, pagesPermissions) => {
    try {
      setLoading(true);
      const res = await updateEmployee(userId, { pagesPermissions });
      setUsers(
        users.map((user) =>
          user?._id === userId ? { ...user, pagesPermissions } : user
        )
      );
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <Gap>All Users</Gap>
      <div className="flex flex-wrap gap-3 ">
        {users.map((user) => {
          const userStatusColor = user?.isAccountVerified ? "green" : "red";
          const baseCardStyles = `bg-white  shadow-md p-4`;
          const cardStyles = `${baseCardStyles} border-b border-${userStatusColor} `;
          return (
            <Card
              key={user?._id}
              className={cardStyles}
              style={{ borderRadius: "none" }}
            >
              <CardBody>
                <div className="flex gap-3 flex-wrap">
                  <Text fontWeight="bold" className="flex-1 border p-1">
                    {user?.firstName} {user?.lastName}
                  </Text>
                  <Text className="flex-1 border p-1">{user?.email}</Text>
                  <Text className="capitalize flex-1 border p-1">
                    {user?.role}
                  </Text>
                  <Text className="flex-1 border p-1">
                    {user?.isAccountVerified ? "Verified" : "Not Verified"}
                  </Text>
                  <Button
                    rounded={"none"}
                    size="sm"
                    variant="solid"
                    colorScheme={user?.isAccountVerified ? "red" : "blue"}
                    onClick={() => {
                      user?.isAccountVerified
                        ? markAsNotVerified(user?._id)
                        : markAsVerified(user?._id);
                    }}
                    className="flex-1 border p-1  "
                  >
                    {user?.isAccountVerified
                      ? "Mark as Not Verified"
                      : "Mark as Verified"}
                  </Button>
                </div>

                <Flex justifyContent="between" mt={4}>
                  <Stack direction="row" spacing={2} className="flex flex-wrap">
                    {Pages.map((page) => {
                      // if (page.hide) return null;
                      return (
                        <Button
                          rounded={"none"}
                          key={page.name}
                          type="button"
                          colorScheme={
                            user?.pagesPermissions?.includes(page.name)
                              ? "green"
                              : "gray"
                          }
                          onClick={() =>
                            handleChangePage(page.name, user?._id, user)
                          }
                        >
                          {page.name}
                        </Button>
                      );
                    })}
                  </Stack>
                </Flex>
                <Button
                  rounded={"none"}
                
                  size="sm"
                  variant="solid"
                  colorScheme="blue"
                  onClick={() => {
                    updatePagePermissions(user?._id, user?.pagesPermissions);
                  }}
                  className="flex-1 border p-1 mt-2"
                >
                  Update Page Permissions
                </Button>
              </CardBody>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default AllUsers;
