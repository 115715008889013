import React, { useState } from "react";
import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { addProduct, deleteProduct } from "../../services/productApi";
import Loader from "../../UI/Loader";

import InputField from "../../UI/InputField";
import Gap from "../../UI/Gap";
import { toast } from "sonner";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import { useDispatch, useSelector } from "react-redux";
import { updateProduct, removeProduct } from "../../redux/productSlice";
import { MdCancel } from "react-icons/md";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { itemsPerPage } from "../../constant";

const Products = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [productDetails, setProductDetails] = useState({
    name: "",
    warranty: "",
    sgst: "",
    cgst: "",
    price: "",
    hsnCode: "",
  });

  const products = useSelector((state) => state.product.products);
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({
      ...productDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await addProduct({ ...productDetails, isEdit });
      dispatch(updateProduct(res.data));
      setProductDetails({
        name: "",
        warranty: "",
        sgst: "",
        cgst: "",
        price: "",
        hsnCode: "",
      });
      setIsEdit(false);
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (product) => {
    setProductDetails(product);
    setIsEdit(true);
  };

  const handleDeleteProduct = async (product) => {
    try {
      setLoading(true);
      const confirmed = window.confirm(
        "Are you sure you want to delete this product?"
      );
      if (confirmed) {
        await deleteProduct(product._id);
        dispatch(removeProduct(product._id));
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };
  const offset = currentPage * itemsPerPage;
  const endOffset = offset + itemsPerPage;

  const currentProducts = filteredProducts.slice(
    offset,
    Math.min(endOffset, filteredProducts.length)
  );

  if (loading) return <Loader />;

  return (
    <div>
      <Gap>Enter Product Details</Gap>
      <form
        className="flex lg:flex-wrap lg:flex-row gap-4 flex-col"
        onSubmit={handleSubmit}
      >
        <InputField
          labelName="Name"
          type="text"
          required
          uni="name"
          value={productDetails.name}
          onChange={handleChange}
        />
        <InputField
          labelName="Warranty"
          type="number"
          required
          uni="warranty"
          value={productDetails.warranty}
          onChange={handleChange}
        />
        <InputField
          labelName="SGST"
          type="number"
          required
          uni="sgst"
          value={productDetails.sgst}
          onChange={handleChange}
        />
        <InputField
          labelName="CGST"
          type="number"
          required
          uni="cgst"
          value={productDetails.cgst}
          onChange={handleChange}
        />
        <InputField
          labelName="Price (Without GST)"
          type="number"
          required
          uni="price"
          value={productDetails.price}
          onChange={handleChange}
        />
        <InputField
          labelName="HSN Code"
          type="text"
          required
          uni="hsnCode"
          value={productDetails.hsnCode}
          onChange={handleChange}
        />
        <Button
rounded={"none"}
          type="submit"
          variant="solid"
          colorScheme="blue"
          size="md"
          className="mt-6"
        >
          Add
        </Button>
      </form>
      <Gap>Products</Gap>
      <InputField
        type="text"
        placeholder="Search products..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16  text-sm focus:outline-none"
      />
      <div className="overflow-y-auto">
        {isMobileView ? (
          <div className="flex flex-col gap-4 p-4">
            {currentProducts.map((product, index) => (
              <div key={index} className="bg-gray-100 p-2  shadow-md">
                <div className="font-bold">{product.name}</div>
                <div className="flex flex-col gap-1">
                  <div>
                    <span className="font-semibold">Warranty (in months):</span>{" "}
                    {product.warranty}
                  </div>
                  <div>
                    <span className="font-semibold">SGST:</span> {product.sgst}
                  </div>
                  <div>
                    <span className="font-semibold">CGST:</span> {product.cgst}
                  </div>
                  <div>
                    <span className="font-semibold">Price:</span>{" "}
                    {product.price}
                  </div>
                  <div>
                    <span className="font-semibold">HSN Code:</span>{" "}
                    {product.hsnCode}
                  </div>
                  <div className="flex gap-2 mt-2">
                    {isEdit ? (
                      <Button
rounded={"none"} onClick={() => setIsEdit(false)}>
                        <MdCancel size={20} color="blue" />
                      </Button>
                    ) : (
                      <>
                        <Button
rounded={"none"} onClick={() => handleEdit(product)} size="xs">
                          <FaEdit size={20} color="blue" />
                        </Button>
                        <Button
rounded={"none"}
                          onClick={() => handleDeleteProduct(product)}
                          size="xs"
                        >
                          <FaTrashAlt size={20} color="red" />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="overflow-y-auto">
            <Table variant="striped" className="mt-4">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Warranty (in months)</Th>
                  <Th>SGST</Th>
                  <Th>CGST</Th>
                  <Th>Price (With GST)</Th>
                  <Th>HSN Code</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentProducts.map((product, index) => (
                  <Tr key={index}>
                    <Td>{product.name}</Td>
                    <Td>{product.warranty}</Td>
                    <Td>{product.sgst}</Td>
                    <Td>{product.cgst}</Td>
                    <Td>{product.price}</Td>
                    <Td>{product.hsnCode}</Td>
                    <Td className="flex">
                      {isEdit ? (
                        <Button
rounded={"none"} onClick={() => setIsEdit(false)}>
                          <MdCancel size={24} color="blue" />
                        </Button>
                      ) : (
                        <>
                          <Button
rounded={"none"} onClick={() => handleEdit(product)}>
                            <FaEdit size={24} color="blue" />
                          </Button>
                          <Button
rounded={"none"} onClick={() => handleDeleteProduct(product)}>
                            <FaTrashAlt size={24} color="red" />
                          </Button>
                        </>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        )}
      </div>
      <div className="flex lg:p-5 lg:w-full p-1 w-4/5">
        <ReactPaginate
          nextLabel={"Next"}
          previousLabel={"Back"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={isMobileView ? 1 : 2}
          pageRangeDisplayed={isMobileView ? 1 : 5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          className="flex gap-3 pagination page-link page-item"
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};
export default Products;
