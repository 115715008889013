import React, { useState } from "react";
import Loader from "../../UI/Loader";

import InputField from "../../UI/InputField";
import { toast } from "sonner";
import { addCompanyDetails } from "../../services/companyDetailsApi";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import Gap from "../../UI/Gap";
import { Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails as setCompanyDetailsRedux } from "../../redux/companyDetailsSlice";
const CompanyDetails = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const companyDetails = useSelector(
    (state) => state.companyDetails.companyDetails
  );

  const [companyDetailsDetails, setCompanyDetails] = useState(companyDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails({
      ...companyDetailsDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await addCompanyDetails(companyDetailsDetails);

      setCompanyDetails({
        ...res.data,
      });
      dispatch(setCompanyDetailsRedux(res.data));
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div >
      <form className="flex flex-wrap " onSubmit={handleSubmit}>
        <div className="flex flex-col w-full">
          <Gap>Jurisdiction</Gap>
          <div className="flex lg:flex-wrap lg:flex-row gap-4 flex-col">
            <InputField
              labelName="Jurisdiction"
              type="text"
              name="jurisdiction"
              uni="jurisdiction"
              value={companyDetailsDetails?.jurisdiction}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <Gap>Business Details</Gap>
          <div className="flex lg:flex-wrap lg:flex-row gap-4 flex-col">
            <InputField
              labelName="Email"
              type="email"
              name="email"
              uni="email"
              value={companyDetailsDetails?.email}
              onChange={handleChange}
            />
            <InputField
              labelName="Business Name"
              type="text"
              name="businessName"
              uni="businessName"
              value={companyDetailsDetails?.businessName}
              onChange={handleChange}
            />
            <InputField
              labelName="Phone Number"
              type="tel"
              name="phoneNumber"
              uni="phoneNumber"
              value={companyDetailsDetails?.phoneNumber}
              onChange={handleChange}
            />
            <InputField
              labelName="Address"
              type="text"
              name="address"
              uni="address"
              value={companyDetailsDetails?.address}
              onChange={handleChange}
            />
            <InputField
              labelName="GST"
              type="text"
              name="gst"
              uni="gst"
              value={companyDetailsDetails?.gst}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <Gap>Bank Details</Gap>
          <div className="flex lg:flex-wrap lg:flex-row gap-4 flex-col">
            <InputField
              labelName="Bank Name"
              type="text"
              name="bankName"
              uni="bankName"
              value={companyDetailsDetails?.bankName}
              onChange={handleChange}
            />
            <InputField
              labelName="IFSC Code"
              type="text"
              name="ifscCode"
              uni="ifscCode"
              value={companyDetailsDetails?.ifscCode}
              onChange={handleChange}
            />
            <InputField
              labelName="Bank Account Number"
              type="text"
              name="bankAccountNumber"
              uni="bankAccountNumber"
              value={companyDetailsDetails?.bankAccountNumber}
              onChange={handleChange}
            />
            <InputField
              labelName="Bank Branch"
              type="text"
              name="bankBranch"
              uni="bankBranch"
              value={companyDetailsDetails?.bankBranch}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <Gap>Other Details</Gap>
          <div className="flex lg:flex-wrap lg:flex-row gap-4 flex-col">
            <InputField
              labelName="Vehicle"
              type="text"
              name="vehicle"
              uni="vehicle"
              value={companyDetailsDetails?.vehicle}
              onChange={handleChange}
            />
            <InputField
              labelName="Transport"
              type="text"
              name="transport"
              uni="transport"
              value={companyDetailsDetails?.transport}
              onChange={handleChange}
            />
            <InputField
              labelName="Station"
              type="text"
              name="station"
              uni="station"
              value={companyDetailsDetails?.station}
              onChange={handleChange}
            />
          </div>
          <Button
rounded={"none"}
            type="submit"
            variant="solid"
            colorScheme="blue"
            size="md"
            className="mt-6"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};
export default CompanyDetails;
