import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import Loader from "../../UI/Loader";
import SelectField from "../../UI/SelectField";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import {
  addCashFlow,
  deleteCashFlow,
  getAllCashFlow,
} from "../../services/cashFlowApi";
import moment from "moment";

const CashFlow = () => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(false);
  const [dateOfEntry, setDateOfEntry] = useState(new Date());
  const [party, setParty] = useState({});
  const [status, setStatus] = useState("");
  const [note, setNote] = useState("");
  const [cashFlows, setCashFlows] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);
  const fetchCashFlows = async () => {
    try {
      setLoading(true);
      const res = await getAllCashFlow();
      setCashFlows(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCashFlows();
  }, []);
  const dealers = useSelector((state) => state.dealer.dealers);
  const customers = useSelector((state) => state.customer.customers);
  const formattedDate = dateOfEntry.toISOString().split("T")[0];

  const handleCashFlowCreate = async () => {
    try {
      if (!amount || amount <= 0 || !dateOfEntry || !party || !status) {
        return toast.error("Please fill all the fiellds");
      }
      setLoading(true);

      const res = await addCashFlow({
        amount,
        dateOfEntry,
        party,
        partyName: party.name,
        status,
        note,
        cashFlowId: selectedId,
      });
      setIsEdit(false);
      setAmount("");
      setParty("");
      setNote("");
      setStatus("");

      await fetchCashFlows();
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = (history, e) => {
    setDateOfEntry(new Date(history.dateOfEntry));
    setAmount(e.amount);
    setStatus(e.status);
    setNote(e.note);
    setSelectedId(e._id);
    setParty([...dealers, ...customers].find((item) => item._id === e.party));
    setIsEdit(true);
  };

  const handleDelete = async (e) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this cash flow?"
      );
      if (!shouldDelete) {
        return;
      }

      setLoading(true);
      const res = await deleteCashFlow(e._id);
      await fetchCashFlows();
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <div>
        <Gap>Cash Flow</Gap>
        <div className="flex lg:flex-wrap lg:flex-row gap-4 flex-col">
          <InputField
            type={"date"}
            labelName={"Date Of Entry"}
            value={formattedDate}
            onChange={(e) => setDateOfEntry(new Date(e.target.value))}
          />
          <InputField
            type={"number"}
            labelName={"Amount"}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <div className="lg:w-1/6">
            <SelectField
              labelName={"Party"}
              options={[...dealers, ...customers]}
              value={{
                label: [...dealers, ...customers].find(
                  (pty) => pty._id === party._id
                )?.name,
                value: party?._id,
              }}
              onChange={({ value }) => setParty(value)}
            />
          </div>
          <div className="lg:w-1/6">
            <SelectField
              labelName={"Status"}
              options={[
                {
                  label: "In",
                  name: "In",
                },
                {
                  label: "Out",
                  name: "Out",
                },
              ]}
              value={{
                label: status,
                value: status,
              }}
              onChange={({ value }) => setStatus(value.name)}
            />
          </div>
          <InputField
            type={"text"}
            labelName={"Note"}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Button
rounded={"none"}
            colorScheme="blue"
            className="mt-6"
            onClick={handleCashFlowCreate}
          >
            Add
          </Button>
        </div>
      </div>
      <div>
        <Gap>History</Gap>
        <div className="overflow-x-auto">
          {isMobileView ? (
            <div className="flex flex-col gap-4 p-4">
              {cashFlows.map((history, index) => (
                <div
                  key={index}
                  className="bg-gray-100 p-2  shadow-md"
                >
                  <div className="text-lg font-bold">
                    {moment(history.dateOfEntry).format("DD MMM YYYY")}
                  </div>
                  <div className="flex flex-col gap-1">
                    {history.entries.map((entry, entryIndex) => (
                      <div
                        key={entryIndex}
                        className="bg-white  p-2 flex items-center justify-between"
                      >
                        <div className="flex flex-col">
                          <div className="font-semibold">
                            Party: {entry.partyName}
                          </div>
                          <div>Amount: {entry.amount}</div>
                          <div>Status: {entry.status}</div>
                          <div>Note: {entry.note}</div>
                        </div>
                        <div className="flex gap-2">
                          {isEdit ? (
                            <Button
rounded={"none"}
                              onClick={() => {
                                setIsEdit(false);
                                setAmount("");
                                setParty("");
                                setNote("");
                                setStatus("");
                              }}
                            >
                              <MdCancel size={20} color="blue" />
                            </Button>
                          ) : (
                            <>
                              <Button
rounded={"none"}
                                onClick={() => handleEdit(history, entry)}
                                size="xs"
                              >
                                <FaEdit size={20} color="blue" />
                              </Button>
                              <Button
rounded={"none"}
                                onClick={() => handleDelete(entry)}
                                size="xs"
                              >
                                <FaTrashAlt size={20} color="red" />
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Table variant="striped" className="mt-4">
              <Thead className="bg-gray-50">
                <Tr>
                  <Th>Date</Th>
                  <Th>Party</Th>
                  <Th>Amount</Th>
                  <Th>Status</Th>
                  <Th>Note</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {cashFlows.map((history, index) => (
                  <Tr key={index}>
                    <Td>{moment(history.dateOfEntry).format("DD MMM YYYY")}</Td>
                    <Td>
                      <ul className="flex flex-col gap-6">
                        {history.entries.map((entry, entryIndex) => (
                          <li key={entryIndex}>{entry.partyName}</li>
                        ))}
                      </ul>
                    </Td>
                    <Td>
                      <ul className="flex flex-col gap-6">
                        {history.entries.map((entry, entryIndex) => (
                          <li key={entryIndex}>{entry.amount}</li>
                        ))}
                      </ul>
                    </Td>
                    <Td>
                      <ul className="flex flex-col gap-6">
                        {history.entries.map((entry, entryIndex) => (
                          <li key={entryIndex}>{entry.status}</li>
                        ))}
                      </ul>
                    </Td>
                    <Td>
                      <ul className="flex flex-col">
                        {history.entries.map((entry, entryIndex) => (
                          <li key={entryIndex}>{entry.note}</li>
                        ))}
                      </ul>
                    </Td>
                    <Td className="flex">
                      <ul className="flex flex-col gap-6">
                        {history.entries.map((entry) => (
                          <li key={entry._id}>
                            {isEdit ? (
                              <Button
rounded={"none"}
                                onClick={() => {
                                  setIsEdit(false);
                                  setAmount("");
                                  setParty("");
                                  setNote("");
                                  setStatus("");
                                }}
                              >
                                <MdCancel size={24} color="blue" />
                              </Button>
                            ) : (
                              <>
                                <Button
rounded={"none"}
                                  onClick={() => handleEdit(history, entry)}
                                  size="xs"
                                >
                                  <FaEdit size={20} color="blue" />
                                </Button>
                                <Button
rounded={"none"}
                                  onClick={() => handleDelete(entry)}
                                  size="xs"
                                >
                                  <FaTrashAlt size={20} color="red" />
                                </Button>
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};
export default CashFlow;
