import React, { useState } from "react";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import { Button } from "@chakra-ui/react";
import { FaTrashAlt } from "react-icons/fa";
import SelectField from "../../UI/SelectField";
import { toast } from "sonner";
import Loader from "../../UI/Loader";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import { addQrSettings } from "../../services/qrSettingsApi";
import { useDispatch, useSelector } from "react-redux";
import { setItems as setItemsRedux } from "../../redux/qrSettingsSlice";
const QRSettings = () => {
  const qrSettings = useSelector((state) => state.qrSettings.items);
  const [items, setItems] = useState(
    qrSettings?.length === 0 ? [{ labelName: "", labelType: "" }] : qrSettings
  );

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const addField = () => {
    setItems([...items, { labelName: "", labelType: "" }]);
  };

  const deleteField = (index) => {
    setItems((prevItems) => prevItems.filter((item, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    setItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const arr = items.filter((item) => !item.labelName || !item.labelType);
      if (arr.length > 0) {
        return toast.error("Please fill all fields");
      }
      const res = await addQrSettings({
        fields: items,
      });
      dispatch(setItemsRedux(res?.data?.fields));
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const renderOptionsInput = (index, labelType) => {
    if (labelType === "Select") {
      return (
        <InputField
          type="textarea"
          name="options"
          labelName={`Options Seperate by ","`}
          placeholder={"Red,Blue,Light Blue"}
          value={items[index].options || ""}
          onChange={(e) => handleChange(index, "options", e.target.value)}
        />
      );
    }
    return null;
  };
  if (loading) return <Loader />;
  return (
    <div>
      <Gap>QR Settings</Gap>

      <div >
        {items?.map((item, index) => (
          <div
            className="flex  gap-4 lg:flex-row flex-col "
            key={item._id}
          >
            <InputField
              type="text"
              name="labelName"
              labelName="Label Name"
              uni="labelName"
              value={item.labelName}
              onChange={(e) => handleChange(index, "labelName", e.target.value)}
            />

            <SelectField
              name="labelType"
              uni="labelType"
              options={[
                {
                  label: "Text",
                  name: "Text",
                },
                {
                  label: "Number",
                  name: "Number",
                },
                {
                  label: "Time",
                  name: "Time",
                },
                {
                  label: "Date",
                  name: "Date",
                },
                {
                  label: "Select",
                  name: "Select",
                },
              ]}
              labelName="Label Type"
              value={{
                label: item.labelType,
                name: item.labelType,
              }}
              onChange={({ value }) =>
                handleChange(index, "labelType", value.name)
              }
            />
            {renderOptionsInput(index, item.labelType)}
            <Button
rounded={"none"} onClick={() => deleteField(index)} className="mt-6">
              <FaTrashAlt size={20} color="red" />
            </Button>
          </div>
        ))}
      </div>
      <div className="flex gap-2">
        <Button
rounded={"none"} onClick={addField} colorScheme="blue" className="mt-3">
          Add Field
        </Button>
        <Button
rounded={"none"} onClick={handleSubmit} colorScheme="blue" className="mt-3">
          Submit
        </Button>
      </div>
    </div>
  );
};

export default QRSettings;
