import http from "./http";

const productApi = "/api/product";

export const addProduct = (data) => {
  return http.post(`${productApi}/add`, data);
};

export const getAllProducts = (data) => {
  return http.get(`${productApi}/get`, data);
};

export const deleteProduct = (productId) => {
  if (!productId) {
    return alert("productId is missing");
  }
  return http.delete(`${productApi}/delete/${productId}`);
};
