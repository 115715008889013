import { Button, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { toast } from "sonner";
import { getErrorMessage } from "../../functions";
import { getYearlyAttendance } from "../../services/attendanceApi";
import Loader from "../../UI/Loader";
const AttendanceDetails = ({
  employee,
  handleAddAttendance,
  setShowFullAttendanceDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [highlightedDate, setHighlightedDate] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [sortedView, setSortedView] = useState(true);

  const handlePreviousYear = () => setYear(year - 1);
  const handleNextYear = () => setYear(year + 1);

  const handleDayClick = async (date, monthIndex) => {
    const selectedMonth = date.getMonth();
    if (selectedMonth === monthIndex) {
      setHighlightedDate(date);
      await handleAddAttendance(employee.employeeId, date, true);
      fetchYearlyAttendance(year);
    }
  };

  const fetchYearlyAttendance = async (year) => {
    try {
      setLoading(true);
      const query = `?year=${year}&employeeId=${employee.employeeId}`;
      const res = await getYearlyAttendance(query);
      setAttendanceRecords(res.data.attendanceRecords);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchYearlyAttendance(year);
  }, [year]);

  const renderMonthCalendar = (monthIndex) => {
    const month = new Date(year, monthIndex, 1);

    // Filter attendance records for the current month
    const monthAttendance = attendanceRecords.filter(
      (rec) => new Date(rec.date).getMonth() === monthIndex
    );

    // Calculate the number of days present in the current month
    const daysPresent = monthAttendance.filter((rec) => rec.isPresent).length;
    const daysAbsent = monthAttendance.filter((rec) => !rec.isPresent).length;
    const totalDaysInMonth = new Date(year, monthIndex + 1, 0).getDate();

    return (
      <div key={monthIndex} className="calendar-container m-4">
        <Calendar
          value={month}
          tileClassName={({ date }) => {
            const record = attendanceRecords.find(
              (rec) => new Date(rec.date).toDateString() === date.toDateString()
            );
            if (record && record.isPresent && date.getMonth() === monthIndex) {
              return "bg-green-300 text-black font-bold";
            }
            if (record && !record.isPresent && date.getMonth() === monthIndex) {
              return "bg-red-300 text-black font-bold";
            } else if (
              highlightedDate &&
              highlightedDate.getFullYear() === date.getFullYear() &&
              highlightedDate.getMonth() === date.getMonth() &&
              highlightedDate.getDate() === date.getDate()
            ) {
              return "bg-yellow-300 text-black font-bold";
            } else if (date.getMonth() !== monthIndex) {
              return "text-gray-300";
            } else {
              return "text-black hover:bg-yellow-400 hover:text-black";
            }
          }}
          onClickDay={(date) => handleDayClick(date, monthIndex)}
          view="month"
          navigationLabel={({ date }) =>
            date.toLocaleDateString("default", {
              month: "long",
            })
          }
          activeStartDate={new Date(year, monthIndex, 1)}
          nextLabel={null}
          prevLabel={null}
          next2Label={null}
          prev2Label={null}
          className="p-4 border rounded-lg shadow-lg"
        />
        <div className="attendance-summary text-center mt-2">
          <Table size="sm" variant={"striped"}>
            <Tbody>
              <Tr>
                <Td>Total</Td>
                <Td>{totalDaysInMonth}</Td>
              </Tr>
              <Tr>
                <Td>Present</Td>
                <Td>{daysPresent}</Td>
              </Tr>
              <Tr>
                <Td>Absent</Td>
                <Td>{daysAbsent}</Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
      </div>
    );
  };

  const getSortedMonths = () => {
    const currentMonth = new Date().getMonth();
    const months = Array.from({ length: 12 }, (_, i) => i);
    const sortedMonths = months
      .slice(currentMonth)
      .concat(months.slice(0, currentMonth));
    return sortedMonths;
  };

  if (loading) return <Loader />;

  return (
    <div className="yearly-calendars-wrapper p-4">
      <div className="flex gap-2 shadow-lg p-2 lg:items-center lg:flex-row flex-col">
        <Button rounded={"none"} disabled className="flex justify-center">
          Attendance of {employee.name}
        </Button>
        <Button
          rounded={"none"}
          colorScheme="blue"
          className="flex justify-center"
          onClick={() => setShowFullAttendanceDetails(false)}
        >
          Back
        </Button>
        <Button
          rounded={"none"}
          colorScheme="blue"
          onClick={() => setSortedView(!sortedView)}
        >
          {sortedView ? "Show Normal View" : "Show Sorted View"}
        </Button>
      </div>
      <div className="year-navigation flex justify-center items-center my-4">
        <button
          onClick={handlePreviousYear}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 mr-2 flex items-center"
        >
          <FaChevronLeft />
          Previous
        </button>
        <span className="text-2xl font-semibold">{year}</span>
        <button
          onClick={handleNextYear}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 ml-2 flex items-center"
        >
          Next <FaChevronRight />
        </button>
      </div>
      <div className="yearly-calendars flex flex-wrap justify-center">
        {(sortedView
          ? getSortedMonths()
          : Array.from({ length: 12 }, (_, i) => i)
        ).map((monthIndex) => renderMonthCalendar(monthIndex))}
      </div>
    </div>
  );
};

export default AttendanceDetails;
