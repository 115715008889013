import React from "react";
import InputField from "../../../UI/InputField";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
export default function ItemList({ items, handleDeleteItem, setItems }) {
  const handleChangeItemValue = (e, itemToUpdate) => {
    const { name, value } = e.target;
    const updatedItems = items?.map((item) =>
      item._id === itemToUpdate._id ? { ...item, [name]: value } : item
    );

    setItems(updatedItems);
  };

  return (
    <div className="flex flex-wrap gap-4 flex-col mt-6 overflow-y-auto">
      <Table variant={"striped"} size="sm" minWidth="600px">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Warranty (in months)</Th>
            <Th>HSN Code</Th>
            <Th>Price</Th>
            <Th>Quantity</Th>
            <Th>SGST (%)</Th>
            <Th>SGST Amount</Th>
            <Th>CGST (%)</Th>
            <Th>CGST Amount</Th>
            <Th>Total Amount</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items?.map((item) => {
            // if (item.isTaxIncluded) {
            //   sgstAmount = (
            //     item.quantity *
            //     ((item.price * +item.sgst) / 100)
            //   ).toFixed(2);

            //   cgstAmount = (
            //     item.quantity *
            //     ((item.price * +item.cgst) / 100)
            //   ).toFixed(2);

            //   grandTotal = (item.quantity * item.price).toFixed(2);
            // } else {
            const sgstAmount = (
              item.quantity *
              item.price *
              (item.sgst / 100)
            ).toFixed(2);
            const cgstAmount = (
              item.quantity *
              item.price *
              (item.cgst / 100)
            ).toFixed(2);
            const subtotal = item.quantity * item.price;
            const grandTotal = (+subtotal + +sgstAmount + +cgstAmount).toFixed(
              2
            );
            // }

            return (
              <Tr key={item._id}>
                <Td maxW="120px">
                  <InputField
                    type="text"
                    value={item.name}
                    name="name"
                    uni="name"
                    onChange={(e) => handleChangeItemValue(e, item)}
                  />
                </Td>
                <Td maxW="80px">
                  <InputField
                    type="number"
                    value={item.warranty}
                    name="warranty"
                    uni="warranty"
                    onChange={(e) => handleChangeItemValue(e, item)}
                  />
                </Td>
                <Td maxW="80px">
                  <InputField
                    type="text"
                    name="hsnCode"
                    uni="hsnCode"
                    value={item.hsnCode}
                    onChange={(e) => handleChangeItemValue(e, item)}
                  />
                </Td>
                <Td maxW="80px">
                  <InputField
                    type="number"
                    value={item.price}
                    name="price"
                    uni="price"
                    onChange={(e) => handleChangeItemValue(e, item)}
                  />
                </Td>
                <Td maxW="80px">
                  <InputField
                    type="number"
                    value={item.quantity}
                    name="quantity"
                    uni="quantity"
                    onChange={(e) => handleChangeItemValue(e, item)}
                  />
                </Td>
                <Td maxW="80px">
                  <InputField
                    type="number"
                    value={item.sgst}
                    name="sgst"
                    uni="sgst"
                    onChange={(e) => handleChangeItemValue(e, item)}
                  />
                </Td>
                <Td maxW="80px">
                  <InputField type="text" value={sgstAmount} disabled />
                </Td>
                <Td maxW="80px">
                  <InputField
                    type="number"
                    value={item.cgst}
                    name="cgst"
                    uni="cgst"
                    onChange={(e) => handleChangeItemValue(e, item)}
                  />
                </Td>
                <Td maxW="80px">
                  <InputField type="text" value={cgstAmount} disabled />
                </Td>
                <Td maxW="80px">
                  <InputField type="number" value={grandTotal} disabled />
                </Td>
                <Td maxW="80px">
                  <button onClick={() => handleDeleteItem(item._id)}>
                    <FaTrash size={20} className="text-red-500" />
                  </button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
}
