import moment from "moment";
import { BsQrCode, BsQrCodeScan } from "react-icons/bs";
import { CiBarcode, CiBoxList } from "react-icons/ci";
import { FaBuilding, FaCarBattery, FaProductHunt } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import {
  GiArchiveRegister,
  GiCash,
  GiMoneyStack,
  GiNotebook,
} from "react-icons/gi";
import {
  MdAccountCircle,
  MdOutlineInventory,
  MdPeopleAlt,
  MdPeopleOutline,
} from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import Dashboard from "./Pages/Dashboard";
import ForgotPassword from "./Pages/ForgotPassword";
import Invoice from "./Pages/Invoice";
import QrDetail from "./Pages/QrDetails";
import AccountNotVerified from "./components/AccountNotVerified/AccountNotVerified";
import AllUsers from "./components/AllUsers/AllUsers";
import Attendance from "./components/Attendance/Attendance";
import BarcodeGenerator from "./components/Barcode/Barcode";
import BatteryDetails from "./components/BatteryDetails/BatteryDetails";
import CncLedger from "./components/CNC Ledger/CncLedger";
import CashFlow from "./components/CashFlow/CashFlow";
import CompanyDetails from "./components/CompanyDetails/CompanyDetails";
import Customers from "./components/Customers/Customers";
import Dealers from "./components/Dealers/Dealers";
import Employee from "./components/Employee/Employee";
import GenerateQR from "./components/GenerateQR/GenerateQR";
import Inventory from "./components/Inventory/Inventory";
import InvoiceComponent from "./components/Invoice/Invoice";
import Khatabook from "./components/Khatabook/Khatabook";
import Login from "./components/Login/Login";
import Products from "./components/Products/Products";
import {
  default as Profile,
  default as ProfilePage,
} from "./components/Profile/Profile";
import QRSettings from "./components/QrSettings/QRSettings";
import SignUp from "./components/SignUp/SignUp";

export const ROLES = {
  ADMIN: "admin",
  EMPLOYEE: "employee",
  SUPER_ADMIN: "super_admin",
};

export default [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    isAuth: true,
  },
  {
    path: "/invoice",
    component: Invoice,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/profile",
    component: ProfilePage,
    isAuth: true,
  },
  {
    path: "/qr",
    component: QrDetail,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/account-not-verified",
    component: AccountNotVerified,
  },
  {
    path: "*",
    component: Dashboard,
  },
];

export const state_arr = new Array(
  "Andaman & Nicobar",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra & Nagar Haveli",
  "Daman & Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Orissa",
  "Pondicherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal"
);

export const pricingCardsData = [
  {
    title: "Beginner",
    price: "10,999",
    points: [
      "For Absolute Beginners, Learn From Basics",
      "4 Weeks Of Live Interactive Classes.",
      "Small Batch Size Of 5 Students",
      "Band 7 Guarantee Program",
      "Certified & Experienced Trainers",
      "IELTS Study Plan",
      "Score High Or Full Fee Refund",
      "Grammar Development Classes",
      "Full Length Band Predictor Tests",
      "IELTS Study Materials",
      "Doubt Solving Sessions",
      "Latest Curriculum Based New Exam Pattern",
      "Speaking Room For Speaking Evaluation",
      "IELTS Tips & Strategies",
      "IELTS Vocabulary Building",
    ],
    buttonText: "Select Plan",
  },
  {
    title: "Intermediate",
    price: "6,599",

    points: [
      "For Learners With Some Knowledge of IELTS",
      "2 Weeks Of Live Interactive Classes.",
      "Small Batch Size Of 5 Students",
      "Certified & Experienced Trainers",
      "Grammar Development Classes",
      "Full Length Band Predictor Tests",
      "IELTS Study Materials",
      "Doubt Solving Sessions",
      "Latest Curriculum Based New Exam Pattern",
      "Speaking Room For Speaking Evaluation",
      "IELTS Tips & Strategies",
      "IELTS Vocabulary Building",
    ],
    buttonText: "Select Plan",
  },
  {
    title: "Pro",
    price: "5,599",

    points: [
      "Ready To Appear For The Test Need To Know Tips And Tricks",
      "1 Weeks Of Live Interactive Classes.",
      "Small Batch Size Of 5 Students",
      "Certified & Experienced Trainers",
      "Grammar Development Classes",
      "Full Length Band Predictor Tests",
      "IELTS Study Materials",
      "Doubt Solving Sessions",
      "Latest Curriculum Based New Exam Pattern",
      "Speaking Room For Speaking Evaluation",
      "IELTS Tips & Strategies",
      "IELTS Vocabulary Building",
    ],

    buttonText: "Select Plan",
  },
];

export const itemsPerPage = 25;

export const COPY_TYPE = [
  { name: "Original Copy" },
  { name: "Duplicate Copy" },
  { name: "Tertiary Copy" },
];

export const barcodeTypes = [
  { label: "CODE128 auto", name: "CODE128" },
  { label: "CODE128 A", name: "CODE128A" },
  { label: "CODE128 B", name: "CODE128B" },
  { label: "CODE128 C", name: "CODE128C" },
  { label: "EAN13", name: "EAN13" },
  { label: "EAN8", name: "EAN8" },
  { label: "UPC", name: "UPC" },
  { label: "CODE39", name: "CODE39" },
  { label: "ITF14", name: "ITF14" },
  { label: "ITF", name: "ITF" },
  { label: "MSI", name: "MSI" },
  { label: "MSI10", name: "MSI10" },
  { label: "MSI11", name: "MSI11" },
  { label: "MSI1010", name: "MSI1010" },
  { label: "MSI1110", name: "MSI1110" },
  { label: "Pharmacode", name: "Pharmacode" },
];

export const fontOptions = [
  {
    label: "monospace",
    name: "Monospace",
    style: { fontFamily: "monospace" },
  },
  {
    label: "sans-serif",
    name: "Sans-serif",
    style: { fontFamily: "sans-serif" },
  },
  { label: "serif", name: "Serif", style: { fontFamily: "serif" } },
  { label: "fantasy", name: "Fantasy", style: { fontFamily: "fantasy" } },
  { label: "cursive", name: "Cursive", style: { fontFamily: "cursive" } },
];

export const Pages = [
  {
    name: "MyProfile",
    component: <Profile />,
    icon: MdAccountCircle,
    label: "Profile",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "CompanyDetails",
    component: <CompanyDetails />,
    icon: FaBuilding,
    label: "Company Details",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "Customers",
    component: <Customers />,
    icon: MdPeopleOutline,
    label: "Customers",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "Dealers",
    component: <Dealers />,
    icon: MdPeopleAlt,
    label: "Dealers",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "Employee",
    component: <Employee />,
    icon: SlPeople,
    label: "Employee",
    permission: [ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.EMPLOYEE],
  },
  {
    name: "Products",
    component: <Products />,
    icon: FaProductHunt,
    label: "Products",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "Invoices",
    component: <InvoiceComponent />,
    icon: CiBoxList,
    label: "Invoice",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "BatteryDetails",
    component: <BatteryDetails />,
    icon: FaCarBattery,
    label: "Battery Details",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "Inventory",
    component: <Inventory />,
    icon: MdOutlineInventory,
    label: "Inventory",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "CashFlow",
    component: <CashFlow />,
    icon: GiMoneyStack,
    label: "Cash Flow",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "QRSettings",
    component: <QRSettings />,
    icon: BsQrCode,
    label: "QR Settings",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "GenerateQR",
    component: <GenerateQR />,
    icon: BsQrCodeScan,
    label: "Generate QR",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "Barcode",
    component: <BarcodeGenerator />,
    icon: CiBarcode,
    label: "Generate Barcode",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "AllUsers",
    component: <AllUsers />,
    icon: FiUsers,
    label: "All Users",
    permission: [ROLES.SUPER_ADMIN],
    hide: true,
  },
  {
    name: "Attendance",
    component: <Attendance />,
    icon: GiArchiveRegister,
    label: "Attendance",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "CNCLedger",
    component: <CncLedger />,
    icon: GiNotebook,
    label: "CNC Ledger",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
  {
    name: "Khatabook",
    component: <Khatabook />,
    icon: GiCash,
    label: "Khatabook",
    permission: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPER_ADMIN],
  },
].sort((a, b) => {
  const labelA = a.label.toLowerCase(); // Convert labels to lowercase for case-insensitive sorting
  const labelB = b.label.toLowerCase();
  return labelA.localeCompare(labelB);
});

export const months = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const batteryDetailsTemplate = (data) => {
  const rows = data
    .map(
      (item, index) => `
    <tr>
      <td>${index + 1}</td>
      <td>${moment(item.dateOfEntry).format("DD/MM/YYYY")}</td>
      <td>${item.batterySNo}</td>
      <td>${item.partyName}</td>
      <td>${item.batteryType}</td>
      <td>${item.status}</td>
      <td>${item.batteryReplacement}</td>
    </tr>
  `
    )
    .join("");

  return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Battery Details</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      margin: 20px;
      background-color: #f9f9f9;
      color: #333;
    }
    h1 {
      text-align: center;
      color: #4CAF50;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    }
    th, td {
      padding: 12px 15px;
      border: 1px solid #ddd;
      text-align: left;
    }
    th {
      background-color: #4CAF50;
      color: white;
      text-transform: uppercase;
      font-size: 14px;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    tr:hover {
      background-color: #e9e9e9;
    }
  </style>
</head>
<body>
  <h1>Battery Data</h1>
  <table>
    <thead>
      <tr>
        <th>S.No</th>
        <th>Date</th>
        <th>Battery SNo.</th>
        <th>Party Name</th>
        <th>Battery Type</th>
        <th>Status</th>
        <th>Replacement Battery SNo.</th>
      </tr>
    </thead>
    <tbody>
      ${rows}
    </tbody>
  </table>
</body>
</html>
  `;
};
