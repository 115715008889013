import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    updateCustomer: (state, action) => {
      const updatedCustomer = action.payload;
      const index = state.customers.findIndex(
        (customer) => customer._id === updatedCustomer._id
      );
      if (index !== -1) {
        state.customers[index] = updatedCustomer;
      } else {
        state.customers = [...state.customers, updatedCustomer];
      }
    },
    removeCustomer: (state, action) => {
      const customerId = action.payload;
      state.customers = state.customers.filter(
        (customer) => customer._id !== customerId
      );
    },
    emptyCustomers: (state) => {
      state.customers = [];
    },
  },
});

export const { setCustomers, updateCustomer, removeCustomer,emptyCustomers } =
  customerSlice.actions;

export default customerSlice.reducer;
