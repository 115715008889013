import React, { useEffect, useState } from "react";
import JsBarcode from "jsbarcode";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import { Button } from "@chakra-ui/react";
import { barcodeTypes, fontOptions } from "../../constant";
import { FaDownload, FaPrint } from "react-icons/fa";

const BarcodeGenerator = () => {
  const [userInput, setUserInput] = useState("Example 1234");
  const [barcodeType, setBarcodeType] = useState("CODE128");
  const [barWidth, setBarWidth] = useState(2);
  const [barHeight, setBarHeight] = useState(100);
  const [barMargin, setBarMargin] = useState(10);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [lineColor, setLineColor] = useState("#000000");
  const [displayText, setDisplayText] = useState(true);
  const [textAlign, setTextAlign] = useState("center");
  const [font, setFont] = useState("Monospace");
  const [fontBold, setFontBold] = useState(false);
  const [fontItalic, setFontItalic] = useState(false);
  const [fontSize, setFontSize] = useState(20);
  const [textMargin, setTextMargin] = useState(0);
  const [isInvalid, setIsInvalid] = useState(false);
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const generateBarcode = () => {
    JsBarcode("#barcode", userInput, {
      format: barcodeType,
      width: barWidth,
      height: barHeight,
      margin: barMargin,
      background: backgroundColor,
      lineColor: lineColor,
      displayValue: displayText,
      textAlign: textAlign,
      font: font,
      fontOptions: `${fontBold ? "bold" : ""} ${fontItalic ? "italic" : ""}`,
      fontSize: fontSize,
      textMargin: textMargin,
      valid: (valid) => {
        if (valid) {
          setIsInvalid(false);
          document.getElementById("barcode").style.display = "block";
          document.getElementById("invalid").style.display = "none";
        } else {
          setIsInvalid(true);
          document.getElementById("barcode").style.display = "none";
          document.getElementById("invalid").style.display = "block";
        }
      },
    });
  };

  useEffect(() => {
    generateBarcode();
  }, [
    userInput,
    barcodeType,
    barHeight,
    barWidth,
    barMargin,
    backgroundColor,
    lineColor,
    displayText,
    textAlign,
    font,
    fontBold,
    fontItalic,
    fontSize,
    textMargin,
  ]);

  const toggleShow = () => {
    setDisplayText((prev) => !prev);
  };

  const handlePrintBarcode = () => {
    const barcodeSvg = document.getElementById("barcode").outerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Barcode</title>
          <style>
            @media print {
              body { margin: 0; }
              svg { display: block; margin: 0 auto; }
            }
          </style>
        </head>
        <body>
          ${barcodeSvg}
          <script>
            setTimeout(function() {
              window.print();
              window.close();
            }, 100);
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const handleDownload = () => {
    const svg = document.getElementById("barcode").outerHTML;
    const blob = new Blob([svg], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "barcode.svg";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  return (
    <>
      <Gap>Barcode Generator</Gap>

      <div className="flex items-center justify-center">
        <svg id="barcode"></svg>
        <span id="invalid" style={{ display: "none" }}>
          Not valid data for this barcode type!
        </span>
      </div>
      <div className="flex lg:flex-wrap lg:flex-row gap-4 flex-col">
        <InputField
          id="userInput"
          labelName={"Code"}
          type="text"
          value={userInput}
          onChange={handleInputChange}
          placeholder="Barcode"
          autoFocus
        />

        <InputField
          type={"select"}
          id="barcodeType"
          labelName={"Barcode Type"}
          title={barcodeType}
          options={barcodeTypes}
          value={{
            label: barcodeType,
            name: barcodeType,
          }}
          onChange={({ value }) => setBarcodeType(value.name)}
        />
        <InputField
          type={"select"}
          id="font"
          labelName="Font"
          options={fontOptions}
          style={{ fontFamily: font }}
          onChange={({ value }) => setFont(value.name)}
          value={{
            label: font,
            name: font,
          }}
        />

        <InputField
          id="bar-width"
          type="range"
          min="1"
          labelName={"Bar Width"}
          max="4"
          step="1"
          value={barWidth}
          onChange={(e) => setBarWidth(parseInt(e.target.value))}
        />

        <InputField
          id="bar-height"
          type="range"
          min="10"
          labelName={"Height"}
          max="150"
          step="5"
          value={barHeight}
          onChange={(e) => setBarHeight(parseInt(e.target.value))}
        />

        <InputField
          id="bar-margin"
          type="range"
          min="0"
          max="25"
          step="1"
          labelName={"Margin"}
          value={barMargin}
          onChange={(e) => setBarMargin(parseInt(e.target.value))}
        />

        <InputField
          id="bar-fontSize"
          labelName={"Font Size"}
          type="range"
          min="8"
          max="36"
          step="1"
          value={fontSize}
          onChange={(e) => setFontSize(parseInt(e.target.value))}
        />

        <InputField
          id="bar-text-margin"
          type="range"
          labelName={"Font Margin"}
          min="-15"
          max="40"
          step="1"
          value={textMargin}
          onChange={(e) => setTextMargin(parseInt(e.target.value))}
        />
        <InputField
          type={"color"}
          labelName={"Background Color"}
          id="background-color"
          value={backgroundColor}
          onChange={(e) => setBackgroundColor(e.target.value)}
        />
        <InputField
          type={"color"}
          labelName={"Line Color"}
          value={lineColor}
          onChange={(e) => setLineColor(e.target.value)}
        />
        <div className="mt-6 gap-2 flex flex-wrap">
          <Button
rounded={"none"}
            type="button"
            colorScheme={displayText ? "red" : "blue"}
            onClick={toggleShow}
          >
            {displayText ? "Hide" : "Show"}
          </Button>

          <Button
rounded={"none"}
            type="button"
            onClick={() => setTextAlign("left")}
            colorScheme="blue"
          >
            Left
          </Button>
          <Button
rounded={"none"}
            type="button"
            onClick={() => setTextAlign("center")}
            colorScheme="blue"
          >
            Center
          </Button>
          <Button
rounded={"none"}
            type="button"
            onClick={() => setTextAlign("right")}
            colorScheme="blue"
          >
            Right
          </Button>

          <Button
rounded={"none"}
            type="button"
            onClick={() => setFontBold(!fontBold)}
            style={{ fontWeight: fontBold ? "bold" : "normal" }}
            colorScheme="blue"
          >
            Bold
          </Button>
          <Button
rounded={"none"}
            type="button"
            colorScheme="blue"
            onClick={() => setFontItalic(!fontItalic)}
            style={{ fontStyle: fontItalic ? "italic" : "normal" }}
          >
            Italic
          </Button>
          {!isInvalid && (
            <>
                <Button
rounded={"none"}
                type="button"
                colorScheme="blue"
                onClick={handlePrintBarcode}
              >
                <FaPrint size={20} />
              </Button>
              <Button
rounded={"none"} type="button" colorScheme="blue" onClick={handleDownload}>
                <FaDownload size={20} />
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BarcodeGenerator;
