import React from "react";
// import imageConstant from "../imageConstant";

const Layout = ({ children }) => {
  return (
    <>
      {/* <img
        src={imageConstant.logo}
        alt="logo"
        className="w-1/2 lg:w-1/2 md:w-1/4 opacity-[0.05] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-20"
      /> */}
      <div className="flex flex-col lg:p-8 md:p-8 lg:pt-0 relative">
        {children}
      </div>
    </>
  );
};

export default Layout;
