import React, { useEffect, useState } from "react";
import Loader from "../UI/Loader";
import { toast } from "sonner";
import { getErrorMessage } from "../functions";
import { getQrDetailById } from "../services/qrDataApi";
import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { Logo } from "../components/Login/Logo";
const QrDetail = () => {
  const [qrDetailId, setQrDetailId] = useState("");
  const [qrDetail, setQrDetail] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("qrId");
    if (id) {
      setQrDetailId(id);
    }
  }, []);

  const fetchQrDetailById = async () => {
    try {
      setLoading(true);
      if (qrDetailId) {
        const res = await getQrDetailById(qrDetailId);
        setQrDetail(res.data);
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchQrDetailById();
  }, [qrDetailId]);

  if (loading) return <Loader />;

  return (
    <div className="lg:p-10 flex items-center mt-10 lg:justify-start h-full flex-col gap-10">
      <Logo />
      <div>
        <Table variant={"striped"}>
          <Tbody>
            {qrDetail?.data?.map((item, index) => (
              <Tr key={index}>
                <Td>{item.labelName}</Td>
                <Td>{item.value}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <div className="flex flex-col justify-center items-center gap-4">
        <h1>Generate your QR or GST QrDetail for free </h1>
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}`}
          className="text-blue-500 underline italic"
        >
          Click here
        </a>
      </div>
    </div>
  );
};

export default QrDetail;
