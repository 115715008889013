import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  FaDownload,
  FaEdit,
  FaEye,
  FaMailBulk,
  FaPlus,
  FaPrint,
  FaShareAlt,
  FaTrashAlt,
} from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import BasicModal from "../../UI/BasicModal";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import Loader from "../../UI/Loader";
import SelectField from "../../UI/SelectField";
import { COPY_TYPE, itemsPerPage } from "../../constant";
import {
  getErrorMessage,
  getSuccessMessage,
  invoicePDFTemplate,
} from "../../functions";
import {
  deleteInvoice,
  downloadPdf,
  getAllInvoice,
  sendToMail,
} from "../../services/invoiceApi";
import CreateInvoice from "./components/CreateInvoice";
import EditInvoice from "./components/EditInvoice";
import LivePreview from "./components/LivePreview";
import moment from "moment";

const Invoice = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [invoiceToEdit, setInvoiceToEdit] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [copyType, setCopyType] = useState(COPY_TYPE[1]);
  const [showPreview, setShowPreview] = useState(false);
  const { auth } = useSelector((state) => state);
  const companyDetails = useSelector(
    (state) => state.companyDetails.companyDetails
  );
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredProducts = invoices.filter(
    (product) =>
      (product.invoiceNumber &&
        product.invoiceNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (product.customerDetails.name &&
        product.customerDetails.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (product.customerDetails.email &&
        product.customerDetails.email
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (product?.customerDetails?.gst &&
        product.customerDetails.gst
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (product?.customerDetails?.address &&
        product.customerDetails.address
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (product?.customerDetails?.phoneNumber &&
        product.customerDetails.phoneNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (product?.customerDetails?.whatsappNumber &&
        product.customerDetails.whatsappNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (product.billTo &&
        product.billTo.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (product.eWayBillNo &&
        product.eWayBillNo.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (product.stateOfSupply &&
        product.stateOfSupply
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (product.billType &&
        product.billType.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);

  const toggleRefresh = async () => {
    await fetchAllInvoices();
  };
  const fetchAllInvoices = async () => {
    try {
      setLoading(true);
      const res = await getAllInvoice();
      setInvoices(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllInvoices();
  }, []);

  const printPDF = (invoice) => {
    if (!copyType) {
      return toast.error("Please select a copy type");
    }
    // const html = invoice.isTaxIncluded
    //   ? newInvoicePDFTemplate(invoice, copyType.name)
    //   : invoicePDFTemplate(invoice, copyType.name);

    const html = invoicePDFTemplate(invoice, copyType.name);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const iframeDocument = iframe.contentDocument;
    iframeDocument.open();
    iframeDocument.write(html);
    iframeDocument.close();

    setTimeout(() => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 1000);
  };

  const handleEditNavigate = (invoice) => {
    setInvoiceToEdit(invoice);
    setIsEdit(true);
  };
  const handleSelectedInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setShowPreview(true);
  };

  const handleCreateNew = () => {
    setIsCreate(true);
  };

  const handleDeleteInvoice = async (invoice) => {
    try {
      setLoading(true);
      const confirmed = window.confirm(
        "Are you sure you want to delete this invoice?"
      );
      if (confirmed) {
        await deleteInvoice(invoice._id);

        setInvoices((prevInvoices) =>
          prevInvoices.filter((inv) => inv._id !== invoice._id)
        );
        toast.success("Invoice deleted successfully");
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSentToMail = async (invoice) => {
    try {
      if (!copyType) {
        return toast.error("Please select a copy type");
      }
      setLoading(true);
      const res = await sendToMail({
        // html: invoice.isTaxIncluded
        //   ? newInvoicePDFTemplate(invoice, copyType.name)
        //   : invoicePDFTemplate(invoice, copyType.name),
        html: invoicePDFTemplate(invoice, copyType.name),
        from: auth?.user?.email,
        to: invoice?.customerDetails?.email,
        text: `Contact Information \nName:${auth.user?.firstName} ${auth.user?.lastName}\nEmail : ${auth.user?.email} \nPhone Number: ${auth.user?.phoneNumber}`,
        replyTo: auth?.user?.email,
        subject: `Invoice from ${companyDetails.businessName}`,
      });
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPdf = async (invoice) => {
    try {
      if (!copyType) {
        return toast.error("Please select a copy type");
      }
      setLoading(true);

      const response = await downloadPdf({
        // html: invoice.isTaxIncluded
        //   ? newInvoicePDFTemplate(invoice, copyType.name)
        //   : invoicePDFTemplate(invoice, copyType.name),
        html: invoicePDFTemplate(invoice, copyType.name),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf"); // or any other filename you prefer
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };
  const offset = currentPage * itemsPerPage;
  const endOffset = offset + itemsPerPage;

  const currentProducts = filteredProducts.slice(
    offset,
    Math.min(endOffset, filteredProducts.length)
  );

  const handleShare = async (invoiceId) => {
    if (!copyType) {
      return toast.error("Please select a copy type");
    }
    const textToShare =
      `${process.env.REACT_APP_FRONTEND_URL}/invoice/?invoiceId=${invoiceId}&copyType=${copyType.name}`.replace(
        " ",
        "%20"
      );
    try {
      // Check if the browser supports navigator.share
      if (navigator.share) {
        await navigator.share({
          title: "Share Invoice Link",
          text: textToShare,
        });
      } else {
        // Fallback for browsers that do not support navigator.share
        const tempInput = document.createElement("input");
        tempInput.value = textToShare;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert("Text copied to clipboard");
      }
    } catch (error) {
      toast.error("Error sharing. Please try again.");
    }
  };

  if (loading) return <Loader />;

  return (
    <div>
      <BasicModal isOpen={isCreate} onClose={() => setIsCreate(false)}>
        <CreateInvoice
          setIsCreate={setIsCreate}
          defaultInvoiceNumber={invoices.length}
          setInvoices={setInvoices}
        />
      </BasicModal>
      <BasicModal isOpen={isEdit} onClose={() => setIsEdit(false)}>
        <EditInvoice
          invoice={invoiceToEdit}
          toggleRefresh={toggleRefresh}
          setIsEdit={setIsEdit}
        />
      </BasicModal>
      <BasicModal isOpen={showPreview} onClose={() => setShowPreview(false)}>
        <LivePreview invoice={selectedInvoice} />
      </BasicModal>
      <Gap>Invoices</Gap>
      <div className="flex gap-4">
        <InputField
          type="text"
          placeholder="Search invoice..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16  text-sm focus:outline-none"
        />
        <Button
          rounded={"none"}
          colorScheme="blue"
          onClick={handleCreateNew}
          className="flex items-center gap-1"
        >
          <p>Create New</p> <FaPlus size={14} />
        </Button>
      </div>
      <div className="overflow-y-auto">
        {isMobileView ? (
          <div className="flex flex-col gap-4 p-4">
            {currentProducts.map((item) => (
              <div key={item._id} className="bg-gray-100 p-2  shadow-md">
                <div className="font-bold">
                  Invoice Number: {item.invoiceNumber}
                </div>
                <div className="flex flex-col gap-1">
                  <div>
                    <span className="font-semibold">Customer:</span>{" "}
                    {item.customerDetails.name}
                  </div>
                  <div>
                    <span className="font-semibold">Date of Invoice:</span>{" "}
                    {new Date(item.invoiceDate).toLocaleDateString()}
                  </div>
                  <div className="flex gap-2 items-center">
                    <SelectField
                      inputClass={"w-full"}
                      value={{
                        label: copyType.name,
                        value: copyType,
                      }}
                      options={COPY_TYPE}
                      onChange={({ value }) => {
                        setCopyType(value);
                      }}
                      selectStyles={{
                        control: (provided) => ({
                          ...provided,
                          width: "150px",
                        }),
                      }}
                    />
                  </div>
                  <div className="flex gap-2 mt-2">
                    {isEdit ? (
                      <Button rounded={"none"} onClick={() => setIsEdit(false)}>
                        <MdCancel size={20} color="blue" />
                      </Button>
                    ) : (
                      <>
                        {isMobileView ? null : (
                          <Button
                            rounded={"none"}
                            onClick={() => handleSelectedInvoice(item)}
                            size="xs"
                          >
                            <FaEye size={20} color="darkBlue" />
                          </Button>
                        )}
                        <Button
                          rounded={"none"}
                          onClick={() => handleShare(item._id)}
                          size="xs"
                        >
                          <FaShareAlt size={20} color="darkBlue" />
                        </Button>
                        <Button
                          rounded={"none"}
                          onClick={() => printPDF(item)}
                          size="xs"
                        >
                          <FaPrint size={20} />
                        </Button>
                        <Button
                          rounded={"none"}
                          onClick={() => handleSentToMail(item)}
                          size="xs"
                        >
                          <FaMailBulk size={20} color="purple" />
                        </Button>
                        <Button
                          rounded={"none"}
                          onClick={() => handleDownloadPdf(item)}
                          size="xs"
                        >
                          <FaDownload size={20} color="green" />
                        </Button>
                        <Button
                          rounded={"none"}
                          onClick={() => handleEditNavigate(item)}
                          size="xs"
                        >
                          <FaEdit size={20} color="blue" />
                        </Button>
                        <Button
                          rounded={"none"}
                          onClick={() => handleDeleteInvoice(item)}
                          size="xs"
                        >
                          <FaTrashAlt size={20} color="red" />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="overflow-y-auto">
            <Table variant="striped" className="mt-4">
              <Thead>
                <Tr>
                  <Th>Invoice Number</Th>
                  <Th>Customer</Th>
                  <Th>Date of Invoice</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentProducts.map((item) => (
                  <Tr key={item._id}>
                    <Td>{item.invoiceNumber}</Td>
                    <Td>{item.customerDetails.name}</Td>
                    <Td>{moment(item.invoiceDate).format("DD MMM YYYY")}</Td>
                    <Td className="flex gap-2 items-center">
                      <SelectField
                        inputClass={"w-full"}
                        value={{
                          label: copyType.name,
                          value: copyType,
                        }}
                        options={COPY_TYPE}
                        onChange={({ value }) => {
                          setCopyType(value);
                        }}
                        selectStyles={{
                          control: (provided) => ({
                            ...provided,
                            width: "150px",
                          }),
                        }}
                      />
                      <div className="flex gap-2">
                        {isEdit ? (
                          <Button
                            rounded={"none"}
                            onClick={() => setIsEdit(false)}
                          >
                            <MdCancel size={24} color="blue" />
                          </Button>
                        ) : (
                          <>
                            <Button
                              rounded={"none"}
                              onClick={() => handleSelectedInvoice(item)}
                            >
                              <FaEye size={24} color="darkBlue" />
                            </Button>
                            <Button
                              rounded={"none"}
                              onClick={() => handleShare(item._id)}
                            >
                              <FaShareAlt size={24} color="darkBlue" />
                            </Button>
                            <Button
                              rounded={"none"}
                              onClick={() => printPDF(item)}
                            >
                              <FaPrint size={24} />
                            </Button>
                            <Button
                              rounded={"none"}
                              onClick={() => handleSentToMail(item)}
                            >
                              <FaMailBulk size={24} color="purple" />
                            </Button>
                            <Button
                              rounded={"none"}
                              onClick={() => handleDownloadPdf(item)}
                            >
                              <FaDownload size={24} color="green" />
                            </Button>
                            <Button
                              rounded={"none"}
                              onClick={() => handleEditNavigate(item)}
                            >
                              <FaEdit size={24} color="blue" />
                            </Button>
                            <Button
                              rounded={"none"}
                              onClick={() => handleDeleteInvoice(item)}
                            >
                              <FaTrashAlt size={24} color="red" />
                            </Button>
                          </>
                        )}
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        )}
      </div>
      <div className="flex lg:p-5 lg:w-full p-1 w-4/5">
        <ReactPaginate
          nextLabel={"Next"}
          previousLabel={"Back"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={isMobileView ? 1 : 2}
          pageRangeDisplayed={isMobileView ? 1 : 5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          className="flex gap-3 pagination page-link page-item"
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default Invoice;
