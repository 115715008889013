import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyDetails: {
    email: "",
    businessName: "",
    phoneNumber: "",
    address: "",
    bankName: "",
    ifscCode: "",
    bankAccountNumber: "",
    bankBranch: "",
    gst: "",
    vehicle: "",
    transport: "",
    station: "",
  },
};

export const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState,
  reducers: {
    setCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    },
    emptyCustomerDetails: (state) => {
      state.companyDetails = {
        email: "",
        businessName: "",
        phoneNumber: "",
        address: "",
        bankName: "",
        ifscCode: "",
        bankAccountNumber: "",
        bankBranch: "",
        gst: "",
        vehicle: "",
        transport: "",
        station: "",
      };
    },
  },
});

export const { setCompanyDetails, emptyCustomerDetails } =
  companyDetailsSlice.actions;

export default companyDetailsSlice.reducer;
