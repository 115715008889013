import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FaEye, FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import { createKhata, getAllKhatabook } from "../../services/khatabookApi";
import {
  addParty,
  deletePartyById,
  getAllParties,
} from "../../services/userWithoutValidationApi";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import Loader from "../../UI/Loader";
import SelectField from "../../UI/SelectField";
import KhataDetails from "./components/KhataDetails";

const Khatabook = () => {
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [khatas, setKhatas] = useState([]);
  const [allParties, setAllParties] = useState([]);
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);
  const [showKhataDetails, setShowKhataDetails] = useState(false);
  const [partyName, setPartyName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [basePayment, setBasePayment] = useState(0);
  const [month, setMonth] = useState("All");
  const [year, setYear] = useState(new Date().getFullYear());
  const fetchKhatabook = async () => {
    try {
      setLoading(true);
      const res = await getAllKhatabook();
      setKhatas(res.data);
      setLoading(false);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleAddParty = async () => {
    try {
      if (!partyName) {
        return toast.error("Please enter party name");
      }
      setLoading(true);
      const res = await addParty({
        name: partyName,
      });
      setAllParties((prevParties) => [res.data, ...prevParties]);
      setPartyName("");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKhatabook();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleLedgerDetailsCreate();
  };

  const handleLedgerDetailsCreate = async () => {
    try {
      setLoading(true);
      const res = await createKhata({
        partyName: selectedEmployee.name,
        month,
        year,
        basePay: basePayment,
      });
      fetchKhatabook();
      setIsEdit(false);

      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const fetchAllParties = async () => {
    try {
      setLoading(true);
      const res = await getAllParties();
      setAllParties(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllParties();
  }, []);

  const handleDeleteParty = async (partyId) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this party?"
      );
      if (!shouldDelete) {
        return;
      }

      setLoading(true);
      const res = await deletePartyById(partyId);
      toast.success(getSuccessMessage(res));
      setAllParties((prevParties) =>
        prevParties.filter((party) => party._id !== partyId)
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (employee) => {
    setSelectedEmployee(employee);
    setShowKhataDetails(true);
  };

  if (loading) return <Loader />;

  return (
    <>
      {showKhataDetails ? (
        <KhataDetails
          employee={selectedEmployee}
          setShowKhataDetails={setShowKhataDetails}
        />
      ) : (
        <>
          <div>
            <Gap>Employee</Gap>
            <div className="flex flex-wrap gap-4">
              {allParties?.map((party, index) => (
                <div className="flex gap-2 items-center" key={index}>
                  <div className="flex items-center gap-2 bg-gray-200 px-3 py-1 ">
                    <span>{party.name}</span>
                    <button
                      onClick={() => handleDeleteParty(party._id)}
                      className="flex items-center justify-center w-5 h-5 bg-red-500 text-white  hover:bg-red-700"
                    >
                      <FaTimes size={10} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex gap-2 items-center mt-2">
              <InputField
                type="text"
                placeholder={"Employee Name"}
                value={partyName}
                onChange={(e) => setPartyName(e.target.value)}
              />
              <Button
                rounded={"none"}
                onClick={handleAddParty}
                size={"md"}
                colorScheme="blue"
              >
                Add
              </Button>
            </div>
          </div>
          <div>
            <Gap>Add Khata</Gap>
            <form
              className="flex lg:grid lg:grid-row-3 gap-4 flex-col"
              onSubmit={handleSubmit}
            >
              <div className="flex lg:flex-row flex-col gap-4">
                <div className="flex  lg:w-96">
                  <SelectField
                    type={"text"}
                    labelName={"Employee"}
                    uni={"employee"}
                    options={allParties?.map((party) => ({
                      name: party.name,
                      value: party,
                    }))}
                    // ?.sort((a, b) => a.firstName.localeCompare(b.firstName))}
                    value={{
                      label:
                        selectedEmployee?.name && `${selectedEmployee?.name} `,
                      value: selectedEmployee,
                    }}
                    name={"employee"}
                    onChange={({ value }) => {
                      setSelectedEmployee(value.value);
                    }}
                  />
                </div>
                <div className="flex flex-col  flex-1">
                  <label
                    className=" text-secondary font-bold col-span-1 flex items-center"
                    htmlFor="DatePicker"
                  >
                    Month
                  </label>
                  <DatePicker
                    id="MonthPicker"
                    className="text-black text-center border w-full border-gray-300 p-1.5 focus:outline-none focus:border-blue-500"
                    selected={
                      month == null || month === "All"
                        ? null
                        : new Date(new Date().getFullYear(), month, 1)
                    }
                    onChange={(date) => {
                      // Ensure date is not null

                      setMonth(date.getMonth()); // Months are 0-based in JavaScript Date
                    }}
                    showMonthYearPicker
                    dateFormat="MMMM"
                    required
                  />
                </div>
                <div className="flex flex-col  flex-1">
                  <label
                    className=" text-secondary font-bold col-span-1 flex items-center"
                    htmlFor="DatePicker"
                  >
                    Year
                  </label>
                  <DatePicker
                    id="DatePicker"
                    type="string"
                    className="text-black text-center border w-full border-gray-300  p-1.5  focus:outline-none focus:border-blue-500"
                    selected={new Date(year, 0)}
                    onChange={(date) => {
                      //make sure date not become null
                      if (!date) return;
                      setYear(date?.getFullYear());
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    required
                  />
                </div>
                <div className="flex flex-col gap-2 flex-1">
                  <InputField
                    labelName={"Base Pay"}
                    type="number"
                    placeholder="Base Pay..."
                    value={basePayment}
                    onChange={(e) => setBasePayment(e.target.value)}
                    className="border border-gray-300 p-2 "
                  />
                </div>
              </div>

              <div className="flex lg:flex-row flex-col gap-4 w-full">
                <Button
                  rounded={"none"}
                  colorScheme="blue"
                  className="lg:mt-6 w-full "
                  type="submit"
                >
                  {isEdit ? "Update" : "Add"}
                </Button>
              </div>
            </form>
          </div>
          <div className="p-0">
            <Gap>Employees</Gap>
            <div className="flex gap-2 flex-1 w-full lg:flex-row flex-col"></div>
            <div className=" text-secondary font-bold col-span-1 flex items-center mt-2 border-2 border-secondary p-2 w-fit ">
              <span className="text-lg"> Total Khatas: {khatas.length}</span>
            </div>
            <div className="overflow-x-auto">
              {isMobileView ? (
                <div className="grid grid-cols-1 gap-4 mt-4">
                  {khatas?.length > 0 &&
                    khatas?.map((history, index) => (
                      <div
                        key={history._id}
                        className="bg-white shadow-md  p-4 border border-gray-200"
                      >
                        <div className="flex justify-between items-center mb-3">
                          <span className="font-bold text-lg">
                            S.No: {index + 1}
                          </span>
                        </div>

                        <div className="mb-2">
                          <span className="font-semibold">Employee Name:</span>{" "}
                          {history?.partyName}
                        </div>
                        <div className="mb-2">
                          <Button
                            rounded={"none"}
                            colorScheme="blue"
                            // onClick={() => setShowKhataDetails(true)}
                            onClick={() => handleEdit(history)}
                          >
                            <FaEye size={20} />
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <Table variant="striped" className="mt-4">
                    <Thead className="bg-gray-50">
                      <Tr>
                        <Th>S.No</Th>
                        <Th>Employee Name</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {khatas?.map((history, index) => (
                        <Tr key={history._id}>
                          <Td>{index + 1}</Td>
                          <Td>{history?.partyName}</Td>

                          <Td>
                            <Button
                              rounded={"none"}
                              colorScheme="blue"
                              // onClick={() => setShowKhataDetails(true)}
                              onClick={() => handleEdit(history)}
                            >
                              <FaEye size={20} />
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Khatabook;
