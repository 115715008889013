import http from "./http";

const userApi = "/api/auth";

const otpApi = "/api/auth/otp";

// const loginActivityApi = "/api/login/activity";

export const register = (data) => {
  return http.post(`${userApi}/signup`, data);
};

export const login = async (data) => {
  const res = await http.post(`${userApi}/login`, data);
  return res;
};

export const getUser = (userId) => {
  return http.get(`${userApi}/profile/${userId}`);
};

export const logout = () => {
  return http.post(`${userApi}/logout`);
};

export const updateUser = (userId, data) => {
  return http.put(`${userApi}/update/${userId}`, data);
};
export const sendOtp = (data) => {
  return http.post(`${otpApi}/send`, data);
};

export const verifyOtp = (data) => {
  return http.post(`${otpApi}/verify`, data);
};

export const resetPassword = (data) => {
  return http.post(`${userApi}/reset-password`, data);
};

export const getAllUsers = () => {
  return http.get(`${userApi}/get`);
};

export const markUserAsVerified = (userId) => {
  return http.put(`${userApi}/account/verify/${userId}`);
};

export const markUserAsNotVerified = (userId) => {
  return http.put(`${userApi}/account/unverify/${userId}`);
};
