import React from "react";
import SelectField from "./SelectField";

const InputField = ({
  labelName,
  name,
  type,
  uni,
  ref,
  placeholder,
  onChange,
  value,
  touched,
  min = 0,
  max,
  error,
  onBlur,
  disabled,
  rowWise,
  inputClass,
  options,
  required = false,
  selectStyles,
  checked = false,
}) => {
  return (
    <div>
      <div
        className={`col-span-1  focus:outline-none focus:border-blue-500 ${
          rowWise ? "grid grid-cols-3" : "flex flex-col"
        } `}
      >
        <label
          className=" text-secondary font-bold col-span-1 flex items-center"
          htmlFor={uni}
        >
          {labelName}
        </label>
        {type === "select" ? (
          <SelectField
            ref={ref}
            disabled={disabled}
            name={name}
            min="0"
            value={value}
            onChange={onChange}
            className={`border-[1px] p-2 bg-white focus:outline-none focus:border-blue-500   ${
              disabled ? "bg-gray-300" : ""
            } ${inputClass}`}
            placeholder={placeholder}
            type={type}
            id={uni}
            onBlur={onBlur}
            required={required}
            options={options}
            selectStyles={selectStyles}
          />
        ) : type === "textarea" ? (
          <textarea
            disabled={disabled}
            min="0"
            value={value}
            ref={ref}
            name={name}
            onChange={onChange}
            className={`border-[1px] p-2 focus:outline-none focus:border-blue-500  ${
              disabled
                ? "bg-gray-300 hover:cursor-not-allowed text-gray-800"
                : "bg-white"
            } ${inputClass}`}
            placeholder={placeholder}
            type={type}
            id={uni}
            onBlur={onBlur}
            required={required}
          />
        ) : (
          <input
            disabled={disabled}
            min={min}
            max={max}
            value={value}
            ref={ref}
            checked={checked}
            //allow decimal
            step={"any"}
            //disable stepper

            onChange={onChange}
            className={`border-[1px] p-1.5  no-stepper  focus:outline-none focus:border-blue-500 ${
              disabled
                ? "bg-gray-300 hover:cursor-not-allowed text-gray-800"
                : "bg-white"
            } ${inputClass}`}
            placeholder={placeholder}
            type={type}
            id={uni}
            name={uni}
            onBlur={onBlur}
            required={required}
          />
        )}
      </div>
      {touched && error && <p className="text-red-500 ">{error}</p>}
    </div>
  );
};

export default InputField;
