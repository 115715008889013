import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FaEdit, FaPlus, FaPrint, FaTimes, FaTrashAlt } from "react-icons/fa";
import { MdCancel, MdRestartAlt } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { v4 } from "uuid";
import { batteryDetailsTemplate, itemsPerPage } from "../../constant";
import { debounce, getErrorMessage, getSuccessMessage } from "../../functions";
import {
  addBatteryDetails,
  deleteBatteryDetails,
  getAllBatteryDetails,
} from "../../services/batteryDetailsApi";
import {
  addParty,
  deletePartyById,
  getAllParties,
} from "../../services/userWithoutValidationApi";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import Loader from "../../UI/Loader";
import SelectField from "../../UI/SelectField";

const statusOptions = [
  { name: "Sold", value: "Sold" },
  {
    name: "Replacement",
    value: "Replacement",
  },
  {
    name: "Processed",
    value: "Processed",
  },
  {
    name: "Returned",
    value: "Returned",
  },
  {
    name: "Cancelled",
    value: "Cancelled",
  },
];

const BatteryDetails = () => {
  const [loading, setLoading] = useState(false);
  const [batteryDetailsHistory, setBatteryDetailsHistory] = useState([]);
  batteryDetailsHistory;
  const [dateOfEntry, setDateOfEntry] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [searchStatus, setSearchStatus] = useState("All");
  const [allParties, setAllParties] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [formData, setFormData] = useState({
    batterySNo: "",
    partyName: "",
    batteryType: "",
    status: "",
    batteryReplacement: "",
  });
  const [partyName, setPartyName] = useState("");
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);
  const dealers = useSelector((state) => state.dealer.dealers);
  const customers = useSelector((state) => state.customer.customers);
  const [totalCount, setTotalCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [filteredBatteryDetailsHistory, setFilteredBatteryDetailsHistory] =
    useState([]);
  const [month, setMonth] = useState("All");
  const [year, setYear] = useState(null);
  const [date, setDate] = useState(0);
  const [secondaryBatteryDetails, setSecondaryBatteryDetails] = useState([
    {
      batterySNo: "",
      batteryType: "",
      batteryReplacement: "",
      id: v4(),
    },
  ]);

  useEffect(() => {
    const page = window.location.search;
    const urlParams = new URLSearchParams(page);
    const date = urlParams.get("date");
    const month = urlParams.get("month");
    const year = urlParams.get("year");

    setDate(date ? Number(date) : 0);
    setMonth(month ? month : "All");
    setYear(
      year == null || year == "NaN" ? null : isNaN(year) ? null : Number(year)
    );
  }, []);

  const handleAddSecondaryBatteryDetails = () => {
    setSecondaryBatteryDetails((prevSecondaryBatteryDetails) => [
      ...prevSecondaryBatteryDetails,
      {
        batterySNo: "",
        batteryType: "",
        batteryReplacement: "",
        id: v4(),
      },
    ]);
  };

  const navigate = useNavigate();

  const handleDeleteSecondaryBatteryDetails = (id) => {
    if (!id) return;
    if (secondaryBatteryDetails.length === 1) return;
    setSecondaryBatteryDetails((prevSecondaryBatteryDetails) =>
      prevSecondaryBatteryDetails.filter((item) => item.id !== id)
    );
  };

  const handleChangeSecondaryBatteryDetails = (id, field, value) => {
    if (!id) return;
    setSecondaryBatteryDetails((prevSecondaryBatteryDetails) =>
      prevSecondaryBatteryDetails.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleAddParty = async () => {
    try {
      if (!partyName) {
        return toast.error("Please enter party name");
      }
      setLoading(true);
      const res = await addParty({
        name: partyName,
      });
      setAllParties((prevParties) => [res.data, ...prevParties]);
      setPartyName("");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchAllParties = async () => {
    try {
      setLoading(true);
      const res = await getAllParties();
      setAllParties(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllParties();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleBatteryDetailsCreate();
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const fetchBatteryDetails = async (query) => {
    try {
      setLoading(true);
      if (!query) {
        query = `?page=1&limit=${itemsPerPage}&date=${date}&month=${month}&year=${year}&search=${searchQuery}&status=${searchStatus}`;
      }
      navigate({
        search: query,
      });
      const res = await getAllBatteryDetails(query);
      setBatteryDetailsHistory(res.data);
      setTotalPages(res.totalPages);
      setTotalCount(res.totalCount);
      setFilteredBatteryDetailsHistory(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchQuery !== searchQuery) return;
    fetchBatteryDetails();
  }, [date, month, year, debouncedSearchQuery, searchStatus]);

  const debouncedChange = debounce((inputValue) => {
    setDebouncedSearchQuery(inputValue);
  }, 1000);

  const handleSearchInputChange = async (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedChange(value);
  };

  const printPDF = () => {
    const html = batteryDetailsTemplate(filteredBatteryDetailsHistory);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const iframeDocument = iframe.contentDocument;
    iframeDocument.open();
    iframeDocument.write(html);
    iframeDocument.close();

    setTimeout(() => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 1000);
  };

  const handleBatteryDetailsCreate = async () => {
    try {
      if (!dateOfEntry) {
        return toast.error("Please enter date of entry");
      }
      if (secondaryBatteryDetails.length === 0) {
        return toast.error("Please add secondary battery details");
      }

      setLoading(true);
      const res = await addBatteryDetails({
        ...formData,
        dateOfEntry,
        batteryDetailsId: selectedId,
        secondaryBatteryDetails,
      });
      setFormData({
        name: "",
        batterySNo: "",
        partyName: "",
        batteryType: "",
        status: "",
        batteryReplacement: "",
      });
      setSecondaryBatteryDetails([
        {
          batterySNo: "",
          batteryType: "",
          batteryReplacement: "",
          id: v4(),
        },
      ]);
      setIsEdit(false);

      if (isEdit) {
        setBatteryDetailsHistory((prevHistory) =>
          prevHistory.map((item) => (item._id === selectedId ? res.data : item))
        );
      } else {
        await fetchBatteryDetails();
      }
      setSelectedId("");
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const formattedDate = dateOfEntry
    ? dateOfEntry.toISOString().split("T")[0]
    : "";

  const handleEdit = (history) => {
    setDateOfEntry(new Date(history.dateOfEntry));
    setFormData({
      ...formData,
      ...history,
    });
    setSecondaryBatteryDetails([
      {
        batterySNo: history.batterySNo,
        batteryType: history.batteryType,
        batteryReplacement: history.batteryReplacement,
        id: v4(),
      },
    ]);
    setSelectedId(history._id);
    setIsEdit(true);
  };

  const handleDeleteBatteryDetails = async (batteryDetails) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this log?"
      );
      if (!shouldDelete) {
        return;
      }

      setLoading(true);
      const res = await deleteBatteryDetails(batteryDetails._id);
      toast.success(getSuccessMessage(res));

      setBatteryDetailsHistory((prevHistory) =>
        prevHistory.filter((history) => history._id !== batteryDetails._id)
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteParty = async (partyId) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this party?"
      );
      if (!shouldDelete) {
        return;
      }

      setLoading(true);
      const res = await deletePartyById(partyId);
      toast.success(getSuccessMessage(res));
      setAllParties((prevParties) =>
        prevParties.filter((party) => party._id !== partyId)
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleResetFilters = () => {
    setSearchQuery("");
    setSearchStatus("All");
    setMonth("All");
    setYear(null);
    setDate(0);
  };

  const handlePageClick = async (data) => {
    const selectedPage = data.selected;
    const query = `?page=${
      selectedPage + 1
    }&limit=${itemsPerPage}&date=${date}&month=${month}&year=${year}&search=${searchQuery}&status=${searchStatus}`;
    setCurrentPage(selectedPage);
    navigate({
      search: query,
    });
    await fetchBatteryDetails(query);
  };

  if (loading) return <Loader />;

  return (
    <>
      <div>
        <Gap>Add Details</Gap>
        <form
          className="flex lg:grid lg:grid-row-3 gap-4 flex-col"
          onSubmit={handleSubmit}
        >
          <div className="flex lg:flex-row flex-col gap-4">
            <div>
              <InputField
                type={"date"}
                labelName={"Date"}
                value={formattedDate}
                onChange={(e) => setDateOfEntry(new Date(e.target.value))}
              />
            </div>
            <div className="flex  lg:w-96">
              <SelectField
                type={"text"}
                labelName={"Party Name"}
                uni={"partyName"}
                options={[...allParties, ...customers, ...dealers]
                  .map((party) => ({
                    name: party.name,
                    value: party.name,
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name))}
                value={{
                  label: formData.partyName,
                  value: formData.partyName,
                }}
                name={"partyName"}
                onChange={({ value }) => {
                  setFormData({
                    ...formData,
                    partyName: value.value,
                  });
                }}
              />
            </div>
            <div className="flex lg:w-44">
              <SelectField
                labelName={"Status"}
                inputClass={"w-full"}
                options={statusOptions}
                value={{
                  label: formData.status,
                  value: formData.status,
                }}
                name={"status"}
                onChange={({ value }) => {
                  setFormData({
                    ...formData,
                    status: value.value,
                  });
                }}
              />
            </div>
          </div>
          {secondaryBatteryDetails.map((item) => (
            <div className="flex gap-4" key={item.id}>
              <div className="flex lg:flex-row flex-col gap-4">
                <div>
                  <InputField
                    type={"text"}
                    labelName={"Battery SNo."}
                    uni={"batterySNo"}
                    value={item.batterySNo}
                    onChange={(e) =>
                      handleChangeSecondaryBatteryDetails(
                        item.id,
                        "batterySNo",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div>
                  <InputField
                    type={"text"}
                    labelName={"Battery Type"}
                    uni={"batteryType"}
                    value={item.batteryType}
                    onChange={(e) =>
                      handleChangeSecondaryBatteryDetails(
                        item.id,
                        "batteryType",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div>
                  <InputField
                    type={"text"}
                    labelName={"Battery Replacement"}
                    uni={"batteryReplacement"}
                    value={item.batteryReplacement}
                    onChange={(e) =>
                      handleChangeSecondaryBatteryDetails(
                        item.id,
                        "batteryReplacement",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <Button
                rounded={"none"}
                colorScheme="blue"
                className="lg:mt-6 "
                onClick={() => handleAddSecondaryBatteryDetails()}
              >
                <FaPlus size={20} />
              </Button>
              <Button
                rounded={"none"}
                colorScheme="red"
                className="lg:mt-6 "
                onClick={() => handleDeleteSecondaryBatteryDetails(item.id)}
              >
                <FaTrashAlt size={20} />
              </Button>
            </div>
          ))}
          <div className="flex lg:flex-row flex-col gap-4 w-full">
            <Button
              rounded={"none"}
              colorScheme="blue"
              className="lg:mt-6 w-full "
              type="submit"
            >
              {isEdit ? "Update" : "Add"}
            </Button>
          </div>
        </form>
      </div>
      <div>
        <Gap>Parties</Gap>
        <div className="flex flex-wrap gap-4">
          {allParties?.map((party, index) => (
            <div className="flex gap-2 items-center" key={index}>
              <div className="flex items-center gap-2 bg-gray-200 px-3 py-1 ">
                <span>{party.name}</span>
                <button
                  onClick={() => handleDeleteParty(party._id)}
                  className="flex items-center justify-center w-5 h-5 bg-red-500 text-white  hover:bg-red-700"
                >
                  <FaTimes size={10} />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="flex gap-2 items-center mt-2">
          <InputField
            type="text"
            placeholder={"Party Name"}
            value={partyName}
            onChange={(e) => setPartyName(e.target.value)}
          />
          <Button
            rounded={"none"}
            onClick={handleAddParty}
            size={"md"}
            colorScheme="blue"
          >
            Add
          </Button>
        </div>
      </div>
      <div className="p-0">
        <Gap>Battery Details</Gap>
        <div className="flex gap-2 flex-1 w-full lg:flex-row flex-col">
          <div className="flex flex-col flex-1">
            <label
              className=" text-secondary font-bold col-span-1 flex items-center"
              htmlFor="DatePicker"
            >
              Date
            </label>
            <DatePicker
              id="DayPicker"
              selected={
                date
                  ? new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      date
                    )
                  : null
              }
              onChange={(date) => {
                //make sure date not become null
                if (!date) return;
                setDate(date?.getDate());
              }}
              dateFormat="d"
              showMonthDropdown={false}
              showYearDropdown={false}
              showMonthYearPicker={false}
              showFullMonthYearPicker={false}
              className="text-black text-center border w-full border-gray-300 p-1.5 focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="flex flex-col  flex-1">
            <label
              className=" text-secondary font-bold col-span-1 flex items-center"
              htmlFor="DatePicker"
            >
              Month
            </label>
            <DatePicker
              id="MonthPicker"
              className="text-black text-center border w-full border-gray-300 p-1.5 focus:outline-none focus:border-blue-500"
              selected={
                month == null || month === "All"
                  ? null
                  : new Date(new Date().getFullYear(), month, 1)
              }
              onChange={(date) => {
                // Ensure date is not null
                if (!date) return;
                setMonth(date.getMonth()); // Months are 0-based in JavaScript Date
              }}
              showMonthYearPicker
              dateFormat="MMMM"
              required
            />
          </div>
          <div className="flex flex-col  flex-1">
            <label
              className=" text-secondary font-bold col-span-1 flex items-center"
              htmlFor="DatePicker"
            >
              Year
            </label>
            <DatePicker
              id="DatePicker"
              type="string"
              className="text-black text-center border w-full border-gray-300  p-1.5  focus:outline-none focus:border-blue-500"
              selected={
                year === null || year === "NaN" ? null : new Date(year, 0)
              }
              onChange={(date) => {
                //make sure date not become null
                if (!date) return;
                setYear(date?.getFullYear());
              }}
              showYearPicker
              dateFormat="yyyy"
              yearItemNumber={10}
              required
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <InputField
              labelName={"Search"}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="border border-gray-300 "
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <SelectField
              labelName={"Status"}
              inputClass={"w-fit"}
              options={[{ name: "All", value: "All" }, ...statusOptions]}
              value={{
                label: searchStatus,
                value: searchStatus,
              }}
              name={"status"}
              onChange={({ value }) => {
                setSearchStatus(value.value);
              }}
            />
          </div>
          <div
            className="flex flex-col gap-2 flex-1 mt-6"
            title="Reset Filters"
          >
            <Button rounded={"none"} onClick={handleResetFilters} size={"md"}>
              <MdRestartAlt size={20} color="green" />
            </Button>
          </div>
          <div className="flex flex-col gap-2 flex-1 mt-6">
            <Button rounded={"none"} onClick={printPDF} size={"md"}>
              <FaPrint size={20} color="blue" />
            </Button>
          </div>
        </div>
        <div className=" text-secondary font-bold col-span-1 flex items-center mt-2 border-2 border-secondary p-2 w-fit ">
          <span className="text-lg"> Total Battery Details: {totalCount}</span>
        </div>
        <div className="overflow-x-auto">
          {isMobileView ? (
            <div className="grid grid-cols-1 gap-4 mt-4">
              {filteredBatteryDetailsHistory?.map((history, index) => (
                <div
                  key={history._id}
                  className="bg-white shadow-md  p-4 border border-gray-200"
                >
                  <div className="flex justify-between items-center mb-3">
                    <span className="font-bold text-lg">S.No: {index + 1}</span>
                    <div className="flex gap-2">
                      {isEdit ? (
                        <button
                          onClick={() => setIsEdit(false)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <MdCancel size={24} />
                        </button>
                      ) : (
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleEdit(history)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            <FaEdit size={20} />
                          </button>
                          <button
                            onClick={() => handleDeleteBatteryDetails(history)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <FaTrashAlt size={20} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Date:</span>{" "}
                    {moment(history.dateOfEntry).format("DD MMM YYYY")}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Battery SNo.:</span>{" "}
                    {history.batterySNo}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Party Name:</span>{" "}
                    {history.partyName}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Battery Type:</span>{" "}
                    {history.batteryType}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Status:</span>{" "}
                    {history.status}
                  </div>
                  <div>
                    <span className="font-semibold">
                      Replacement Battery SNo.:
                    </span>{" "}
                    {history.batteryReplacement}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="overflow-x-auto">
              <Table variant="striped" className="mt-4">
                <Thead className="bg-gray-50">
                  <Tr>
                    <Th>S.No</Th>
                    <Th>Date</Th>
                    <Th>Battery SNo.</Th>
                    <Th>Party Name</Th>
                    <Th>Battery Type</Th>
                    <Th>Status</Th>
                    <Th>Replacement Battery SNo.</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredBatteryDetailsHistory?.map((history, index) => (
                    <Tr key={history._id}>
                      <Td>{index + 1}</Td>
                      <Td>
                        {moment(history.dateOfEntry).format("DD MMM YYYY")}
                      </Td>
                      <Td>{history.batterySNo}</Td>
                      <Td>{history.partyName}</Td>
                      <Td>{history.batteryType}</Td>
                      <Td>{history.status}</Td>
                      <Td>{history.batteryReplacement}</Td>
                      <Td>
                        {isEdit ? (
                          <button
                            onClick={() => setIsEdit(false)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            <MdCancel size={24} />
                          </button>
                        ) : (
                          <div className="flex gap-2">
                            <button
                              onClick={() => handleEdit(history)}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              <FaEdit size={20} />
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteBatteryDetails(history)
                              }
                              className="text-red-500 hover:text-red-700"
                            >
                              <FaTrashAlt size={20} />
                            </button>
                          </div>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="flex lg:p-5 lg:w-full p-1 w-4/5">
          <ReactPaginate
            nextLabel={"Next"}
            previousLabel={"Back"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={isMobileView ? 1 : 2}
            pageRangeDisplayed={isMobileView ? 1 : 5}
            onPageChange={handlePageClick}
            forcePage={currentPage}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            disabledClassName={"disabled"}
            className="flex gap-3 pagination page-link page-item"
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  );
};

export default BatteryDetails;
