import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobileView: window.innerWidth < 768, // Adjust breakpoint as needed
};

const mobileViewSlice = createSlice({
  name: 'mobileView',
  initialState,
  reducers: {
    setMobileView(state, action) {
      state.isMobileView = action.payload < 768; 
    },
  },
});

export const { setMobileView } = mobileViewSlice.actions;
export default mobileViewSlice.reducer;
