import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import Loader from "../../UI/Loader";
import {
  addEmployee,
  deleteEmployee,
  getAllEmployees,
  updateEmployee,
} from "../../services/employeeApi";
import { toast } from "sonner";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import { useSelector } from "react-redux";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { Pages, ROLES } from "../../constant";
import moment from "moment-timezone";

const Employee = () => {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [employees, setEmployees] = useState([]);
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);
  const [employee, setEmployee] = useState({});
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    confirmPassword: "",
    joiningDate: "",
    pagesPermissions: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!isEdit) {
        if (
          !formData.email ||
          !formData.password ||
          !formData.firstName ||
          !formData.lastName ||
          !formData.phoneNumber ||
          !formData.joiningDate ||
          !formData.confirmPassword
        ) {
          toast.error("Please fill all the fields");
          return;
        }
        if (formData.password !== formData.confirmPassword) {
          toast.error("Passwords do not match");
          return;
        }
        if (formData.password.length < 8) {
          toast.error("Password must be at least 8 characters long");
          return;
        }
        if (formData.phoneNumber.length !== 10) {
          toast.error("Phone number must be 10 digits long");
          return;
        }

        if (formData.pagesPermissions.length === 0) {
          toast.error("Please select at least one page");
          return;
        }
      }
      // if date of joining in in future then show error
      if (new Date(formData.joiningDate) > new Date()) {
        toast.error("Date of joining cannot be in future");
        return;
      }

      let res;
      if (isEdit) {
        res = await updateEmployee(employee._id, formData);
        await fetchEmployees();
      } else {
        res = await addEmployee({
          ...formData,
          joiningDate: formData.joiningDate,
        });
        setEmployees((prevEmployees) => [res.data.user, ...prevEmployees]);
      }
      setFormData({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        confirmPassword: "",
        joiningDate: "",
        pagesPermissions: [],
      });
      setIsEdit(false);

      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (employee) => {
    setFormData({
      ...employee,
      joiningDate: new Date(employee.joiningDate).toISOString().split("T")[0],
    });
    setEmployee(employee);
    setIsEdit(true);
  };

  const handleDeleteEmployee = async (employee) => {
    try {
      if (user._id === employee._id) {
        toast.error("You cannot delete your account");
        return;
      }
      setLoading(true);
      const confirmed = window.confirm(
        "Are you sure you want to delete this employee?"
      );
      if (confirmed) {
        const res = await deleteEmployee(employee._id);

        toast.success(getSuccessMessage(res));
        await fetchEmployees();
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployees = async () => {
    try {
      setLoading(true);
      const res = await getAllEmployees();

      setEmployees(res.data);
      setFilteredEmployees(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleChangePage = (pageName) => {
    //if it is already selected then remove it from the array
    // const selectedPages = formData.pagesPermissions.filter(
    //   (page) => page !== pageName
    // );
    // setFormData({
    //   ...formData,
    //   pagesPermissions: [...selectedPages, pageName],
    // });
    //if it is not already selected then add it to the array
    if (!formData.pagesPermissions.includes(pageName)) {
      setFormData({
        ...formData,
        pagesPermissions: [...formData.pagesPermissions, pageName],
      });
    } else {
      setFormData({
        ...formData,
        pagesPermissions: formData.pagesPermissions.filter(
          (page) => page !== pageName
        ),
      });
    }
  };

  useEffect(() => {
    const filteredEmployees = employees.filter((employee) => {
      const searchLower = searchQuery.toLowerCase();

      // Check if the search query is empty or if any field matches the search query
      const matchesSearchQuery =
        !searchLower || // This allows all items if searchQuery is empty
        employee?.email?.toLowerCase().includes(searchLower) ||
        employee?.firstName?.toLowerCase().includes(searchLower) ||
        employee?.lastName?.toLowerCase().includes(searchLower) ||
        employee?.phoneNumber?.toLowerCase().includes(searchLower);

      return matchesSearchQuery;
    });

    setFilteredEmployees(filteredEmployees);
  }, [searchQuery, employees]);

  if (loading) return <Loader />;

  return (
    <div>
      <Gap>Add Employee</Gap>
      <form
        className="flex lg:flex-wrap lg:flex-row gap-4 flex-col"
        onSubmit={handleSubmit}
      >
        <InputField
          type={"email"}
          labelName={"Email"}
          value={formData.email}
          onChange={handleChange}
          uni="email"
          disabled={isEdit}
        />
        <InputField
          type={"password"}
          labelName={"Password"}
          value={formData.password}
          uni="password"
          onChange={handleChange}
          disabled={isEdit}
        />
        <InputField
          type={"password"}
          labelName={"Confirm Password"}
          value={formData.confirmPassword}
          uni="confirmPassword"
          onChange={handleChange}
          disabled={isEdit}
        />
        <InputField
          type={"text"}
          labelName={"First Name"}
          uni="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
        <InputField
          type={"text"}
          labelName={"Last Name"}
          value={formData.lastName}
          uni="lastName"
          onChange={handleChange}
        />
        <InputField
          type={"tel"}
          labelName={"Phone Number"}
          value={formData.phoneNumber}
          uni="phoneNumber"
          onChange={handleChange}
          disabled={isEdit}
        />
        <InputField
          type={"date"}
          labelName={"Date Of Joining"}
          value={formData.joiningDate}
          uni="joiningDate"
          onChange={handleChange}
        />
        <div>
          <h1 className="text-lg font-medium mb-2">Permissions</h1>
          <div className="flex flex-wrap gap-4">
            {Pages.map((page) => {
              // if (page.hide && user.role !== ROLES.SUPER_ADMIN ) return null;
              if (
                !user.pagesPermissions.includes(page.name) &&
                user.role !== ROLES.SUPER_ADMIN
              )
                return null;
              return (
                <Button
                  rounded={"none"}
                  type="button"
                  key={page.name}
                  colorScheme={
                    formData?.pagesPermissions?.includes(page.name)
                      ? "green"
                      : "gray"
                  }
                  className="lg:mt-6 "
                  onClick={() => handleChangePage(page.name)}
                >
                  {page.label}
                </Button>
              );
            })}
          </div>
        </div>

        <Button
          rounded={"none"}
          colorScheme="blue"
          className="lg:mt-2 w-full"
          type="submit"
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </form>
      <Gap>Employees</Gap>
      <InputField
        type="text"
        placeholder="Search employees..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16  text-sm focus:outline-none"
      />
      <div className="overflow-y-auto">
        {isMobileView ? (
          <div className="flex flex-col gap-4 p-4">
            {filteredEmployees.map((employee, index) => (
              <div key={index} className="bg-gray-100 p-2  shadow-md">
                <div className="font-bold">{employee.firstName}</div>
                <div className="flex flex-col gap-1">
                  <div>
                    <span className="font-semibold">Email:</span>{" "}
                    {employee.email}
                  </div>
                  <div>
                    <span className="font-semibold">Phone Number:</span>{" "}
                    {employee.phoneNumber}
                  </div>
                  <div>
                    <span className="font-semibold">Joining Date:</span>{" "}
                    {
                      //convert date to IST timezone
                      moment(employee.joiningDate)
                        .tz("Asia/Kolkata")
                        .format("DD MMM YYYY")
                    }
                  </div>
                  <div className="flex gap-2 mt-2">
                    {isEdit ? (
                      <Button
                        rounded={"none"}
                        onClick={() => {
                          setIsEdit(false);
                          setFormData({
                            email: "",
                            password: "",
                            firstName: "",
                            lastName: "",
                            phoneNumber: "",
                            confirmPassword: "",
                            joiningDate: "",
                          });
                        }}
                      >
                        <MdCancel size={20} color="blue" />
                      </Button>
                    ) : (
                      <>
                        <Button
                          rounded={"none"}
                          onClick={() => handleEdit(employee)}
                          size="xs"
                        >
                          <FaEdit size={20} color="blue" />
                        </Button>
                        <Button
                          rounded={"none"}
                          onClick={() => handleDeleteEmployee(employee)}
                          size="xs"
                        >
                          <FaTrashAlt size={20} color="red" />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="overflow-y-auto">
            <Table variant="striped" className="mt-4">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Phone Number</Th>
                  <Th>Joining Date</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredEmployees.map((employee, index) => (
                  <Tr key={index}>
                    <Td>{employee.firstName}</Td>
                    <Td>{employee.email}</Td>
                    <Td>{employee.phoneNumber}</Td>
                    <Td>
                      {moment(employee.joiningDate).format("DD MMM YYYY")}
                    </Td>
                    <Td className="flex">
                      {isEdit ? (
                        <Button
                          rounded={"none"}
                          onClick={() => {
                            setIsEdit(false);
                            setFormData({
                              email: "",
                              password: "",
                              firstName: "",
                              lastName: "",
                              phoneNumber: "",
                              confirmPassword: "",
                              joiningDate: "",
                            });
                          }}
                        >
                          <MdCancel size={24} color="blue" />
                        </Button>
                      ) : (
                        <>
                          <Button
                            rounded={"none"}
                            onClick={() => handleEdit(employee)}
                          >
                            <FaEdit size={24} color="blue" />
                          </Button>
                          <Button
                            rounded={"none"}
                            onClick={() => handleDeleteEmployee(employee)}
                          >
                            <FaTrashAlt size={24} color="red" />
                          </Button>
                        </>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Employee;
