import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  isAuth: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuth = false;
      localStorage.clear();
    },
    login: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuth = true;

      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("isAuth", true);
    },
    setUser: (state, action) => {
      state.user = action.payload.user;

      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("isAuth", true);
    },

    signup: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuth = true;

      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("isAuth", true);
    },
    updateUser: (state, action) => {
      state.user = action.payload.user;
      state.token = localStorage.getItem("token");
      state.isAuth = true;

      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("isAuth", true);

      localStorage.setItem("status", "success");
    },
    deleteUser: (state) => {
      state.user = null;
      state.token = null;
      state.isAuth = false;

      localStorage.clear();
    },
  },
});

export const { logout, login, signup, updateUser, deleteUser, setUser } =
  authSlice.actions;

export default authSlice.reducer;
