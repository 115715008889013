import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { getErrorMessage } from "../functions";
import { setCompanyDetails } from "../redux/companyDetailsSlice";
import { setCustomers } from "../redux/customerSlice";
import { setDealers } from "../redux/dealerSlice";
import { setProducts } from "../redux/productSlice";
import { setItems } from "../redux/qrSettingsSlice";
import { getCompanyDetails } from "../services/companyDetailsApi";
import { getAllCustomers } from "../services/customerApi";
import { getAllDealers } from "../services/dealerApi";
import { getAllProducts } from "../services/productApi";
import { getQrSettings } from "../services/qrSettingsApi";
import Loader from "../UI/Loader";
import Sidebar from "../UI/Sidebar";
import { ROLES } from "../constant";
import { getUser } from "../services/userApi";
import { setUser } from "../redux/authSlice";

const Dashboard = () => {
  const { auth } = useSelector((state) => state);
  const { isAuth } = auth;
  const { user } = auth;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const fetchDealers = async () => {
    try {
      setLoading(true);
      const res = await getAllDealers();
      dispatch(setDealers(res.data));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const res = await getAllCustomers();
      dispatch(setCustomers(res.data));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const res = await getAllProducts();
      dispatch(setProducts(res.data));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      setLoading(true);
      const res = await getCompanyDetails();
      dispatch(setCompanyDetails(res.data));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const fetchQRSettings = async () => {
    try {
      setLoading(true);
      const res = await getQrSettings();
      dispatch(setItems(res?.data?.fields || []));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchUser = async () => {
    try {
      setLoading(true);
      const res = await getUser(user?._id);
      dispatch(
        setUser({
          user: res.data,
        })
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuth) {
      const fetchAllPromise = async () => {
        await Promise.all([
          fetchCompanyDetails(),
          fetchCustomers(),
          fetchProducts(),
          fetchDealers(),
          fetchQRSettings(),
          fetchUser(),
        ]);
      };
      fetchAllPromise();
    }
  }, []);
  const navigate = useNavigate();
  if (!isAuth) navigate("/login");
  if (!user?.isAccountVerified && user?.role !== ROLES.SUPER_ADMIN) {
    navigate("/account-not-verified");
  }
  if (loading) return <Loader />;
  return <Sidebar />;
};

export default Dashboard;
