import React, { useState } from "react";
import { Button } from "@chakra-ui/react";

import Loader from "../../../UI/Loader";

import InputField from "../../../UI/InputField";
import { State } from "country-state-city";
import { useSelector } from "react-redux";

import SelectField from "../../../UI/SelectField";
import ItemList from "./ItemList";
import { toast } from "sonner";
import { addInvoice } from "../../../services/invoiceApi";
import { getErrorMessage, getSuccessMessage } from "../../../functions";

import Gap from "../../../UI/Gap";

const EditInvoice = ({ invoice, toggleRefresh, setIsEdit }) => {
  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(invoice?.invoiceNumber);
  const [eWayBillNo, setEWayBillNo] = useState(invoice?.eWayBillNo);
  const [vehicle, setVehicle] = useState(invoice.vehicle);
  const [transport, setTransport] = useState(invoice.transport);
  const [station, setStation] = useState(invoice?.station);
  const [billType, setBillType] = useState(invoice?.billType);
  const [billTo, setBillTo] = useState(invoice?.billTo);
  const [items, setItems] = useState(invoice?.items);
  const [stateOfSupply, setStateOfSupply] = useState(invoice?.stateOfSupply);
  const [billToId, setBillToId] = useState(invoice?.customerDetails?._id);
  const products = useSelector((state) => state.product.products);
  const dealers = useSelector((state) => state.dealer.dealers);
  const customers = useSelector((state) => state.customer.customers);
  const States = State.getStatesOfCountry("IN");

  const handleProductSelect = ({ value }) => {
    const isProductInItems = items?.some((item) => item._id === value._id);
    if (!isProductInItems) {
      setItems([
        ...items,
        {
          ...value,
          quantity: 1,
        },
      ]);
    }
  };
  const handleDeleteItem = (productId) => {
    const updatedItems = items?.filter((item) => item._id !== productId);
    setItems(updatedItems);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (items.length < 1) {
        return toast.error("Please add atleast 1 item");
      }
      if (!invoiceNumber) {
        return toast.error("Please enter invoice number");
      }
      if (!billType) {
        return toast.error("Please select invoice type");
      }
      if (!billTo) {
        return toast.error("Please select whom to bill");
      }
      if (!billToId) {
        return toast.error("Please select a customer or dealer");
      }
      if (!stateOfSupply) {
        return toast.error("Please select a state");
      }
      const res = await addInvoice({
        isEdit: true,
        _id: invoice?._id,
        items,
        invoiceNumber,
        billType,
        billTo,
        billToId,
        stateOfSupply,
        eWayBillNo,
        vehicle,
        station,
        transport,
      });
      toast.success(getSuccessMessage(res));
      toggleRefresh();
      setIsEdit(false);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  
  return (
    <div >
      <Gap>Edit Invoice</Gap>
      <div className="flex lg:flex-wrap lg:flex-row gap-4 flex-col">
        <InputField
          labelName="Invoice Number"
          type="text"
          required
          uni="invoiceNumber"
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
        />
        <InputField
          labelName="E Way Bill Number"
          type="text"
          uni="eWayBillNo"
          value={eWayBillNo}
          onChange={(e) => setEWayBillNo(e.target.value)}
        />
        <InputField
          labelName="Vehicle"
          type="text"
          uni="vehicle"
          value={vehicle}
          onChange={(e) => setVehicle(e.target.value)}
        />
        <InputField
          labelName="Transport"
          type="text"
          uni="transport"
          value={transport}
          onChange={(e) => setTransport(e.target.value)}
        />
        <InputField
          labelName="Station"
          type="text"
          uni="station"
          value={station}
          onChange={(e) => setStation(e.target.value)}
        />
        <div className="lg:w-1/6">
          <SelectField
            labelName={"State Of Supply"}
            inputClass={"w-fit"}
            options={States}
            value={{
              label: stateOfSupply,
              value: stateOfSupply,
            }}
            onChange={({ value }) => {
              setStateOfSupply(value.name);
            }}
          />
        </div>
        <div className="lg:w-1/6">
          <SelectField
            labelName={"Invoice Type"}
            inputClass={"w-fit"}
            options={[
              { name: "Tax Invoice", value: "Tax Invoice" },
              {
                name: "Sell Invoice",
                value: "Sell Invoice",
              },
            ]}
            value={{
              label: billType,
              value: billType,
            }}
            onChange={({ value }) => {
              setBillType(value.value);
            }}
          />
        </div>
        <div className="lg:w-1/6">
          <SelectField
            labelName={"Bill To"}
            inputClass={"w-fit"}
            value={{
              label: billTo,
              value: billTo,
            }}
            options={[
              { name: "Dealer", value: "Dealer" },
              {
                name: "Customer",
                value: "Customer",
              },
            ]}
            onChange={({ value }) => {
              setBillTo(value.value);
            }}
          />
        </div>
        <div className="lg:w-1/6">
          {billTo === "Customer" ? (
            <SelectField
              labelName={"Customers"}
              options={customers}
              value={{
                label: customers.find((customer) => customer._id === billToId)
                  ?.name,
                value: billToId?._id,
              }}
              onChange={({ value }) => setBillToId(value?._id)}
            />
          ) : (
            <SelectField
              labelName={"Dealers"}
              options={dealers}
              value={{
                label: dealers.find((dealer) => dealer._id === billToId)?.name,
                value: billToId?._id,
              }}
              onChange={({ value }) => setBillToId(value?._id)}
            />
          )}
        </div>
        <div className="lg:w-1/6">
          <SelectField
            labelName={"Products"}
            options={products?.filter(
              (product) => !items?.some((item) => item._id === product._id)
            )}
            onChange={(value) => handleProductSelect(value)}
          />
        </div>
      </div>
      <div>
        <ItemList
          products={products}
          items={items}
          handleProductSelect={handleProductSelect}
          handleDeleteItem={handleDeleteItem}
          setItems={setItems}
        />
      </div>

      <Button
rounded={"none"}
        type="submit"
        variant="solid"
        colorScheme="blue"
        size="md"
        className="mt-6"
        onClick={handleSubmit}
      >
        Update
      </Button>
    </div>
  );
};
export default EditInvoice;
