import React from "react";
import Select from "react-select";
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "0",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0",
  }),
  option: (provided) => ({
    ...provided,
    borderRadius: "0",
  }),
};
const SelectField = ({
  labelName,
  name,
  uni,

  onChange,
  value,
  touched,
  error,
  menuPortalTarget,
  menuPosition,
  options,
  selectStyles,
}) => {
  return (
    <div className="flex w-full">
      <div className="col-span-1 flex flex-col w-full">
        <label className=" text-secondary font-bold" htmlFor={uni}>
          {labelName}
        </label>
        <Select
          className="w-full rounded-none"
          options={options?.map((item) => {
            return {
              value: item,
              label: item.name,
            };
          })}
          name={name}
          menuPortalTarget={menuPortalTarget}
          menuPosition={menuPosition}
          styles={{ ...selectStyles, ...customStyles }}
          onChange={onChange}
          value={value}
        />
      </div>
      {touched && error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default SelectField;
