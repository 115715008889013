import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import { toast } from "sonner";

import { PasswordField } from "./PasswordField";
import { useDispatch } from "react-redux";
import { login as loginReducer } from "../../redux/authSlice";
import Loader from "../../UI/Loader";
import { register } from "../../services/userApi";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import imageConstant from "../../imageConstant";
import { Logo } from "../Login/Logo";
export const SignUp = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const { isAuth } = auth;
  if (isAuth) window.location.href = "/dashboard";
  const [loading, setLoading] = useState(false); // [1
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState({
    firstName: "",
    lastName: "",
  });
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSignUp = async () => {
    try {
      if (
        !name.firstName ||
        !name.lastName ||
        !email ||
        !password ||
        !confirmPassword ||
        !phone
      )
        return toast.info("Please fill all fields");
      //password length should be greater than 6
      if (password.length < 6)
        return toast.info("Password length should be greater than 6");

      if (password !== confirmPassword)
        return toast.info("Password and confirm password should be same");
      //check whether email is valid or not
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email))
        return toast.info("Please enter valid email address");
      //if phone contain + then toast
      if (phone.includes("+"))
        return toast.info("Please enter phone without(+91) country code");
      //check whether phone number is valid or not
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(phone))
        return toast.info("Please enter valid phone number");

      setLoading(true);
      const res = await register({
        firstName: name.firstName,
        lastName: name.lastName,
        email,
        password,

        phoneNumber: phone,
        confirmPassword,
      });
      dispatch(
        loginReducer({
          isAuth: true,
          isAdmin: res.data.isAdmin,
          token: res.data.token,
          user: res.data.user,
          classes: res.data.classes,
        })
      );
      toast.success(getSuccessMessage(res));
      navigate("/login");
      setLoading(false);
    } catch (error) {
      toast.error(getErrorMessage(error));
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  
  return (
    <div className="flex">
      <div className="w-1/2 h-screen hidden lg:flex  flex-col    justify-center items-center text-center  object-cover">
        <img
          src={imageConstant.login}
          className="w-1/2   object-cover fixed"
          alt="login"
        />
      </div>
      <Container
        maxW="lg"
        px={{
          base: "0",
          sm: "8",
        }}
      >
        <Stack spacing="2">
          <Stack spacing="4">
            <div className="flex justify-center mt-10">
              <Logo />
            </div>
            <Stack textAlign="center">
              <Heading
                size={{
                  base: "xs",
                  md: "sm",
                }}
              >
                Sign Up
              </Heading>
              {/* <HStack spacing="1" justify="center">
              <Text color="muted">Don't have an account?</Text>
              <Button
rounded={"none"} variant="link" colorScheme="blue">
                Sign up
              </Button>
            </HStack> */}
            </Stack>
          </Stack>
          <Box
            py={{
              base: "0",
              sm: "8",
            }}
            px={{
              base: "4",
              sm: "10",
            }}
            bg={{
              base: "transparent",
              sm: "bg-surface",
            }}
            boxShadow={{
              base: "none",
              sm: "md",
            }}
            borderRadius={{
              base: "none",
              sm: "xl",
            }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input
                    id="firstName"
                    type="text"
                    value={name.firstName}
                    name="firstName"
                    placeholder="Name"
                    onChange={(e) =>
                      setName({
                        ...name,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input
                    id="name"
                    type="text"
                    value={name.lastName}
                    name="lastName"
                    placeholder="Name"
                    onChange={(e) =>
                      setName({
                        ...name,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <PasswordField
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <PasswordField
                  label="Confirm Password"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <FormControl>
                  <FormLabel htmlFor="phone">Phone</FormLabel>
                  <Input
                    id="phone"
                    type="text"
                    value={phone}
                    placeholder="Phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </FormControl>
              </Stack>
              <HStack justify="space-between"></HStack>
              <Stack spacing="6">
                <Button
rounded={"none"}
                  variant="primary"
                  className="bg-primary text-white"
                  onClick={handleSignUp}
                >
                  Sign Up
                </Button>

                <Button
rounded={"none"}
                  variant="link"
                  colorScheme="blue"
                  size="sm"
                  onClick={() => navigate("/login")}
                >
                  Already have an account? Login here
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </div>
  );
};

export default SignUp;
