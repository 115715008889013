import http from "./http";

const cncLedgerApi = "/api/cnc/ledger";

export const addLedgerDetails = (data) => {
  return http.post(`${cncLedgerApi}/create`, data);
};

export const getAllLedgerDetails = (data) => {
  return http.get(`${cncLedgerApi}/all`, data);
};

export const deleteLedgerDetails = (id) => {
  return http.delete(`${cncLedgerApi}/delete/${id}`);
};
