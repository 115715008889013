import { ChakraProvider } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import PAGES from "./constant";
import useScreenWidth from "./hooks/useScreenWidth";
import { login } from "./redux/authSlice";
import { setMobileView } from "./redux/mobileViewSlice";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";

const App = () => {
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state);
  const { isAuth } = auth;

  const screenWidth = useScreenWidth();

  useEffect(() => {
    dispatch(setMobileView(screenWidth));
  }, [screenWidth]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : null;
    // if(!user?.isAccountVerified)
    const isAuth = localStorage.getItem("isAuth");

    if (token && user && isAuth) {
      dispatch(
        login({
          token,
          user: user,
          isAuth: isAuth,
        })
      );
    }
  }, []);

  return (
    <ChakraProvider>
      <Routes>
        {PAGES.map((page) => {
          if (page.isAuth && !isAuth)
            return <Route key={404} path="*" element={<Login />} />;
          return (
            <Route
              key={page.path}
              path={page.path}
              element={<page.component />}
            />
          );
        })}
      </Routes>
    </ChakraProvider>
  );
};

export default App;
