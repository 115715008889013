import QrCardTable from "./QrCardTable";
export default function TableCard({
  item,
  handleDeleteQrData,
  handleSelectQR,
  className,
  isSelected,
  toggleSelected,
}) {
  return (
    <div
      className={`${className}  table-card ${isSelected ? "bg-pink-800" : ""}`}
      onClick={() => toggleSelected(item)}
    >
      <div className="bg-white  shadow-md overflow-hidden">
        {item.data && (
          <QrCardTable
            key={item._id}
            data={item.data}
            isSelected={isSelected}
            deleteQrData={() => handleDeleteQrData(item._id)}
            handleSelectQR={() => handleSelectQR(item)}
            itemId={item._id}
          />
        )}
      </div>
    </div>
  );
}
