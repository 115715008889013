import http from "./http";

const userWithoutValidationApi = "/api/no-validation/user";

export const addParty = (data) => {
  return http.post(`${userWithoutValidationApi}/create`, data);
};

export const updateParty = (data) => {
  return http.post(`${userWithoutValidationApi}/update`, data);
};

export const getAllParties = () => {
  return http.get(`${userWithoutValidationApi}/get`);
};

export const getPartyById = (id) => {
  return http.get(`${userWithoutValidationApi}/get/${id}`);
};

export const deletePartyById = (id) => {
  return http.delete(`${userWithoutValidationApi}/delete/${id}`);
};
