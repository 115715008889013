import React from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { invoicePDFTemplate } from "../../../functions";

const LivePreview = ({ invoice }) => {
  const {
    billType,
    vehicle,
    companyDetails,
    transport,
    station,
    customerDetails,
    invoiceNumber,
    stateOfSupply,
    eWayBillNo,
    billToId,
    items,
    // isTaxIncluded,
  } = invoice;
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);
  if (isMobileView) {
    toast.error("Preview is not available on mobile view");
    return null;
  }
  return (
    <div className="lg:flex flex-col hidden">
      <div
        className="mt-2"
        dangerouslySetInnerHTML={{
          __html: invoicePDFTemplate(
            {
              billType,
              vehicle,
              companyDetails,
              transport,
              station,
              customerDetails,
              invoiceNumber,
              stateOfSupply,
              eWayBillNo,
              billToId,
              items,
              isPreview: true,
            },
            ""
          ),
        }}
      />
    </div>
  );
};

export default LivePreview;
