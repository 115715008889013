import moment from "moment";
import { ToWords } from "to-words";

export const getErrorMessage = (err) => {
  return err?.response?.data?.message || err.message;
};

export const getSuccessMessage = (res) => {
  return res?.message || "Success";
};

export const converter = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      name: "Rupee",
      plural: "Rupees",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

function groupItemsByHsnCode(items) {
  return items?.reduce((acc, item) => {
    const existingItem = acc.find((i) => i.hsnCode === item.hsnCode);
    if (existingItem) {
      existingItem.amountBeforeTax += item.amountBeforeTax;
      existingItem.cgstAmount += item.cgstAmount;
      existingItem.sgstAmount += item.sgstAmount;
    } else {
      acc.push({
        hsnCode: item.hsnCode,
        sgst: item.sgst,
        cgst: item.cgst,
        amountBeforeTax: item.amountBeforeTax,
        cgstAmount: item.cgstAmount,
        sgstAmount: item.sgstAmount,
      });
    }
    return acc;
  }, []);
}

export const invoicePDFTemplate = (invoice, copyType) => {
  if (!invoice) return;
  const {
    companyDetails,
    invoiceNumber,
    invoiceDate,
    stateOfSupply,
    eWayBillNo = "",
    customerDetails,
    items,
    transport,
    vehicle,
    station,
    isPreview = false,
  } = invoice;

  const {
    address = "",
    phoneNumber = "",
    gst = "",
    name = "",
  } = customerDetails ?? {};
  const formattedBillingDate = new Date(invoiceDate).toLocaleDateString();

  let totalAmountBeforeTax = 0;
  let totalSgstAmount = 0;
  let totalCgstAmount = 0;
  let totalGrandTotal = 0;

  items?.forEach((item) => {
    item.amountBeforeTax = +item.quantity * +item.price;
    item.sgstAmount = +item.amountBeforeTax * (item.sgst / 100);
    item.cgstAmount = +item.amountBeforeTax * (item.cgst / 100);
    item.grandTotal =
      +item.amountBeforeTax * (+item.cgst / 100) +
      +item.amountBeforeTax * (+item.sgst / 100) +
      +item.amountBeforeTax;
    totalAmountBeforeTax += +item.amountBeforeTax;
    totalSgstAmount += +item.sgstAmount;
    totalCgstAmount += +item.cgstAmount;
    totalGrandTotal += +item.grandTotal;
  });
  const groupedItems = groupItemsByHsnCode(items);

  const nonPreviewStyles = isPreview
    ? ""
    : `
    html, body {
      margin: 0;
      padding: 0;
    }
    body {
      font-family: 'Roboto', sans-serif;
      padding: 0px;
    }
  `;

  return `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${name}</title>
        <style>
        .pdf-flex {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
  
        flex-direction: column;
    }
  
    .box-parent {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
  
        flex-direction: column;
    }
  
    .pdf-footer {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        width: 95%;
    }
    ${nonPreviewStyles}
        
            .container {
                border: 1px solid orange;
                padding: 10px;
               
                background-color: #f8f8f8;
            }
            .header {
              display: -webkit-box;
  
                align-items: center;
                font-size: 14px;
                width:100%;
                margin-bottom: 20px;
  
            }
  
            .header p {
                margin: 0;
            }
  
            .stamp {
                text-align: center;
                margin-bottom: 20px;
                font-size: 18px;
            }
  
            .stamp .para {
                font-weight:500;
                margin: 5px 0;
            }
  
            .company_name {
                color: red;
                font-size: 18px;
                margin-bottom: 10px;
            }
  
            .address {
                font-size: 14px;
                margin-bottom: 5px;
            }
  
            .box-parent {
                justify-content: space-between;
                margin-bottom: 20px;
            }
  
            .box-div {
                border: 1px solid black;
                width: 50%;
            }
            .list {
                padding: 10px;
                font-size: 14px;
  
  
                flex-direction: column;
            }
  
            .list i {
                font-style: italic;
                margin-bottom: 5px;
            }
  
            .list p {
                margin: 5px 0;
            }
  
            .table_container {
                text-align: center;
                margin-top: 16px;
            }
  
            table {
                font-family: Arial, Helvetica, sans-serif;
                border-collapse: collapse;
                width: 100%;
                font-size: 14px;
            }
  
            table th, table td {
                border: 1px solid #ddd;
                padding: 8px;
            }
  
            table th {
                background-color: #f2f2f2;
            }
  
            table tr:nth-child(even) {
                background-color: #f2f2f2;
            }
  
            table tr:hover {
                background-color: #ddd;
            }
            .bank_container
            {
                font-weight: 500;
                font-size: 14px;
            }
            .terms{
                border:1px solid black;
              padding: 10px;
                width:50%;
            }
            .terms_heading{
                text-decoration: underline;
                font-weight: 500;
                font-size: 14px;
            }
            .for_heading{
                font-weight: 500;
                font-size: 14px;
            }
    
            .signature_div{
                height: 20%;
                border-bottom: 1px solid black;
            }
            .disclaimer{
                border: 1px solid black;
                text-align: right;
                font-size: 14px;
                width: 95%;
                padding-right: 4px;
            }
            p{
                font-size: 14px;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="header">
                <p>GSTIN: <b>${companyDetails?.gst || ""}</b> </p>
                <p style="margin: auto;">${copyType}</p>
            </div>
            <hr>
            <div class="stamp">
                <p class="para">${invoice?.billType?.toUpperCase() || ""}</p>
                <p class="company_name para">${
                  companyDetails?.businessName || ""
                }</p>
                <p class="address">${companyDetails?.address || ""}</p>
                <p class="address">Mobile Number : ${
                  companyDetails?.phoneNumber || ""
                }</p>
                <p class="address">Email: ${companyDetails?.email || ""}</p>
            </div>
            <hr>
            <div class="box-parent">
                <div class="box-div pdf-flex">
                    <div class="list">
                        <p>Invoice No :</p>
                        <p>Date of Invoice :</p>
                        <p>State of Supply :</p>
                    </div>
                    <div class="list">
                        <p>${invoiceNumber || ""}</p>
                        <p>${
                          formattedBillingDate === "Invalid Date"
                            ? moment().format("DD/MM/YYYY")
                            : moment(formattedBillingDate).format("DD/MM/YYYY")
                        }</p>
                        <p>${stateOfSupply || ""}</p>
                    </div>
                </div>
                <div class="box-div pdf-flex">
                    <div class="list">
                        <p>Transport :</p>
                        <p>Vehicle :</p>
                        <p>Station :</p>
                        <p>E-Way Bill No :</p>
                    </div>
                    <div class="list">
                        <p>${transport || ""}</p>
                        <p>${vehicle || ""}</p>
                        <p>${station || ""}</p>
                        <p>${eWayBillNo || ""}</p>
                    </div>
                </div>
            </div>
            <div class="box-parent">
                <div class="box-div">
                    <div class="list">
                        <i>Billed to :</i>
                        <p>${name || ""}</p>
                        <p>${address || ""}</p>
                        <p>Mob : ${phoneNumber || ""}</p>
                        <p>GSTIN / UIN : ${gst || ""}</p>
                    </div>
                </div>
                <div class="box-div">
                    <div class="list">
                        <i>Shipped to :</i>
                        <p>${name}</p>
                        <p>${address}</p>
                        <p>Mob : ${phoneNumber}</p>
                        <p>GSTIN / UIN : ${gst}</p>
                    </div>
                </div>
            </div>
            <div class="table_container">
                <table>
                    <thead>
                        <tr>
                            <td>S.No</td>
                            <td>Particulars</td>
                            <td>HSN Code</td>
                            <td style="width: 10px;">Warranty (in months)</td>
                            <td>Qty.</td>
                            <td>Rate</td>
                            <td>Amount</td>
                            <td colspan="2">SGST Amount</td>
                            <td colspan="2">GGST Amount</td>
                            <td>Grand Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        ${
                          items
                            ?.map(
                              (item, index) => `
                            <tr>
                                <td>${index + 1}</td>
                                <td>${item.name}</td>
                                <td>${item.hsnCode}</td>
                                <td>${item.warranty || 0}</td>
                                <td>${+item.quantity || 0}</td>
                                <td>${
                                  parseFloat(item.price).toFixed(2) || 0
                                }</td>
                                <td>${
                                  +item.amountBeforeTax.toFixed(2) || 0
                                }</td>
                                <td>${+item.sgst || 0}</td>
                                <td>${+item.sgstAmount.toFixed(2)}</td>
                                <td>${+item.cgst || 0}</td>
                                <td>${+item.cgstAmount.toFixed(2) || 0}</td>
                                <td>${+item.grandTotal.toFixed(2) || 0}</td>
                            </tr>
                        `
                            )
                            .join("") || ""
                        }
                        <tr>
                            <td colspan="6">Total</td>
                            <td>${totalAmountBeforeTax.toFixed(2)}</td>
                            <td colspan="2">${totalSgstAmount.toFixed(2)}</td>
                            <td colspan="2">${totalCgstAmount.toFixed(2)}</td>
                            <td>${totalGrandTotal.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_container" id="2">
                <table>
                    <thead>
                        <tr>
                            <td>HSN/SAC</td>
                            <td>Tax Rate</td>
                            <td>Taxable Amount</td>
                            <td style="width: 10px;">CGST Amount</td>
                            <td>SGST Amount</td>
                            <td>Total tax</td>
                        </tr>
                    </thead>
                    <tbody>
                    ${
                      groupedItems
                        ?.map(
                          (item, index) => `
                    <tr key=${index}>
                        <td>${item.hsnCode}</td>
                        <td>${(item.sgst + item.cgst).toFixed(2)}</td>
                        <td>${item.amountBeforeTax.toFixed(2)}</td>
                        <td>${item.sgstAmount.toFixed(2)}</td>
                        <td>${item.cgstAmount.toFixed(2)}</td>
                        <td>${(item.cgstAmount + item.sgstAmount).toFixed(
                          2
                        )}</td>
                    </tr>
                `
                        )
                        .join("") || ""
                    }
                        <tr>
                            <td>Totals</td>
                            <td></td>
                            <td>${totalAmountBeforeTax.toFixed(2)}</td>
                            <td>${totalCgstAmount.toFixed(2)}</td>
                            <td>${totalSgstAmount.toFixed(2)}</td>
                            <td>${totalGrandTotal.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h5>${converter.convert(totalGrandTotal)}</h5>
            <div class="bank_container">
                <p>Bank Name : ${companyDetails?.bankName}</p>
                <p>IFSC Code : ${companyDetails?.ifscCode}</p>
                <p>Account Number : ${companyDetails?.bankAccountNumber}</p>
                <p>Branch : ${companyDetails?.bankBranch}</p>
            </div>
            <div class="pdf-footer">
                <div class="terms">
                    <h5 class="terms_heading">Terms & Conditions</h5>
                    <p>E. & E.O.</p>
                    <p>1) Goods once sold will not be taken back</p>
                    <p>2) Interest @18% p.a. will be charged if the payment is not made within the stipulated time.</p>
                    <p>3) Subject to '${
                      companyDetails?.jurisdiction
                    }' Jurisdiction only.</p>
                </div>
                <div class="terms">
                    <div class="signature_div">
                        <h5 class="terms_heading">Receiver's Signature</h5>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                    </div>
                    <div class="">
                        <h5 class="for_heading">for ${
                          companyDetails?.businessName
                        }</h5>
                        <div style="height: 60px;"></div>
                        <p class="for_heading">Authorised Signatory</p>
                    </div>
                </div>
            </div>
            <div class="pdf-footer">
                <p>**this is computer generated invoice no signature required**</p>
            </div>
        </div>
    </body>
    </html>`;
};

function newGroupItemsByHsnCode(items) {
  return items?.reduce((acc, item) => {
    const existingItem = acc.find((i) => i.hsnCode === item.hsnCode);
    if (existingItem) {
      existingItem.amountBeforeTax += item.grandTotal;
      existingItem.cgstAmount += item.cgstAmount;
      existingItem.sgstAmount += item.sgstAmount;
    } else {
      acc.push({
        hsnCode: item.hsnCode,
        sgst: item.sgst,
        cgst: item.cgst,
        amountBeforeTax: item.grandTotal,
        cgstAmount: item.cgstAmount,
        sgstAmount: item.sgstAmount,
      });
    }
    return acc;
  }, []);
}

export const newInvoicePDFTemplate = (invoice, copyType) => {
  if (!invoice) return;
  const {
    companyDetails,
    invoiceNumber,
    invoiceDate,
    stateOfSupply,
    eWayBillNo = "",
    customerDetails,
    items,
    transport,
    vehicle,
    station,
    isPreview = false,
  } = invoice;

  const {
    address = "",
    phoneNumber = "",
    gst = "",
    name = "",
  } = customerDetails ?? {};
  const formattedBillingDate = new Date(invoiceDate).toLocaleDateString();

  let totalAmountBeforeTax = 0;
  let totalSgstAmount = 0;
  let totalCgstAmount = 0;
  let totalGrandTotal = 0;

  items?.forEach((item) => {
    const totalTaxPercentage = +item.sgst + +item.cgst;

    // Calculate the tax amount included in the price
    const taxAmount = (totalTaxPercentage / 100) * +item.price;

    // Calculate the amount before tax
    item.amountBeforeTax = +item.price - taxAmount;
    item.sgstAmount = +item.quantity * ((item.price * +item.sgst) / 100);
    item.cgstAmount = +item.quantity * ((item.price * +item.cgst) / 100);
    item.grandTotal = +item.quantity * +item.price;
    item.totalAmountBeforeTax = +item.quantity * +item.price;
    totalAmountBeforeTax += +item.amountBeforeTax * +item.quantity;
    totalSgstAmount += +item.sgstAmount;
    totalCgstAmount += +item.cgstAmount;
    totalGrandTotal += +item.grandTotal;
  });
  const groupedItems = newGroupItemsByHsnCode(items);

  const nonPreviewStyles = isPreview
    ? ""
    : `
  html, body {
    margin: 0;
    padding: 0;
  }
  body {
    font-family: 'Roboto', sans-serif;
    padding: 0px;
  }
`;

  return `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${name}</title>
      <style>
      .pdf-flex {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;

      flex-direction: column;
  }

  .box-parent {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;

      flex-direction: column;
  }

  .pdf-footer {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      width: 95%;
  }
  ${nonPreviewStyles}

          .container {
              border: 1px solid orange;
              padding: 10px;

              background-color: #f8f8f8;
          }
          .header {
            display: -webkit-box;

              align-items: center;
              font-size: 14px;
              width:100%;
              margin-bottom: 20px;

          }

          .header p {
              margin: 0;
          }

          .stamp {
              text-align: center;
              margin-bottom: 20px;
              font-size: 18px;
          }

          .stamp .para {
              font-weight:500;
              margin: 5px 0;
          }

          .company_name {
              color: red;
              font-size: 18px;
              margin-bottom: 10px;
          }

          .address {
              font-size: 14px;
              margin-bottom: 5px;
          }

          .box-parent {
              justify-content: space-between;
              margin-bottom: 20px;
          }

          .box-div {
              border: 1px solid black;
              width: 50%;
          }
          .list {
              padding: 10px;
              font-size: 14px;

              flex-direction: column;
          }

          .list i {
              font-style: italic;
              margin-bottom: 5px;
          }

          .list p {
              margin: 5px 0;
          }

          .table_container {
              text-align: center;
              margin-top: 16px;
          }

          table {
              font-family: Arial, Helvetica, sans-serif;
              border-collapse: collapse;
              width: 100%;
              font-size: 14px;
          }

          table th, table td {
              border: 1px solid #ddd;
              padding: 8px;
          }

          table th {
              background-color: #f2f2f2;
          }

          table tr:nth-child(even) {
              background-color: #f2f2f2;
          }

          table tr:hover {
              background-color: #ddd;
          }
          .bank_container
          {
              font-weight: 500;
              font-size: 14px;
          }
          .terms{
              border:1px solid black;
            padding: 10px;
              width:50%;
          }
          .terms_heading{
              text-decoration: underline;
              font-weight: 500;
              font-size: 14px;
          }
          .for_heading{
              font-weight: 500;
              font-size: 14px;
          }

          .signature_div{
              height: 20%;
              border-bottom: 1px solid black;
          }
          .disclaimer{
              border: 1px solid black;
              text-align: right;
              font-size: 14px;
              width: 95%;
              padding-right: 4px;
          }
          p{
              font-size: 14px;
          }
      </style>
  </head>
  <body>
      <div class="container">
          <div class="header">
              <p>GSTIN: <b>${companyDetails?.gst || ""}</b> </p>
              <p style="margin: auto;">${copyType}</p>
          </div>
          <hr>
          <div class="stamp">
              <p class="para">${invoice?.billType?.toUpperCase() || ""}</p>
              <p class="company_name para">${
                companyDetails?.businessName || ""
              }</p>
              <p class="address">${companyDetails?.address || ""}</p>
              <p class="address">Mobile Number : ${
                companyDetails?.phoneNumber || ""
              }</p>
              <p class="address">Email: ${companyDetails?.email || ""}</p>
          </div>
          <hr>
          <div class="box-parent">
              <div class="box-div pdf-flex">
                  <div class="list">
                      <p>Invoice No :</p>
                      <p>Date of Invoice :</p>
                      <p>State of Supply :</p>
                  </div>
                  <div class="list">
                      <p>${invoiceNumber || ""}</p>
                      <p>${
                        formattedBillingDate === "Invalid Date"
                          ? new Date().toLocaleDateString()
                          : formattedBillingDate
                      }</p>
                      <p>${stateOfSupply || ""}</p>
                  </div>
              </div>
              <div class="box-div pdf-flex">
                  <div class="list">
                      <p>Transport :</p>
                      <p>Vehicle :</p>
                      <p>Station :</p>
                      <p>E-Way Bill No :</p>
                  </div>
                  <div class="list">
                      <p>${transport || ""}</p>
                      <p>${vehicle || ""}</p>
                      <p>${station || ""}</p>
                      <p>${eWayBillNo || ""}</p>
                  </div>
              </div>
          </div>
          <div class="box-parent">
              <div class="box-div">
                  <div class="list">
                      <i>Billed to :</i>
                      <p>${name || ""}</p>
                      <p>${address || ""}</p>
                      <p>Mob : ${phoneNumber || ""}</p>
                      <p>GSTIN / UIN : ${gst || ""}</p>
                  </div>
              </div>
              <div class="box-div">
                  <div class="list">
                      <i>Shipped to :</i>
                      <p>${name}</p>
                      <p>${address}</p>
                      <p>Mob : ${phoneNumber}</p>
                      <p>GSTIN / UIN : ${gst}</p>
                  </div>
              </div>
          </div>
          <div class="table_container">
              <table>
                  <thead>
                      <tr>
                          <td>S.No</td>
                          <td>Particulars</td>
                          <td>HSN Code</td>
                          <td style="width: 10px;">Warranty (in months)</td>
                          <td>Qty.</td>
                          <td>Rate</td>
                          <td>Amount</td>
                          <td colspan="2">SGST Amount</td>
                          <td colspan="2">GGST Amount</td>
                          <td>Grand Total</td>
                      </tr>
                  </thead>
                  <tbody>
                      ${
                        items
                          ?.map(
                            (item, index) => `
                          <tr>
                              <td>${index + 1}</td>
                              <td>${item.name}</td>
                              <td>${item.hsnCode}</td>
                              <td>${item.warranty || 0}</td>
                              <td>${+item.quantity || 0}</td>
                              <td>${parseFloat(item.price).toFixed(2) || 0}</td>
                              <td>${+item.grandTotal.toFixed(2) || 0}</td>
                              <td>${+item.sgst || 0}%</td>
                              <td>${+item.sgstAmount.toFixed(2)}</td>
                              <td>${+item.cgst || 0}%</td>
                              <td>${+item.cgstAmount.toFixed(2) || 0}</td>
                              <td>${+item.grandTotal.toFixed(2) || 0}</td>
                          </tr>
                      `
                          )
                          .join("") || ""
                      }
                      <tr>
                          <td colspan="6">Total</td>
                          <td>${totalAmountBeforeTax.toFixed(2)}</td>
                          <td colspan="2">${totalSgstAmount.toFixed(2)}</td>
                          <td colspan="2">${totalCgstAmount.toFixed(2)}</td>
                          <td>${totalGrandTotal.toFixed(2)}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="table_container" id="2">
              <table>
                  <thead>
                      <tr>
                          <td>HSN/SAC</td>
                          <td>Tax Rate</td>
                          <td>Taxable Amount</td>
                          <td style="width: 10px;">CGST Amount</td>
                          <td>SGST Amount</td>
                          <td>Total tax</td>
                      </tr>
                  </thead>
                  <tbody>
                  ${
                    groupedItems
                      ?.map(
                        (item, index) => `
                  <tr key=${index}>
                      <td>${item.hsnCode}</td>
                      <td>${(item.sgst + item.cgst).toFixed(2)}</td>
                      <td>${item.amountBeforeTax.toFixed(2)}</td>
                      <td>${item.sgstAmount.toFixed(2)}</td>
                      <td>${item.cgstAmount.toFixed(2)}</td>
                      <td>${(item.cgstAmount + item.sgstAmount).toFixed(2)}</td>
                  </tr>
              `
                      )
                      .join("") || ""
                  }
                      <tr>
                          <td>Totals</td>
                          <td></td>
                          <td>${totalAmountBeforeTax.toFixed(2)}</td>
                          <td>${totalCgstAmount.toFixed(2)}</td>
                          <td>${totalSgstAmount.toFixed(2)}</td>
                          <td>${totalGrandTotal.toFixed(2)}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <h5>${converter.convert(totalGrandTotal)}</h5>
          <div class="bank_container">
              <p>Bank Name : ${companyDetails?.bankName}</p>
              <p>IFSC Code : ${companyDetails?.ifscCode}</p>
              <p>Account Number : ${companyDetails?.bankAccountNumber}</p>
              <p>Branch : ${companyDetails?.bankBranch}</p>
          </div>
          <div class="pdf-footer">
              <div class="terms">
                  <h5 class="terms_heading">Terms & Conditions</h5>
                  <p>E. & E.O.</p>
                  <p>1) Goods once sold will not be taken back</p>
                  <p>2) Interest @18% p.a. will be charged if the payment is not made within the stipulated time.</p>
                  <p>3) Subject to '${
                    companyDetails?.jurisdiction
                  }' Jurisdiction only.</p>
              </div>
              <div class="terms">
                  <div class="signature_div">
                      <h5 class="terms_heading">Receiver's Signature</h5>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                  </div>
                  <div class="">
                      <h5 class="for_heading">for ${
                        companyDetails?.businessName
                      }</h5>
                      <div style="height: 60px;"></div>
                      <p class="for_heading">Authorised Signatory</p>
                  </div>
              </div>
          </div>
          <div class="pdf-footer">
              <p>**this is computer generated invoice no signature required**</p>
          </div>
      </div>
  </body>
  </html>`;
};

export const inventoryPDFTemplate = (inventoryData) => {
  const { companyDetails, _id: date, entries } = inventoryData;

  return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Inventory Data</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 20px;
        }
        .container {
            border: 1px solid orange;
            padding: 10px;
            background-color: #f8f8f8;
        }
        .header {
            display: flex;
            align-items: center;
            font-size: 14px;
            width: 100%;
            margin-bottom: 20px;
        }
        .header p {
            margin: 0;
        }
        .stamp {
            text-align: center;
            margin-bottom: 20px;
            font-size: 18px;
        }
        .stamp .para {
            font-weight: 500;
            margin: 5px 0;
        }
        .company_name {
            color: red;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .address {
            font-size: 14px;
            margin-bottom: 5px;
        }
        .table_container {
            text-align: center;
            margin-top: 16px;
        }
        table {
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
            font-size: 14px;
        }
        table th, table td {
            border: 1px solid #ddd;
            padding: 8px;
        }
        table th {
            background-color: #f2f2f2;
        }
        table tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        table tr:hover {
            background-color: #ddd;
        }
        .date-row td {
            font-weight: bold;
            background-color: #f9f9f9;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="header">
            <p>Inventory Data</p>
        </div>
        <div class="stamp">
            <p class="para">${companyDetails?.businessName}</p>
            <p class="address">${companyDetails?.address}</p>
            <p class="address">Mobile Number: ${companyDetails?.phoneNumber}</p>
            <p class="address">Email: ${companyDetails?.email}</p>
            <p class="address">Date: ${date}</p>
        </div>
        <div class="table_container">
            <table>
                <thead>
                    <tr>
                        <th>ITEM</th>
                        <th>QUANTITY</th>
                        <th>STATUS</th>
                        <th>NOTE</th>
                    </tr>
                </thead>
                <tbody>
                 ${entries[0].items
                   .map(
                     (item) => `
                    <tr key=${item.itemId}>
                      <td>${item.name}</td>
                      <td>${item.itemQuantity}</td>
                      <td>${item.status}</td>
                      <td>${item.note}</td>
                    </tr>
                `
                   )
                   .join("")}
                </tbody>
            </table>
        </div>
    </div>
</body>
</html>
`;
};

export const getInputType = (type) => {
  if (type === "Text") {
    return "text";
  } else if (type === "Number") {
    return "Number";
  } else if (type === "Boolean") {
    return "checkbox";
  } else if (type === "Date") {
    return "date";
  } else if (type === "Time") {
    return "time";
  } else if (type === "Color") {
    return "color";
  } else if (type === "Select") {
    return "select";
  }
};

export function debounce(cb, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

export const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
