import { Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import Loader from "../../UI/Loader";
import {
  getErrorMessage,
  getInputType,
  getSuccessMessage,
} from "../../functions";
import {
  addQrData,
  createBulk,
  deleteQrData,
  getQrData,
  updateQrDetails,
} from "../../services/qrDataApi";
import { useNavigate } from "react-router-dom";
import EditQrDataModal from "./components/EditQrDataModal";
import QRAddInBulkModal from "./components/QRAddInBulkModal";
import TableCard from "./components/TableCard";
const GenerateQR = () => {
  const qrSettings = useSelector((state) => state.qrSettings.items);
  const [formData, setFormData] = useState([]);
  const [qrData, setQrData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedQR, setSelectedQR] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [noOfQr, setNoOfQr] = useState(1);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [fieldToIncrement, setFieldToIncrement] = useState("");
  const [bulkStartRange, setBulkStartRange] = useState(0);
  const [selectedQrs, setSelectedQrs] = useState([]);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (formData.length === 0) {
        return toast.error("You can't generate QR without any data.");
      }
      const res = await addQrData({ data: formData });
      fetchQRData();
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setFormData([]);
      setLoading(false);
    }
  };

  const handleChange = (labelName, value, labelType) => {
    setFormData((prevData) => {
      const index = prevData.findIndex((item) => item.labelName === labelName);
      if (index !== -1) {
        const updatedData = [...prevData];
        updatedData[index] = { labelName, value, labelType };
        return updatedData;
      } else {
        return [...prevData, { labelName, value, labelType }];
      }
    });
  };
  const fetchQRData = async () => {
    try {
      setLoading(true);
      const res = await getQrData();
      setQrData(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const isSelected = (qrId) => {
    return selectedQrs.some((item) => item._id === qrId);
  };

  const toggleSelected = (qr) => {
    setSelectedQrs((prevSelected) =>
      prevSelected.some((item) => item._id === qr._id)
        ? prevSelected.filter((item) => item._id !== qr._id)
        : [...prevSelected, qr]
    );
  };

  useEffect(() => {
    fetchQRData();
  }, []);
  const handleDeleteQrData = async (qrDataId) => {
    try {
      setLoading(true);
      const res = await deleteQrData(qrDataId);
      setQrData((prevData) => prevData.filter((item) => item._id !== qrDataId));
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const handleSelectQR = (qr) => {
    setIsEdit(true);
    setSelectedQR(qr);
  };
  const updateQrData = async (qrId, qrData) => {
    try {
      setLoading(true);
      const res = await updateQrDetails(qrId, qrData.data);
      setQrData((prevData) =>
        prevData.map((item) => (item._id === qrId ? res.data : item))
      );
      setIsEdit(false);
      setSelectedQR({});
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const handleBulkCreate = async () => {
    try {
      if (+bulkStartRange < 0) {
        return toast.error("Start should have a positive value");
      }
      if (!fieldToIncrement) {
        return toast.error("Select one field to increment");
      }
      const res = await createBulk({
        start: bulkStartRange,
        fieldToIncrement,
        noOfQrs: noOfQr,
        data: formData,
      });
      toast.success(getSuccessMessage(res));
      setQrData((prevData) => [...prevData, ...res.data]);
      setShowBulkModal(false);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const handleBulkDownload = async () => {
    try {
      if (selectedQrs.length === 0) {
        return toast.error("Please select some QRs");
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/qr/data/download/bulk`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            qrTextList: selectedQrs,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "qr-codes.pdf";
      a.click();
      window.URL.revokeObjectURL(url);
      setSelectedQrs([]);
      toast.success("QR codes downloaded successfully");
    } catch (error) {
      toast.error(`Failed to download QR codes: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    setSelectedQrs((prevSelected) =>
      prevSelected.length === qrData.length ? [] : qrData
    );
  };

  if (loading) return <Loader />;
  if (qrSettings?.length === 0) {
    return (
      <div>
        <Gap>Alert!</Gap>
        <div className="flex  flex-col lg:flex-row gap-2 items-center">
          <p>You can&apos;t use this feature yet . Go to</p>
          <Button
rounded={"none"}
            colorScheme="blue"
            onClick={() => navigate(`/dashboard?component=QRSettings`)}
          >
            QR Settings
          </Button>{" "}
          <p>and add fields there.</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <EditQrDataModal
        isOpen={isEdit}
        onClose={() => {
          setIsEdit(false);
          setSelectedQR({});
        }}
        qrData={selectedQR}
        updateQrData={updateQrData}
      />
      <QRAddInBulkModal
        qrSettings={qrSettings}
        noOfQr={noOfQr}
        setNoOfQr={setNoOfQr}
        showBulkModal={showBulkModal}
        setShowBulkModal={setShowBulkModal}
        handleChange={handleChange}
        setFieldToIncrement={setFieldToIncrement}
        setBulkStartRange={setBulkStartRange}
        handleBulkCreate={handleBulkCreate}
        bulkStartRange={bulkStartRange}
      />
      <div>
        <Gap>Generate QR</Gap>
        <form
          onSubmit={handleSubmit}
          className="flex lg:flex-wrap lg:flex-row gap-4 flex-col"
        >
          {qrSettings?.map((item, index) => (
            <InputField
              key={index}
              type={getInputType(item.labelType)}
              name={item.labelName}
              labelName={item.labelName}
              uni={item.labelName}
              options={item?.options?.split(",")?.map((option) => {
                return {
                  label: option,
                  name: option,
                };
              })}
              onChange={(e) => {
                getInputType(item.labelType) === "select"
                  ? handleChange(item.labelName, e.value.name, item.labelType)
                  : handleChange(
                      item.labelName,
                      e.target.value,
                      item.labelType
                    );
              }}
            />
          ))}

          <Button
rounded={"none"}
            colorScheme="blue"
            className="mt-6"
            onClick={() => setShowBulkModal(true)}
          >
            Add In Bulk
          </Button>
          <Button
rounded={"none"} colorScheme="blue" className="mt-6" type="submit">
            Submit
          </Button>
        </form>
      </div>
      <div>
        <Gap>Your QRs</Gap>
        {selectedQrs.length > 0 && (
          <div className="flex gap-2">
            <Button
rounded={"none"} colorScheme="blue" onClick={handleBulkDownload}>
              Download Selected
            </Button>
            <Button
rounded={"none"} colorScheme="blue" onClick={handleSelectAll}>
              Select All
            </Button>
          </div>
        )}
        <div className="lg:grid lg:grid-cols-3 md:grid md:grid-cols-2 flex lg:flex-wrap gap-2 flex-col tick main item mt-2">
          {qrData?.map((item) => (
            <TableCard
              key={item._id}
              handleDeleteQrData={handleDeleteQrData}
              item={item}
              handleSelectQR={handleSelectQR}
              isSelected={isSelected(item._id)}
              toggleSelected={toggleSelected}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default GenerateQR;
