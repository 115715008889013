import logo from "./assets/logo.png";
import classPic from "./assets/class.svg";
import interview from "./assets/interview.svg";
import signupBg from "./assets/signup-bg.png";
import qa from "./assets/qa.svg";
import live from "./assets/live.svg";
import premium from "./assets/premium.svg";
import teaching from "./assets/teaching.svg";
import look from "./assets/look.png";
import onlineClass from "./assets/online-class.png";
import classIcon from "./assets/classIcon.png";
import premiumIcon from "./assets/premiumIcon.jpeg";
import login from "./assets/login.svg";
import signup from "./assets/signup.svg";
import invoice from "./assets/invoice.svg";
export default {
  logo,
  classPic,
  interview,
  signupBg,
  qa,
  live,
  premium,
  teaching,
  look,
  onlineClass,
  classIcon,
  premiumIcon,
  login,
  signup,
  invoice,
};
