import React, { useEffect, useState } from "react";
import Loader from "../../UI/Loader";
import { useSelector } from "react-redux";
import SelectField from "../../UI/SelectField";
import InventoryItemList from "./components/InventoryItemList";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import {
  addInventory,
  deleteInventory,
  getAllInventory,
} from "../../services/inventoryApi";
import { toast } from "sonner";
import {
  getErrorMessage,
  getSuccessMessage,
  inventoryPDFTemplate,
} from "../../functions";
import { FaEdit, FaPrint, FaTrashAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import moment from "moment";

const CreateInventory = () => {
  const products = useSelector((state) => state.product.products);
  const companyDetails = useSelector(
    (state) => state.companyDetails.companyDetails
  );
  const isMobileView = useSelector((state) => state.mobileView.isMobileView);

  const [loading, setLoading] = useState(false);
  const [inventoryHistory, setInventoryHistory] = useState([]);
  const [dateOfEntry, setDateOfEntry] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, []);
  const [items, setItems] = useState([]);
  const fetchInventory = async () => {
    try {
      setLoading(true);
      const res = await getAllInventory();
      setInventoryHistory(res.data);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInventory();
  }, []);
  const handleDeleteItem = (productId, status) => {
    const updatedItems = items.filter(
      (item) => !(item._id === productId && item.status === status)
    );
    setItems(updatedItems);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleProductSelect = ({ value }) => {
    const existingItem = items.find(
      (item) => item._id === value._id && item.status === value.status
    );

    if (
      !existingItem ||
      items.filter(
        (item) => item._id === value._id && item.status === value.status
      ).length < 2
    ) {
      setItems([
        ...items,
        {
          ...value,
          itemQuantity: 1,
          status: "Manufactured",
          note: "",
        },
      ]);
    } else {
      const updatedItems = items.map((item) =>
        item._id === value._id && item.status === value.status
          ? {
              ...item,
              status: item.status === "Manufactured" ? "Sold" : "Manufactured",
              note: "",
            }
          : item
      );

      setItems(updatedItems);
    }
  };

  const handleInventoryCreate = async () => {
    try {
      if (items.length === 0) {
        return toast.error("Please at least 1 item");
      }
      if (!dateOfEntry) {
        return toast.error("Please enter date of entry");
      }

      const formattedDateOfEntry = new Date(dateOfEntry)
        .toISOString()
        .slice(0, 10);

      if (
        !isEdit &&
        filteredInventoryHistory
          .map((item) => item._id)
          .includes(formattedDateOfEntry)
      ) {
        return toast.warning("Date of entry already exist . Please edit that.");
      }
      setLoading(true);
      const res = await addInventory({
        items: items.map((item) => {
          return {
            itemId: item._id,
            name: item.name,
            itemQuantity: +item.itemQuantity,
            status: item.status,
            note: item.note,
          };
        }),
        dateOfEntry,
      });
      setIsEdit(false);
      setItems([]);

      await fetchInventory();
      toast.success(getSuccessMessage(res));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const formattedDate = dateOfEntry.toISOString().split("T")[0];
  const handleEdit = (history) => {
    setDateOfEntry(new Date(history._id));
    setItems(
      history.entries.flatMap((entry) => {
        return entry.items;
      })
    );
    setIsEdit(true);
  };

  const handleDeleteInventory = async (inventory) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this log?"
      );
      if (!shouldDelete) {
        return;
      }

      setLoading(true);
      const res = await deleteInventory(inventory._id);
      toast.success(getSuccessMessage(res));
      setInventoryHistory((prevHistory) =>
        prevHistory.filter((history) => history._id !== inventory._id)
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const filteredInventoryHistory = inventoryHistory.filter((history) => {
    const historyDate = new Date(history.dateOfEntry)
      .toDateString()
      .toLowerCase();
    const searchLower = searchQuery.toLowerCase();
    return (
      historyDate.includes(searchLower) ||
      history.entries.some((entry) =>
        entry.items.some(
          (item) =>
            item.name.toLowerCase().includes(searchLower) ||
            item.note.toLowerCase().includes(searchLower)
        )
      )
    );
  });

  const printPDF = (inventoryData) => {
    const html = inventoryPDFTemplate({
      ...inventoryData,
      companyDetails,
    });

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const iframeDocument = iframe.contentDocument;
    iframeDocument.open();
    iframeDocument.write(html);
    iframeDocument.close();

    setTimeout(() => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 1000);
  };

  if (loading) return <Loader />;

  return (
    <>
      <div>
        <Gap>Inventory</Gap>
        <div className="flex lg:flex-wrap lg:flex-row gap-4 flex-col">
          <div className="lg:w-1/6">
            <SelectField
              labelName={"Products"}
              options={products}
              onChange={(value) => handleProductSelect(value)}
            />
          </div>
          <div>
            <InputField
              type={"date"}
              labelName={"Date Of Entry"}
              value={formattedDate}
              onChange={(e) => setDateOfEntry(new Date(e.target.value))}
            />
          </div>

          <Button
rounded={"none"}
            colorScheme="blue"
            className="lg:mt-6 "
            onClick={handleInventoryCreate}
          >
            {isEdit ? "Update" : "Add"}
          </Button>
        </div>
        <InventoryItemList
          products={products}
          items={items}
          handleProductSelect={handleProductSelect}
          handleDeleteItem={handleDeleteItem}
          setItems={setItems}
        />
      </div>
      <div className="p-0">
        <Gap>History</Gap>

        <InputField
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="border border-gray-300 p-2 "
        />

        <div className="overflow-x-auto">
          {isMobileView ? (
            <div className="flex flex-col gap-4 p-4">
              {filteredInventoryHistory.map((history) => (
                <div
                  key={history._id}
                  className="bg-gray-100 p-2  shadow-md"
                >
                  <div className="font-bold">
                    {new Date(history._id).toLocaleDateString()}
                  </div>
                  <div className="flex flex-col gap-1 mt-1">
                    <div>
                      <ul className="flex flex-col gap-2">
                        {history.entries.map((entry, entryIndex) =>
                          entry.items.map((item, itemIndex) => (
                            <li
                              key={`${entryIndex}-${itemIndex}`}
                              className="flex gap-3"
                            >
                              {/* {item.name} - Quantity: {item.itemQuantity} -
                              Status: {item.status} - Note: {item.note} */}
                              <div className="flex gap-2 flex-wrap flex-col bg-white  p-2">
                                <span className="font-semibold">
                                  {item.name}
                                </span>
                                <span className="font-semibold">
                                  Quantity: {item.itemQuantity}
                                </span>
                                <span className="font-semibold">
                                  Status: {item.status}
                                </span>
                                <span className="font-semibold">
                                  Note: {item.note}
                                </span>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                    <div className="flex lg:gap-2 mt-2 gap-4">
                      {isEdit ? (
                        <Button
rounded={"none"}
                          onClick={() => {
                            setIsEdit(false);
                            setItems([]);
                          }}
                        >
                          <MdCancel size={20} color="blue" />
                        </Button>
                      ) : (
                        <>
                          <Button
rounded={"none"} onClick={() => printPDF(history)} size="xs">
                            <FaPrint size={20} color="darkBlue" />
                          </Button>
                          <Button
rounded={"none"} onClick={() => handleEdit(history)} size="xs">
                            <FaEdit size={20} color="blue" />
                          </Button>
                          <Button
rounded={"none"}
                            onClick={() => handleDeleteInventory(history)}
                            size="xs"
                          >
                            <FaTrashAlt size={20} color="red" />
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="overflow-x-auto">
              <Table variant="striped" className="mt-4">
                <Thead className="bg-gray-50">
                  <Tr>
                    <Th width={"20%"}>Date</Th>
                    <Th width={"30%"}>Item</Th>
                    <Th width={"10%"}>Quantity</Th>
                    <Th width={"10%"}>Status</Th>
                    <Th >Note</Th>
                    <Th width={"10%"}>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredInventoryHistory.map((history) => (
                    <Tr key={history._id}>
                      <Td>{moment(history._id).format("DD MMM YYYY")}</Td>
                      <Td>
                        <ul className="flex flex-col gap-6 ">
                          {history.entries.map((entry, entryIndex) =>
                            entry.items.map((item, itemIndex) => (
                              <li key={`${entryIndex}-${itemIndex}`} className="flex gap-2 capitalize">
                                {item.name}
                              </li>
                            ))
                          )}
                        </ul>
                      </Td>
                      <Td>
                        <ul className="flex flex-col gap-6">
                          {history.entries.map((entry, entryIndex) =>
                            entry.items.map((item, itemIndex) => (
                              <li key={`${entryIndex}-${itemIndex}`} className="flex gap-2">
                                {item.itemQuantity}
                              </li>
                            ))
                          )}
                        </ul>
                      </Td>
                      <Td>
                        <ul className="flex flex-col gap-6" >
                          {history.entries.map((entry, entryIndex) =>
                            entry.items.map((item, itemIndex) => (
                              <li key={`${entryIndex}-${itemIndex}`} className="flex gap-2">
                                {item.status}
                              </li>
                            ))
                          )}
                        </ul>
                      </Td>
                      <Td>
                        <ul className="flex flex-col gap-4">
                          {history.entries.map((entry, entryIndex) =>
                            entry.items.map((item, itemIndex) => (
                              <li key={`${entryIndex}-${itemIndex}`} className="flex p-0.5 border capitalize border-gray-300">
                                {item.note}
                              </li>
                            ))
                          )}
                        </ul>
                      </Td>
                      <Td className="flex">
                        {isEdit ? (
                          <Button
rounded={"none"}
                            onClick={() => {
                              setIsEdit(false);
                              setItems([]);
                            }}
                          >
                            <MdCancel size={24} color="blue" />
                          </Button>
                        ) : (
                          <>
                            <Button
rounded={"none"} onClick={() => printPDF(history)}>
                              <FaPrint size={24} color="darkBlue" />
                            </Button>
                            <Button
rounded={"none"} onClick={() => handleEdit(history)}>
                              <FaEdit size={24} color="blue" />
                            </Button>
                            <Button
rounded={"none"}
                              onClick={() => handleDeleteInventory(history)}
                            >
                              <FaTrashAlt size={24} color="red" />
                            </Button>
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CreateInventory;
