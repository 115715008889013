import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dealers: [],
};

export const dealerSlice = createSlice({
  name: "dealer",
  initialState,
  reducers: {
    setDealers: (state, action) => {
      state.dealers = action.payload;
    },
    updateDealer: (state, action) => {
      const updatedDealer = action.payload;
      const index = state.dealers.findIndex(
        (dealer) => dealer._id === updatedDealer._id
      );
      if (index !== -1) {
        state.dealers[index] = updatedDealer;
      } else {
        state.dealers = [...state.dealers, updatedDealer];
      }
    },
    removeDealer: (state, action) => {
      const dealerId = action.payload;
      state.dealers = state.dealers.filter((dealer) => dealer._id !== dealerId);
    },
    emptyDealers: (state) => {
      state.dealers = [];
    },
  },
});

export const { setDealers, updateDealer, removeDealer, emptyDealers } =
  dealerSlice.actions;

export default dealerSlice.reducer;
